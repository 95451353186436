import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useHistory, Link } from "react-router-dom";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: 0,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  boxShadow: 24,
};

const garantList = [
  { id: 1, name: "利用者" },
  { id: 2, name: "倉庫管理者" },
  { id: 3, name: "サイト管理者" },
];

const start = 1960;
let end = new Date().getFullYear() + 1;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const headers = {
  "Content-Type": "application/json",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function AdminEditUser(props) {
  const { currentUser, masterData, userInfo } = useAuthValue();
  const [company, setCompany] = useState(props.userData.companyId);
  const [kankatsuShukanShiten, setKankatsuShukanShiten] = useState(
    props.userData.kankatsuShukanShitenId
  );
  const [shiten, setShiten] = useState(props.userData.shitenId);
  const [eigyosho, setEigyosho] = useState(props.userData.eigyoshoId);

  const [type, setType] = useState(props.userData.type);
  const [email, setEmail] = useState(props.userData.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [error2, setError2] = useState("");
  const [userEmail, setUserEmail] = useState(userInfo?.currentUser);
  const [userPw, setUserPw] = useState("");

  const history = useHistory();
  const [inputError, setInputError] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const move = () => {
    history.push("/admin");
  };

  const inputRef = useRef(null);

  const handleChange = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };

  const validateEigyosho = () => {
    let isValid = true;
    if (eigyosho === "") {
      isValid = false;
      setError2("営業所が選択されていません");
    }
    return isValid;
  };
  const setKankatsuShu = (data) => {
    setCompany(data);
    const kankatsuSSId = kankatsuShukanShitenMList.filter(
      (item) => item.companyId === data
    )[0].kankatsuShukanShitenId;
    if (data > 1) {
      setKankatsuShukanShiten(kankatsuSSId);
      setShiten1(kankatsuSSId);
    }
  };

  const setShiten1 = (data) => {
    const shitenId1 = shitenMList.filter(
      (item) => item.kankatsuShitenId === data
    )[0].shitenId;
    setShiten(shitenId1);
  };

  const kankatsuShuList = kankatsuShukanShitenMList.filter(
    (item) => item.companyId === company
  );

  const shitenList = shitenMList.filter(
    (item) => item.kankatsuShitenId === kankatsuShukanShiten
  );

  const eigyoshoList = eigyoshoMList.filter((item) => item.shitenId === shiten);

  const eigyoshoSet = (data) => {
    setEigyosho(data);
    validateEigyosho();
  };

  const kankatsuEigyobuList = kankatsuEigyobuMList.find(
    (item) => item.kankatsuEigyobu === eigyosho
  );

  const mailSend = () => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const body = {
      token: `${process.env.REACT_APP_SENDGRID_API_KEY}`,
      personalizations: [
        {
          to: [
            {
              email: email,
            },
          ],
          subject: "SENKO-SO-MATCH 権限変更完了",
        },
      ],
      from: {
        email: `${process.env.REACT_APP_SENDMAIL_EMAIL}`,
      },
      content: [
        {
          type: "text/plain",
          value: `SENKO-SO-MATCH 権限が更新されました。\n\n`,
        },
      ],
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}sendgrid/sendMail`, body, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const update = (e) => {
    const updateUser = {
      userId: props.userData.id,
      companyId: company,
      type: type,
      kankatsuShukanShitenId: kankatsuShukanShiten,
      shitenId: shiten,
      eigyoshoId: eigyosho,
      status: 1,
      email: email,
      isNotification: 0,
      password: password,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}user/update`, updateUser, {
        headers: headers,
      })
      .then((response) => {
        console.log("success");
        mailSend();
        setSuccess("ユーザー情報を更新しました。");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const handleSearchModaleOpen = () => {
    setSearchModal(true);
  };

  const handleSearchModalClose = () => {
    setSuccess("");
    setSearchModal(false);
  };

  const move1 = () => {
    history.push("/admin");
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        sx={{ width: 100 }}
        onClick={() => {
          handleSearchModaleOpen();
        }}
      >
        <Typography>編集</Typography>
      </Button>

      <Modal
        hideBackdrop
        open={searchModal}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <div>
          <Toaster position="top-right" />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Paper
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    ...style,
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    marginTop={2}
                  >
                    <Typography variant="h5">
                      {success !== "" ? "ユーザ修正完了" : "倉庫管理者修正"}
                    </Typography>
                  </Grid>

                  <Grid marginTop={5}>
                    <Grid container direction="column" alignItems="center">
                      <Grid sx={{ width: "60%" }}>
                        {/* {success && (
                          <div className="auth__success">{success}</div>
                        )} */}
                        {error && <div className="auth__error">{error}</div>}
                      </Grid>
                    </Grid>
                    {success !== "" ? (
                      ""
                    ) : (
                      <Stack direction="row" alignItems="center">
                        <FormControl sx={{ marginX: "5%", width: "25%" }}>
                          <Typography variant="h6">権限</Typography>
                        </FormControl>
                        <FormControl sx={{ width: "65%" }}>
                          <InputLabel id="type">権限を選択</InputLabel>
                          <Select
                            sx={{ width: "50%" }}
                            labelId="type"
                            fullWidth
                            label="倉庫種別を選択"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <MenuItem disabled value="">
                              権限を選択
                            </MenuItem>
                            {garantList.map((item, index) => {
                              return (
                                <MenuItem key={item.name} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Stack>
                    )}
                    {success !== "" ? (
                      ""
                    ) : (
                      <Grid marginTop={1}>
                        <Stack direction="row" alignItems="center">
                          <FormControl sx={{ marginX: "5%", width: "25%" }}>
                            <Typography variant="h6">メールアドレス</Typography>
                          </FormControl>
                          <FormControl sx={{ width: "65%" }}>
                            <Typography sx={{ width: "50%" }}>
                              {email}
                            </Typography>
                          </FormControl>
                        </Stack>
                      </Grid>
                    )}

                    {success !== "" ? (
                      ""
                    ) : (
                      <Grid marginTop={2}>
                        <Stack direction="row" alignItems="center">
                          <FormControl sx={{ marginX: "5%", width: "25%" }}>
                            <Typography variant="h6">会社名</Typography>
                          </FormControl>
                          <FormControl sx={{ width: "65%" }}>
                            <InputLabel id="company">会社名を選択</InputLabel>
                            <Select
                              sx={{ width: "50%" }}
                              labelId="company"
                              fullWidth
                              label="会社名を選択"
                              value={company}
                              onChange={(e) => {
                                setKankatsuShu(e.target.value);
                                setCompany(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                会社名を選択
                              </MenuItem>
                              {companyMList
                                .sort((a, b) => a.sort - b.sort)
                                .map((item, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={item.companyId}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                    )}
                    {success !== "" ? (
                      ""
                    ) : (
                      <Grid marginTop={2}>
                        <Stack direction="row" alignItems="center">
                          <FormControl sx={{ marginX: "5%", width: "25%" }}>
                            <Typography variant="h6">管轄主管支店名</Typography>
                          </FormControl>
                          <FormControl sx={{ width: "65%" }}>
                            <InputLabel id="kankatsuShukanShiten">
                              {kankatsuShuList.length === 1
                                ? "なし"
                                : "管轄主管支店を選択"}
                            </InputLabel>
                            {kankatsuShuList.length === 1 ? (
                              <Select
                                sx={{ width: "50%" }}
                                labelId="kankatsuShukanShiten"
                                fullWidth
                                label={
                                  kankatsuShuList.length === 1
                                    ? "なし"
                                    : "管轄主管支店を選択"
                                }
                                value={
                                  kankatsuShuList.length === 1
                                    ? ""
                                    : kankatsuShukanShiten
                                }
                                onChange={(e) =>
                                  setKankatsuShukanShiten(e.target.value)
                                }
                                disabled
                              >
                                {kankatsuShuList.length === 1 ? (
                                  ""
                                ) : (
                                  <MenuItem value="">
                                    管轄主管支店を選択
                                  </MenuItem>
                                )}

                                {kankatsuShuList.length === 1
                                  ? ""
                                  : kankatsuShuList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.kankatsuShukanShitenId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })}
                              </Select>
                            ) : (
                              <Select
                                sx={{ width: "50%" }}
                                labelId="kankatsuShukanShiten"
                                fullWidth
                                label={
                                  kankatsuShuList.length === 1
                                    ? "なし"
                                    : "管轄主管支店を選択"
                                }
                                value={
                                  kankatsuShuList.length === 1
                                    ? ""
                                    : kankatsuShukanShiten
                                }
                                onChange={(e) =>
                                  setKankatsuShukanShiten(e.target.value)
                                }
                              >
                                {kankatsuShuList.length === 1 ? (
                                  ""
                                ) : (
                                  <MenuItem value="">
                                    管轄主管支店を選択
                                  </MenuItem>
                                )}

                                {kankatsuShuList.length === 1
                                  ? ""
                                  : kankatsuShuList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.kankatsuShukanShitenId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })}
                              </Select>
                            )}
                          </FormControl>
                        </Stack>
                      </Grid>
                    )}
                    {success !== "" ? (
                      ""
                    ) : (
                      <Grid marginTop={2}>
                        <Stack direction="row" alignItems="center">
                          <FormControl sx={{ marginX: "5%", width: "25%" }}>
                            <Typography variant="h6">支店名</Typography>
                          </FormControl>
                          <FormControl sx={{ width: "65%" }}>
                            <InputLabel id="sokoType">
                              {shitenList[0]?.shitenId === 28 ||
                              shitenList[0]?.shitenId === 25
                                ? "支店名を選択"
                                : shitenList.length === 1
                                ? "なし"
                                : "支店名を選択"}
                            </InputLabel>
                            {shitenList[0]?.shitenId === 28 ||
                            shitenList[0]?.shitenId === 25 ||
                            (shitenList.length !== 1 &&
                              shitenList.length !== 0) ? (
                              <Select
                                sx={{ width: "50%" }}
                                labelId="sokoType"
                                fullWidth
                                label={
                                  shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? "支店名を選択"
                                    : shitenList.length === 1
                                    ? "なし"
                                    : "支店名を選択"
                                }
                                value={
                                  shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? shiten
                                    : shitenList.length === 1
                                    ? ""
                                    : shiten
                                }
                                onChange={(e) =>
                                  shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? setShiten(e.target.value)
                                    : shitenList.length === 1
                                    ? ""
                                    : setShiten(e.target.value)
                                }
                              >
                                {shitenList[0]?.shitenId === 28 ||
                                shitenList[0]?.shitenId === 25 ? (
                                  <MenuItem value="">支店名を選択</MenuItem>
                                ) : shitenList.length === 1 ? (
                                  ""
                                ) : (
                                  <MenuItem value="">支店名を選択</MenuItem>
                                )}
                                {shitenList[0]?.shitenId === 28 ||
                                shitenList[0]?.shitenId === 25
                                  ? shitenList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.shitenId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })
                                  : shitenList.length === 1
                                  ? ""
                                  : shitenList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.shitenId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })}
                              </Select>
                            ) : (
                              <Select
                                sx={{ width: "50%" }}
                                labelId="sokoType"
                                fullWidth
                                label={
                                  shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? "支店名を選択"
                                    : shitenList.length === 1
                                    ? "なし"
                                    : "支店名を選択"
                                }
                                value={
                                  shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? shiten
                                    : shitenList.length === 1
                                    ? ""
                                    : shiten
                                }
                                onChange={(e) =>
                                  shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? setShiten(e.target.value)
                                    : shitenList.length === 1
                                    ? ""
                                    : setShiten(e.target.value)
                                }
                                disabled
                              >
                                {shitenList[0]?.shitenId === 28 ||
                                shitenList[0]?.shitenId === 25 ? (
                                  <MenuItem value="">支店名を選択</MenuItem>
                                ) : shitenList.length === 1 ? (
                                  ""
                                ) : (
                                  <MenuItem value="">支店名を選択</MenuItem>
                                )}
                                {shitenList[0]?.shitenId === 28 ||
                                shitenList[0]?.shitenId === 25
                                  ? shitenList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.shitenId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })
                                  : shitenList.length === 1
                                  ? ""
                                  : shitenList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.shitenId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })}
                              </Select>
                            )}
                          </FormControl>
                        </Stack>
                      </Grid>
                    )}
                    {success !== "" ? (
                      ""
                    ) : (
                      <Grid marginTop={2}>
                        <Stack direction="row" alignItems="center">
                          <FormControl sx={{ marginX: "5%", width: "25%" }}>
                            <Typography variant="h6">営業所名</Typography>
                          </FormControl>
                          <FormControl sx={{ width: "65%" }}>
                            <InputLabel id="eigyosho">
                              {eigyoshoList[0]?.eigyoshoId === 449
                                ? "支店名を選択"
                                : eigyoshoList.length === 1
                                ? "なし"
                                : "営業所を選択"}
                            </InputLabel>
                            <Select
                              sx={{ width: "50%" }}
                              labelId="eigyosho"
                              fullWidth
                              label={
                                eigyoshoList[0]?.eigyoshoId === 449
                                  ? "支店名を選択"
                                  : eigyoshoList.length === 1
                                  ? "なし"
                                  : "営業所を選択"
                              }
                              value={
                                eigyoshoList[0]?.eigyoshoId === 449
                                  ? eigyosho
                                  : eigyoshoList.length === 1
                                  ? ""
                                  : eigyosho
                              }
                              onChange={(e) =>
                                eigyoshoList[0]?.eigyoshoId === 449
                                  ? eigyoshoSet(e.target.value)
                                  : eigyoshoList.length === 1
                                  ? ""
                                  : eigyoshoSet(e.target.value)
                              }
                            >
                              <MenuItem value="">営業所を選択</MenuItem>
                              {eigyoshoList[0]?.eigyoshoId === 449
                                ? eigyoshoList.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.eigyoshoId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })
                                : eigyoshoList.length === 1
                                ? ""
                                : eigyoshoList.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.eigyoshoId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                            {eigyosho !== ""
                              ? ""
                              : error2 && (
                                  <Typography variant="subtitle1" color="error">
                                    {error2}
                                  </Typography>
                                )}
                          </FormControl>
                        </Stack>
                      </Grid>
                    )}
                    {success !== "" ? (
                      <Grid container direction="column" alignItems="center">
                        <Typography variant="h6">
                          ユーザーの修正が完了いたしました。
                        </Typography>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {success !== "" ? (
                      ""
                    ) : (
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{ marginTop: 5 }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ width: 250 }}
                          onClick={(e) => update(e)}
                        >
                          修正
                        </Button>
                      </Grid>
                    )}
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      sx={{ marginTop: 3, marginBottom: 3 }}
                    >
                      <Button
                        variant="contained"
                        color="inherit"
                        sx={{ width: 250 }}
                        onClick={() => {
                          handleSearchModalClose();
                        }}
                      >
                        閉じる
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default AdminEditUser;
