import { useState, useEffect } from "react";
import "./forms.css";
import { auth } from "./firebase";
import { useHistory, Link, Redirect } from "react-router-dom";
import {
  getAuth,
  EmailAuthProvider,
  updateEmail,
  reauthenticateWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import { useAuthValue } from "./AuthContext";
import axios from "axios";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const theme = createTheme();

function AdminUpdatedEmail() {
  const { currentUser, userInfo } = useAuthValue();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [updated, setUpdated] = useState(false);
  const history = useHistory();

  console.log(currentUser);
  console.log(userInfo);

  const move = () => {
    history.push("/admin");
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          component={Paper}
          elevation={6}
          square
          container
          direction="column"
          alignItems="center"
        >
          <Grid
            sx={{
              width: "50%",
              my: 8,
              mx: 4,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              marginTop={3}
              align="center"
            >
              メールアドレス変更完了
            </Typography>
            <Typography
              component="h1"
              variant="subtitle1"
              color="#000000"
              marginTop={3}
              align="center"
              sx={{ width: "100%" }}
            >
              メールアドレスの変更が完了しました。
            </Typography>
            <Grid container direction="column" alignItems="center">
              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: "50%" }}
                name="login_form"
              >
                {success && <div className="auth__success">{success}</div>}
                {error && <div className="auth__error">{error}</div>}
                {email === confirmEmail
                  ? ""
                  : message && <div className="auth__error">{message}</div>}

                <Button
                  variant="contained"
                  fullWidth
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={move}
                >
                  トップページ ＞
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default AdminUpdatedEmail;
