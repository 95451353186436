import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";

const headers = {
  "Content-Type": "application/json",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

function AdminAddMaster() {
  const { currentUser, userInfo, masterData } = useAuthValue();
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState("");
  const [addName, setAddName] = useState("");

  console.log(userInfo);
  const addMaster = () => {
    // console.log(currentUser.accessToken);
    const addData = {
      tableName: tableData,
      name: addName,
    };
    console.log("send", addData);
    axios
      .post(`${process.env.REACT_APP_API_URL}master/add`, addData, {
        headers: headers,
      })
      .then(function (res) {
        console.log(res.data);
        toast.success(
          () => <Typography variant="h5">追加しました</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      })
      .catch(function (err) {
        console.error(err);
        toast.error(
          () => <Typography variant="h5">追加できませんでした</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      });
  };
  console.log("add", tableData, addName);
  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Toaster position="top-right" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                SENKO-SO-MATCH(登録サイト)
              </Typography>
              <AdminMenu />
            </Toolbar>
          </AppBar>
          <List component="nav" sx={{ mt: 10, width: 240 }}>
            <AdminListItems />
            <Divider sx={{ my: 1 }} />
          </List>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5">マスタ(追加登録)</Typography>

                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid marginX="5%">
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        marginTop={2}
                      >
                        <Typography variant="h5">マスタ追加</Typography>
                      </Grid>

                      <Grid marginTop={10}>
                        <Grid marginTop={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <InputLabel id="master">マスタを選択</InputLabel>
                              <Select
                                sx={{ width: 200 }}
                                labelId="master"
                                fullWidth
                                value={tableData}
                                size="medium"
                                label="マスタを選択"
                                onChange={(e) => setTableData(e.target.value)}
                              >
                                <MenuItem value="">マスタを選択</MenuItem>
                                <MenuItem value="specM">スペック</MenuItem>
                                <MenuItem value="taiouGyotaiM">
                                  希望業態
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <TextField
                                sx={{ mt: 1 }}
                                margin="normal"
                                type="text"
                                fullWidth
                                required
                                size="medium"
                                placeholder="入力してください"
                                value={addName}
                                onChange={(e) => {
                                  setAddName(e.target.value);
                                }}
                              />
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      marginTop={10}
                      marginBottom={5}
                    >
                      <Grid>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ width: 250 }}
                          onClick={() => addMaster()}
                        >
                          登録
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 2 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default AdminAddMaster;
