import { createContext, useState, useContext, useEffect } from "react";
import { auth } from "./firebase";
import axios from "axios";
import dayjs from "dayjs";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //Authorization: `Bearer ${props.accessToken}`,
};

const itemName = [
  "areaM",
  "companyM",
  "contractM",
  "kankatsuShukanShitenM",
  "shitenM",
  "eigyoshoM",
  "kankatsuEigyobuM",
  "prefectureM",
  "sokoTypeM",
  "kinoKeitaiM",
  "specM",
  "taiouGyotaiM",
];
const date = new Date();

const oneYearAfter = dayjs(date).add(1, "y").format("YYYY/MM/DD hh:mm:ss");

const twoWeeksAgo = dayjs(date).subtract(2, "w").format("YYYY/MM/DD hh:mm:ss");

const countData = {
  startAt: "2022/01/01 00:00:00",
  endAt: oneYearAfter,
  sortKey: "createAt",
  ascdesc: "desc",
  limit: 9999,
  offset: 0,
  status: [1],
  areaId: "",
  shitenId: "",
  prefectureId: "",
  availableArea: "",
  sokoTypeId: "",
  companyId: "",
  contractId: "",
  kankatsuShukanShitenId: "",
  kankatsuEigyobuId: "",
  availableYear: "",
  availableMonth: "",
  kinoKeitaiId: "",
  specId: "",
  taiouGyotaiId: "",
};

const newCountData = {
  startAt: twoWeeksAgo,
  endAt: oneYearAfter,
  sortKey: "createAt",
  ascdesc: "desc",
  limit: 9999,
  offset: 0,
  areaId: "",
  status: [1],
  shitenId: "",
  prefectureId: "",
  availableArea: "",
  sokoTypeId: "",
  companyId: "",
  contractId: "",
  kankatsuShukanShitenId: "",
  kankatsuEigyobuId: "",
  availableYear: "",
  availableMonth: "",
  kinoKeitaiId: "",
  specId: "",
  taiouGyotaiId: "",
};

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }, props) {
  const [currentUser, setCurrentUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [newCount, setNewCount] = useState("");
  const [areaCount, setAreaCount] = useState([]);
  const [prefectureCount, setPrefectureCount] = useState([]);
  const [masterData, setMasterData] = useState({});

  const value = {
    currentUser,
    loading,
    userInfo,
    masterData,
    areaCount,
    prefectureCount,
    totalCount,
    newCount,
  };

  const getdata = async (userData) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/search7Count`, countData, {
        headers: headers,
      })
      .then((response) => {
        const count = Object.values(response.data.result[0])[0];
        setTotalCount(count);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/search7Count`,
        newCountData,
        { headers: headers }
      )
      .then((response) => {
        const count = Object.values(response.data.result[0])[0];
        setNewCount(count);
      });
    await Promise.all(
      itemName.map((item) => {
        let getItem = {
          sortKey: "createAt",
          ascdesc: "asc",
          itemName: item,
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}master/get`, getItem, {
            headers: headers,
          })
          .then((response) => {
            switch (item) {
              case "areaM":
                Object.assign(masterData, { areaMList: response.data.result });
                break;
              case "companyM":
                Object.assign(masterData, {
                  companyMList: response.data.result,
                });
                break;
              case "contractM":
                Object.assign(masterData, {
                  contractMList: response.data.result,
                });
                break;
              case "kankatsuShukanShitenM":
                Object.assign(masterData, {
                  kankatsuShukanShitenMList: response.data.result,
                });
                break;
              case "shitenM":
                Object.assign(masterData, {
                  shitenMList: response.data.result,
                });
                break;
              case "eigyoshoM":
                Object.assign(masterData, {
                  eigyoshoMList: response.data.result,
                });
                break;
              case "kankatsuEigyobuM":
                Object.assign(masterData, {
                  kankatsuEigyobuMList: response.data.result,
                });
                break;
              case "prefectureM":
                Object.assign(masterData, {
                  prefectureMList: response.data.result,
                });
                break;
              case "sokoTypeM":
                Object.assign(masterData, {
                  sokoTypeMList: response.data.result,
                });
                break;
              case "kinoKeitaiM":
                Object.assign(masterData, {
                  kinoKeitaiMList: response.data.result,
                });
                break;
              case "specM":
                Object.assign(masterData, {
                  specMList: response.data.result,
                });
                break;
              case "taiouGyotaiM":
                Object.assign(masterData, {
                  taiouGyotaiMList: response.data.result,
                });
                break;
            }
          });
      })
    );

    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getCountByArea3`,

        { headers: headers }
      )
      .then((res) => {
        setAreaCount(res.data.result);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getCountByPrefecture3`,

        { headers: headers }
      )
      .then((res) => {
        setPrefectureCount(res.data.result);
      })
      .then(() => {
        checkList();
      });
  };

  const checkList = () => {
    switch (
      masterData.areaMList !== undefined &&
      masterData.companyMList !== undefined &&
      masterData.contractMList !== undefined &&
      masterData.kankatsuShukanShitenMList !== undefined &&
      masterData.shitenMList !== undefined &&
      masterData.eigyoshoMList !== undefined &&
      masterData.kankatsuEigyobuMList !== undefined &&
      masterData.prefectureMList !== undefined &&
      masterData.sokoTypeMList !== undefined &&
      masterData.kinoKeitaiMList !== undefined &&
      masterData.specMList !== undefined &&
      masterData.taiouGyotaiMList !== undefined &&
      prefectureCount !== undefined &&
      areaCount !== undefined
    ) {
      case true:
        setLoading(false);
        break;
      case false:
        setTimeout(() => {
          setLoading(false);
        }, 3000);
        break;
    }
  };
  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((currentUser) => {
      setCurrentUser(currentUser);
      const getUser = {
        firebaseUuid: currentUser?.uid,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}user/getUserInfo`, getUser, {
          headers: headers,
        })
        .then((response) => {
          setUserInfo(response.data.result[0]);
          const userData = response.data.result[0];
          getdata(userData);
        })
        .catch((err) => {
          console.log("err", err);
          getdata();
        });
    });

    return () => {
      unsubscribed();
    };
  }, []);
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuthValue() {
  return useContext(AuthContext);
}
