import { useState, useRef } from "react";
import "./forms.css";
import { auth } from "./firebase";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";

const theme = createTheme();

function UserRegister() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [inputError, setInputError] = useState(false);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  //const {setTimeActive} = useAuthValue()

  const move = () => {
    history.push("/login");
  };

  const inputRef = useRef(null);

  const headers = {
    "Content-Type": "application/json",
    //Authorization: `Bearer ${props.accessToken}`,
  };

  const handleChange = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };

  const validatePw = () => {
    let isValid = true;
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        isValid = false;
        setMessage("パスワードが異なります");
      }
    }
    return isValid;
  };

  const register = (e) => {
    e.preventDefault();
    setError("");
    if (validatePw()) {
      // Create a new user with email and password using firebase
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          const addUser = {
            kankatsuShukanShitenId: 0,
            shitenId: 0,
            eigyoshoId: 0,
            status: 1,
            email: email,
            firebaseUuid: auth.currentUser.uid,
            companyId: 0,
            type: 1,
            isNotification: 1,
          };
          const ActionCodeSettiings = {
            url: `${process.env.REACT_APP_SITE_URL}/login/mail/${email}`,
          };
          sendEmailVerification(auth.currentUser, ActionCodeSettiings);
          const url = `${process.env.REACT_APP_API_URL}user/add`;
          axios
            .post(url, addUser, { headers: headers })
            .then((res) => {
              console.log("add");
              console.log("success", res.data);
            })
            .catch((error) => {
              console.log("err add");
              console.log("err", error.response.data);
            });
          setSuccess("入力したメールアドレスに確認メールを送信しました");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          console.log(err.code);
          switch (err.code) {
            case "auth/email-already-in-use":
              setError("すでにメールアドレスが使用されています");
              break;
            case "auth/wrong-password":
              setError("パスワードが間違っています");
              break;
            case "auth/user-disabled":
              setError("ユーザーが無効です");
              break;
            case "auth/user-not-found":
              setError("ユーザーが存在しません");
              break;
            case "auth/invalid-email":
              setError("メールアドレスの形式が間違っています");
              break;
            case "auth/too-many-requests":
              setError(
                "試行回数制限を超えました。時間をおいて再度お試しください"
              );
              break;
            default:
              setError("エラーが発生しました");
          }
        });
    }
  };

  const move1 = () => {
    signOut(auth).then((res) => {
      history.push("/");
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          component={Paper}
          elevation={6}
          square
          container
          direction="column"
          alignItems="center"
        >
          <Grid
            sx={{
              width: matches ? "50%" : "90%",
              my: 8,
              mx: matches ? 4 : 0,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              align="center"
            >
              SENKO-SO-MATCH
            </Typography>
            <Typography
              component="h1"
              variant={matches ? "h5" : "h6"}
              color="primary"
              marginTop={3}
              align="center"
            >
              アカウント登録
            </Typography>
            <Typography
              component="h1"
              variant={matches ? "subtitle1" : "subtitle2"}
              color="#000000"
              marginTop={3}
              align="center"
              sx={{ width: "100%" }}
            >
              ご登録のメールアドレス(ID)を入力し、送信ボタンを押してください。
              <br />
              メールアドレス確認のURLをお送りいたします。
            </Typography>
            <Grid container direction="column" alignItems="center">
              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: matches ? "50%" : "80%" }}
                onSubmit={register}
                name="login_form"
              >
                {success && <div className="auth__success">{success}</div>}
                {error && <div className="auth__error">{error}</div>}
                {password === confirmPassword
                  ? ""
                  : message && <div className="auth__error">{message}</div>}
                {success !== "" ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="email"
                    fullWidth
                    value={email}
                    required
                    label="メールアドレス"
                    placeholder="メールアドレスを入力してください。"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
                {success !== "" ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="password"
                    fullWidth
                    value={password}
                    required
                    inputProps={{ maxLength: 20, minLength: 8 }}
                    label="パスワード"
                    placeholder="パスワードを入力してください。"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      handleChange();
                    }}
                  />
                )}
                {success !== "" ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    required
                    inputProps={{ maxLength: 20, minLength: 8 }}
                    inputRef={inputRef}
                    label="パスワード(確認)"
                    placeholder="パスワード(確認)"
                    autoFocus
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      handleChange();
                      validatePw();
                    }}
                  />
                )}

                {success !== "" ? (
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {
                      move1();
                    }}
                  >
                    トップへ ＞
                  </Button>
                ) : password.length !== 0 && password === confirmPassword ? (
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    新規登録
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    disabled
                  >
                    新規登録
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default UserRegister;
