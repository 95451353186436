import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { AuthProvider } from "./AuthContext";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Action from "./Action";
import AdminRoute from "./AdminRoute";
import AdminLogin from "./AdminLogin";
import AdminPwReset from "./AdminPwReset";
import AdminChangePW from "./AdminChangePW";
import AdminChangeEmail from "./AdminChangeEmail";
import AdminUpdatedEmail from "./AdminUpdatedEmail";
import AdminUserList from "./AdminUserList";
import AdminAddWareHouse from "./AdminAddWareHouse";
import AdminEditWareHouse from "./AdminEditWareHouse";
import AdminWareHouseList from "./AdminWareHouseList";
import AdminEmptyAreaList from "./AdminEmptyAreaList";
import AdminAddEmptyArea from "./AdminAddEmptyArea";
import AdminEditEmptyArea from "./AdminEditEmptyArea";
import UserRoute from "./UserRoute";
import UserTopPage from "./UserTopPage";
import UserSearchResult from "./UserSearchResult";
import UserRegister from "./UserRegister";
import UserLogin from "./UserLogin";
import UserLogin1 from "./UserLogin1";
import UserPwReset from "./UserPwReset";
import UserChangePW from "./UserChangePW";
import UserChangeEmail from "./UserChangeEmail";
import UserUpdatedEmail from "./UserUpdatedEmail";
import StorageDetail from "./StorageDetail";
import SokoDetail from "./SokoDetail";
import UserFavorite from "./UserFavorite";
import AdminAddUser from "./AdminAddUser";
import AdminBulkAddUser from "./AdminBulkAddUser";
import AdminAddMaster from "./AdminAddMaster";
import UserSetting from "./UserSetting";
import ScrollTop from "./ScrollTop";
function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  const chk = () => {
    window.scroll(0, 0);
  };

  return (
    <Router>
      <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
        <ScrollTop />
        <Switch>
          <AdminRoute exact path="/admin" component={AdminEmptyAreaList} />
          <AdminRoute exact path="/admin/updatepw" component={AdminChangePW} />
          <AdminRoute
            exact
            path="/admin/updateemail"
            component={AdminChangeEmail}
          />
          <AdminRoute
            exact
            path="/admin/allAdminUsers"
            component={AdminUserList}
          />
          <AdminRoute
            exact
            path="/admin/addwarehouse"
            component={AdminAddWareHouse}
          />
          <AdminRoute
            exact
            path="/admin/editwarehouse"
            component={AdminEditWareHouse}
          />
          <AdminRoute
            exact
            path="/admin/warehouselist"
            component={AdminWareHouseList}
          />
          <AdminRoute
            exact
            path="/admin/addemptyarea"
            component={AdminAddEmptyArea}
          />
          <AdminRoute
            exact
            path="/admin/editemptyarea"
            component={AdminEditEmptyArea}
          />
          <AdminRoute
            exact
            path="/admin/emptyarealist"
            component={AdminEmptyAreaList}
          />
          <AdminRoute
            exact
            path="/admin/addmaster"
            component={AdminAddMaster}
          />
          <AdminRoute exact path="/admin/adduser" component={AdminAddUser} />
          <AdminRoute
            exact
            path="/admin/bulkadd"
            component={AdminBulkAddUser}
          />
          <UserRoute exact path="/updatepw" component={UserChangePW} />
          <UserRoute exact path="/updateemail" component={UserChangeEmail} />
          <UserRoute exact path="/setting" component={UserSetting} />
          <UserRoute exact path="/favorite" component={UserFavorite} />
          <Route
            exact
            path="/admin/emailupdated"
            component={AdminUpdatedEmail}
          />
          <Route exact path="/admin/login" component={AdminLogin} />
          <Route exact path="/admin/pwreset" component={AdminPwReset} />
          <Route exact path="/emailupdated" component={UserUpdatedEmail} />
          <Route exact path="/" component={UserTopPage} />
          <Route
            exact
            path="/search"
            component={UserSearchResult}
            onEnter={chk}
          />
          <Route exact path="/storagedetail" component={StorageDetail} />
          <Route exact path="/sokodetail" component={SokoDetail} />
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/userlogin" component={UserLogin1} />
          <Route exact path="/pwreset" component={UserPwReset} />
          <Route exact path="/register" component={UserRegister} />
          <Route exact path="/action" component={Action} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
