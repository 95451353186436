import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useHistory, Link } from "react-router-dom";
import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  generateEmailVerificationLink,
} from "firebase/auth";

const garantList = [
  //{ id: 1, name: "利用者" },
  { id: 2, name: "倉庫管理者" },
  { id: 3, name: "サイト管理者" },
];

const start = 1960;
let end = new Date().getFullYear() + 1;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const headers = {
  "Content-Type": "application/json",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

function AdminAddUser() {
  const { currentUser, masterData, userInfo } = useAuthValue();

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const [company, setCompany] = useState("");
  const [kankatsuShukanShiten, setKankatsuShukanShiten] = useState("");
  const [shiten, setShiten] = useState("");
  const [eigyosho, setEigyosho] = useState("");

  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [error2, setError2] = useState("");
  const [userEmail, setUserEmail] = useState(userInfo?.currentUser);
  const [userPw, setUserPw] = useState("");

  const [eigyobuId, setEigyobuId] = useState("");

  const history = useHistory();
  const [inputError, setInputError] = useState(false);

  const move = () => {
    history.push("/admin");
  };

  const inputRef = useRef(null);

  const handleChange = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };

  const validateEigyosho = () => {
    let isValid = true;
    if (eigyosho === "") {
      isValid = false;
      setError2("営業所が選択されていません");
    }
    return isValid;
  };
  const setKankatsuShu = (data) => {
    setCompany(data);
    const kankatsuSSId = kankatsuShukanShitenMList.filter(
      (item) => item.companyId === data
    )[0].kankatsuShukanShitenId;
    if (data > 1) {
      setKankatsuShukanShiten(kankatsuSSId);
      setShiten1(kankatsuSSId);
    }
  };

  const setShiten1 = (data) => {
    const shitenId1 = shitenMList.filter(
      (item) => item.kankatsuShitenId === data
    )[0].shitenId;
    setShiten(shitenId1);
  };

  const kankatsuShuList = kankatsuShukanShitenMList.filter(
    (item) => item.companyId === company
  );

  const shitenList = shitenMList.filter(
    (item) => item.kankatsuShitenId === kankatsuShukanShiten
  );

  const eigyoshoList = eigyoshoMList.filter((item) => item.shitenId === shiten);

  const eigyoshoSet = (data) => {
    setEigyosho(data);
    validateEigyosho();
  };

  const validatePw = () => {
    let isValid = true;
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        isValid = false;
        setMessage("パスワードが異なります");
      }
    }
    return isValid;
  };

  const mailSend = (link) => {
    console.log(link);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const body = {
      token: `${process.env.REACT_APP_SENDGRID_API_KEY}`,
      personalizations: [
        {
          to: [
            {
              email: email,
            },
          ],
          subject: "SENKO-SO-MATCH 仮パスワード発行",
        },
      ],
      from: {
        email: `${process.env.REACT_APP_SENDMAIL_EMAIL}`,
      },
      content: [
        {
          type: "text/plain",
          value: `SENKO-SO-MATCH 管理ページからあなたのアカウントが発行されました。\n\n以下のリンクよりアカウントの認証を行い、仮パスワードの変更を行なってください。\n\nメール認証：${link}\n\n登録メールアドレス：${email}\n\n仮パスワード：${password}\n\n`,
        },
      ],
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}sendgrid/sendMail`, body, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const register = (e) => {
    setError("");
    const createUser = {
      companyId: company,
      type: type,
      kankatsuShukanShitenId: kankatsuShukanShiten,
      shitenId: shiten,
      eigyoshoId: eigyosho,
      status: 1,
      email: email,
      isNotification: 0,
      password: password,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}user/addWithAuth`, createUser, {
        headers: headers,
      })
      .then((response) => {
        console.log("success");
        mailSend(response.data.link);
        setSuccess("入力したメールアドレスに確認メールを送信しました");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        switch (err.response.data.errors) {
          case "The email address is already in use by another account.":
            setError("すでにメールアドレスが使用されています");
            break;
          case "The password is invalid or the user does not have a password.":
            setError("パスワードが間違っています");
            break;
          case "auth/user-disabled":
            setError("ユーザーが無効です");
            break;
          case "There is no user record corresponding to this identifier. The user may have been deleted.":
            setError("ユーザーが存在しません");
            break;
          case "The email address is improperly formatted.":
            setError("メールアドレスの形式が間違っています");
            break;
          case "auth/too-many-requests":
            setError(
              "試行回数制限を超えました。時間をおいて再度お試しください"
            );
            break;
          default:
            setError("エラーが発生しました");
        }
      });
  };

  const move1 = () => {
    history.push("/admin");
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Toaster position="top-right" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                SENKO-SO-MATCH(登録サイト)
              </Typography>
              <AdminMenu />
            </Toolbar>
          </AppBar>
          <List component="nav" sx={{ mt: 10, width: 240 }}>
            <AdminListItems />
            <Divider sx={{ my: 1 }} />
          </List>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5">倉庫管理者(登録)</Typography>

                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid marginX="5%">
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        marginTop={2}
                      >
                        <Typography variant="h5">
                          {success !== ""
                            ? "倉庫管理者仮登録完了"
                            : "倉庫管理者登録"}
                        </Typography>
                      </Grid>

                      <Grid marginTop={5}>
                        {success && (
                          <div className="auth__success">{success}</div>
                        )}
                        {error && <div className="auth__error">{error}</div>}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">権限</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <InputLabel id="type">権限を選択</InputLabel>
                              <Select
                                sx={{ width: "50%" }}
                                labelId="type"
                                fullWidth
                                label="倉庫種別を選択"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                              >
                                <MenuItem disabled value="">
                                  権限を選択
                                </MenuItem>
                                {garantList.map((item, index) => {
                                  return (
                                    <MenuItem key={item.name} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Stack>
                        )}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Grid marginTop={1}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  メールアドレス
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <TextField
                                  sx={{ width: "50%" }}
                                  margin="normal"
                                  type="text"
                                  fullWidth
                                  required
                                  placeholder="入力してください"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </FormControl>
                            </Stack>
                          </Grid>
                        )}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Grid marginTop={1}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  仮パスワード
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <TextField
                                  sx={{ width: "50%" }}
                                  margin="normal"
                                  type="text"
                                  fullWidth
                                  required
                                  placeholder="入力してください"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </FormControl>
                            </Stack>
                          </Grid>
                        )}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">会社名</Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="company">
                                  会社名を選択
                                </InputLabel>
                                <Select
                                  sx={{ width: "50%" }}
                                  labelId="company"
                                  fullWidth
                                  label="会社名を選択"
                                  value={company}
                                  onChange={(e) => {
                                    setKankatsuShu(e.target.value);
                                    setCompany(e.target.value);
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    会社名を選択
                                  </MenuItem>
                                  {companyMList
                                    .sort((a, b) => a.sort - b.sort)
                                    .map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.companyId}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid>
                        )}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  管轄主管支店名
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="kankatsuShukanShiten">
                                  {kankatsuShuList.length === 1
                                    ? "なし"
                                    : "管轄主管支店を選択"}
                                </InputLabel>
                                {kankatsuShuList.length === 1 ? (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="kankatsuShukanShiten"
                                    fullWidth
                                    label={
                                      kankatsuShuList.length === 1
                                        ? "なし"
                                        : "管轄主管支店を選択"
                                    }
                                    value={
                                      kankatsuShuList.length === 1
                                        ? ""
                                        : kankatsuShukanShiten
                                    }
                                    onChange={(e) =>
                                      setKankatsuShukanShiten(e.target.value)
                                    }
                                    disabled
                                  >
                                    {kankatsuShuList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">
                                        管轄主管支店を選択
                                      </MenuItem>
                                    )}

                                    {kankatsuShuList.length === 1
                                      ? ""
                                      : kankatsuShuList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={
                                                  item.kankatsuShukanShitenId
                                                }
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                ) : (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="kankatsuShukanShiten"
                                    fullWidth
                                    label={
                                      kankatsuShuList.length === 1
                                        ? "なし"
                                        : "管轄主管支店を選択"
                                    }
                                    value={
                                      kankatsuShuList.length === 1
                                        ? ""
                                        : kankatsuShukanShiten
                                    }
                                    onChange={(e) =>
                                      setKankatsuShukanShiten(e.target.value)
                                    }
                                  >
                                    {kankatsuShuList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">
                                        管轄主管支店を選択
                                      </MenuItem>
                                    )}

                                    {kankatsuShuList.length === 1
                                      ? ""
                                      : kankatsuShuList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={
                                                  item.kankatsuShukanShitenId
                                                }
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                )}
                              </FormControl>
                            </Stack>
                          </Grid>
                        )}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">支店名</Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="sokoType">
                                  {shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? "支店名を選択"
                                    : shitenList.length === 1
                                    ? "なし"
                                    : "支店名を選択"}
                                </InputLabel>
                                {shitenList[0]?.shitenId === 28 ||
                                shitenList[0]?.shitenId === 25 ||
                                (shitenList.length !== 1 &&
                                  shitenList.length !== 0) ? (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="sokoType"
                                    fullWidth
                                    label={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? "支店名を選択"
                                        : shitenList.length === 1
                                        ? "なし"
                                        : "支店名を選択"
                                    }
                                    value={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? shiten
                                        : shitenList.length === 1
                                        ? ""
                                        : shiten
                                    }
                                    onChange={(e) =>
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? setShiten(e.target.value)
                                        : shitenList.length === 1
                                        ? ""
                                        : setShiten(e.target.value)
                                    }
                                  >
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25 ? (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    ) : shitenList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    )}
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25
                                      ? shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })
                                      : shitenList.length === 1
                                      ? ""
                                      : shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                ) : (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="sokoType"
                                    fullWidth
                                    label={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? "支店名を選択"
                                        : shitenList.length === 1
                                        ? "なし"
                                        : "支店名を選択"
                                    }
                                    value={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? shiten
                                        : shitenList.length === 1
                                        ? ""
                                        : shiten
                                    }
                                    onChange={(e) =>
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? setShiten(e.target.value)
                                        : shitenList.length === 1
                                        ? ""
                                        : setShiten(e.target.value)
                                    }
                                    disabled
                                  >
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25 ? (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    ) : shitenList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    )}
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25
                                      ? shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })
                                      : shitenList.length === 1
                                      ? ""
                                      : shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                )}
                              </FormControl>
                            </Stack>
                          </Grid>
                        )}
                        {success !== "" ? (
                          ""
                        ) : (
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">営業所名</Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="eigyosho">
                                  支店名を選択
                                </InputLabel>
                                <Select
                                  sx={{ width: "50%" }}
                                  labelId="state"
                                  fullWidth
                                  label={"営業所を選択"}
                                  value={eigyosho}
                                  onChange={(e) => eigyoshoSet(e.target.value)}
                                >
                                  <MenuItem value="">営業所を選択</MenuItem>
                                  {eigyoshoList.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.eigyoshoId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                {eigyosho !== ""
                                  ? ""
                                  : error2 && (
                                      <Typography
                                        variant="subtitle1"
                                        color="error"
                                      >
                                        {error2}
                                      </Typography>
                                    )}
                              </FormControl>
                            </Stack>
                          </Grid>
                        )}
                        {success !== "" ? (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Typography variant="h6">
                              倉庫管理者の仮登録が完了いたしました。
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {success !== "" ? (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 1, marginBottom: 3 }}
                          >
                            <Typography variant="h6">
                              倉庫管理者の本登録完了後に利用可能となります。
                            </Typography>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {success !== "" ? (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 5, marginBottom: 3 }}
                          >
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ width: 250 }}
                              onClick={move}
                            >
                              トップページ ＞
                            </Button>
                          </Grid>
                        ) : email !== "" &&
                          email.length > 0 &&
                          password !== "" &&
                          eigyosho !== "" ? (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 5 }}
                          >
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ width: 250 }}
                              onClick={(e) => register(e)}
                            >
                              登録
                            </Button>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 5 }}
                          >
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ width: 250 }}
                              disabled
                            >
                              登録
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Copyright sx={{ pt: 2 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default AdminAddUser;
