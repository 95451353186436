import React from "react";
import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ImageListItem from "@mui/material/ImageListItem";
import Masonry from "@mui/lab/Masonry";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import "./App.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import AspectRatio from "@mui/joy/AspectRatio";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { CssVarsProvider } from "@mui/joy/styles";
import InputLabel from "@mui/material/InputLabel";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const LabelBottom = styled(Paper)(() => ({
  backgroundColor: "#FFFFFF",
  padding: 0.5,
  textAlign: "center",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}));

const style = {
  position: "absolute",
  top: "5%",
  bottom: "5%",
  left: "5%",
  right: "5%",
  width: "90%",
  height: "90%",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
};

const style1 = {
  position: "absolute",
  top: 0,
  width: "100vw",
  height: "100%",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function MediaManager(props) {
  const inputRef = useRef(null);
  const [mediaModal, setMediaModal] = useState(false);
  const [mediaAlert, setMediaAlert] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [deleteRes, setDeleteRes] = useState(false);
  const matches = useMediaQuery("(min-width:850px)");
  const storage = getStorage();
  const getImg = async () => {
    const listRef = ref(
      storage,
      `${process.env.REACT_APP_GS_URL}soko/${props.sokoId}/thumb`
    );
    const list = await listAll(listRef);
    const urls = await Promise.all(
      list.items.map((ref) => getDownloadURL(ref))
    );
    const decodeUrls = urls.map(
      (item) =>
        `${process.env.REACT_APP_IMG_URL}soko/${props.sokoId}/thumb/` +
        decodeURIComponent(item).split("thumb/")[1].split("?")[0]
    );
    setImageList(urls);
    props.setImageList(urls);
  };
  useEffect(() => {
    getImg();
  }, []);

  const handleChangeImage = (e) => {
    const uploadDataRef = ref(
      storage,
      `${process.env.REACT_APP_GS_URL}soko/${props.sokoId}/${props.sokoId}-000${
        imageList.length + 1
      }`
    );
    const uploadTask = uploadBytesResumable(uploadDataRef, e.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        /* getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
        }); */

        if (!alert("アップロードしました。")) {
          getImg();
        }
      }
    );
  };

  const handleDleteFiles = (e, item, itemData) => {
    e.stopPropagation();
    const storage = getStorage();
    const desertRef = ref(
      storage,
      `${process.env.REACT_APP_GS_URL}soko/${props.sokoId}/thumb` +
        decodeURIComponent(item).split("thumb")[1].split("?")[0]
    );
    deleteObject(desertRef)
      .then((res) => {
        setDeleteRes(true);
        itemData.length = 0;
        setItemData([]);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const handleAlertOpen = (e, index) => {
    e.stopPropagation();
    if (mediaAlert === index) {
      setMediaAlert("");
    } else {
      setMediaAlert(index);
    }
  };
  const handleAlertClose = (e) => {
    e.stopPropagation();
    setMediaAlert(false);
    setDeleteRes(false);
    getImg();
  };

  const handleAlertClose1 = (e) => {
    e.stopPropagation();
    setMediaAlert(false);
    setDeleteRes(false);
    getImg();
  };
  const handleMediaModaleOpen = () => {
    setMediaModal(true);
  };

  const handleMediaModalClose = () => {
    setMediaModal(false);
  };

  const clickFileUploadButton = () => {
    inputRef.current.click();
  };

  const handleImageData = (e) => {
    const value = e.target.value;
    setItemData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    props.setImage(typeof value === "string" ? value.split(",") : value);

    e.target.value = "";
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ width: 200 }}
        onClick={() => {
          handleMediaModaleOpen();
        }}
      >
        画像アップロード
      </Button>
      <Modal
        hideBackdrop
        open={mediaModal}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <Paper
          sx={{
            mt: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            ...style,
          }}
        >
          <Grid>
            <Box sx={{ ml: 0, mr: 0, mt: 5, mb: 5, minHeight: "auto" }}>
              <Grid container direction="column" alignItems="center">
                <FormControl sx={{ width: "100%" }}>
                  <Masonry columns={{ xs: 2, sm: 3, lg: 4 }} spacing={2}>
                    {imageList.map((item, index) => (
                      <ImageListItem key={index}>
                        <Grid width="100%">
                          <img
                            key={index + index}
                            src={`${item}`}
                            loading="lazy"
                            style={{
                              borderTopLeftRadius: 4,
                              borderTopRightRadius: 4,
                              display: "block",
                              width: "100%",
                            }}
                            className="imagelist"
                          />
                          <Grid className="mediadelbutton ">
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={(e) => {
                                handleAlertOpen(e, index);
                              }}
                            >
                              <Typography variant="subtitle2">
                                Delete
                              </Typography>
                            </Button>
                            <Dialog
                              open={index === mediaAlert}
                              onClose={(e) => {
                                handleAlertClose(e);
                              }}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                CAUTION
                              </DialogTitle>
                              <DialogContent>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                >
                                  <Paper sx={{ width: "30vw" }}>
                                    {deleteRes === true ? (
                                      ""
                                    ) : (
                                      <CssVarsProvider>
                                        <AspectRatio objectFit="contain">
                                          <img
                                            src={item}
                                            className="storedtailsliderbg"
                                          />
                                        </AspectRatio>
                                      </CssVarsProvider>
                                    )}
                                  </Paper>
                                </Grid>
                                {deleteRes === true ? (
                                  <Typography variant="subtitle1" marginTop={2}>
                                    画像を削除しました
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle1" marginTop={2}>
                                    この画像を削除しますか？
                                  </Typography>
                                )}
                              </DialogContent>
                              <DialogActions>
                                {deleteRes === true ? (
                                  ""
                                ) : (
                                  <Button
                                    sx={{
                                      width: "30%",
                                      marginRight: 1,
                                      marginBottom: 2,
                                    }}
                                    variant="contained"
                                    color="inherit"
                                    onClick={(e) => {
                                      handleAlertClose(e);
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      いいえ
                                    </Typography>
                                  </Button>
                                )}
                                {deleteRes === true ? (
                                  <Button
                                    sx={{
                                      width: "30%",
                                      marginRight: 2,
                                      marginBottom: 2,
                                    }}
                                    variant="contained"
                                    color="inherit"
                                    onClick={(e) => {
                                      handleAlertClose1(e);
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      とじる
                                    </Typography>
                                  </Button>
                                ) : (
                                  <Button
                                    sx={{
                                      width: "30%",
                                      marginRight: 2,
                                      marginBottom: 2,
                                    }}
                                    variant="contained"
                                    color="error"
                                    onClick={(e) => {
                                      handleDleteFiles(e, item, itemData);
                                    }}
                                  >
                                    <Typography variant="subtitle1">
                                      削除
                                    </Typography>
                                  </Button>
                                )}
                              </DialogActions>
                            </Dialog>
                          </Grid>
                          <LabelBottom>
                            <Typography>
                              {
                                decodeURIComponent(item)
                                  .split("thumb/")[1]
                                  .split("?")[0]
                              }
                            </Typography>
                          </LabelBottom>
                        </Grid>
                      </ImageListItem>
                    ))}
                  </Masonry>
                </FormControl>
              </Grid>
            </Box>

            <Grid
              container
              direction="column"
              alignItems="center"
              sx={{ marginBottom: 3 }}
            >
              <Grid sx={{ width: "60%" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={clickFileUploadButton}
                  /* upload={upload} */
                >
                  アップロード
                </Button>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleChangeImage}
                />
              </Grid>
              <Grid sx={{ width: "60%", marginTop: 5 }}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={handleMediaModalClose}
                >
                  とじる
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default MediaManager;
