import { useState } from "react";
import "./forms.css";
import { auth } from "./firebase";
import { useHistory, Link } from "react-router-dom";
import { sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
const theme = createTheme();

function UserPwReset() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState("");
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  //const {setTimeActive} = useAuthValue()

  const pwreset = (e) => {
    e.preventDefault();
    setError("");
    // Create a new user with email and password using firebase
    const ActionCodeSettiings = {
      url: `${process.env.REACT_APP_SITE_URL}/login`,
    };
    sendPasswordResetEmail(auth, email, ActionCodeSettiings)
      .then(() => {
        //setTimeActive(true)
        setSuccess("メールを送信しました");
      })
      .catch((err) => {
        console.log(err.message);
        err.message === "Firebase: Error (auth/user-not-found)."
          ? setError("このメールアドレスは登録されていません")
          : console.log(err.message);
      });
    setEmail("");
  };

  const moveHome = () => {
    history.push("/");
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          component={Paper}
          elevation={6}
          square
          container
          direction="column"
          alignItems="center"
        >
          <Grid
            sx={{
              width: matches ? "50%" : "90%",
              my: 8,
              mx: matches ? 4 : 0,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant={matches ? "h5" : "h6"}
              color="primary"
              align="center"
            >
              SENKO-SO-MATCH
            </Typography>
            <Typography
              component="h1"
              variant={matches ? "h5" : "h6"}
              color="primary"
              marginTop={3}
              align="center"
            >
              パスワードをお忘れの方
            </Typography>
            <Typography
              component="h1"
              variant={matches ? "subtitle1" : "subtitle2"}
              color="#000000"
              marginTop={3}
              align="center"
              sx={{ width: "100%" }}
            >
              ご登録のメールアドレス(ID)を入力し、送信ボタンを押してください。
              <br />
              パスワード再設定用のURLをお送りいたします。
            </Typography>
            <Grid container direction="column" alignItems="center">
              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: matches ? "50%" : "80%" }}
                onSubmit={pwreset}
                name="login_form"
              >
                {success && <div className="auth__success">{success}</div>}
                {error && <div className="auth__error">{error}</div>}
                {success !== "" ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="email"
                    fullWidth
                    value={email}
                    required
                    label="メールアドレス"
                    autoComplete="email"
                    placeholder="メールアドレス"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}

                {success !== "" ? (
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={moveHome}
                  >
                    もどる
                  </Button>
                ) : email !== "" ? (
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    送信
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    color="success"
                    disabled
                    sx={{ mt: 3, mb: 2 }}
                  >
                    送信
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default UserPwReset;
