import react from "react";
import { useState, useEffect, useRef } from "react";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  inMemoryPersistence,
  setPersistence,
  applyActionCode,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./forms.css";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import "./App.css";

// URLから指定したクエリのバリューを抜き出す関数
const getParameterByName = (name) => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * 諸々のactionを行うためのページ
 */
const Action = () => {
  const { currentUser, userInfo } = useAuthValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [inputError, setInputError] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [regError, setRegError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const history = useHistory();
  const [state, setState] = useState({
    mode: null,
    actionCode: null,
    continueUrl: null,
    mail: null,
  });

  console.log(state.continueUrl);
  const inputRef = useRef(null);

  const handleChange = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };

  console.log("context", currentUser);
  console.log("userInfo", userInfo);

  const validatePw = () => {
    let isValid = true;
    if (newPassword !== "" && confirmNewPassword !== "") {
      if (newPassword !== confirmNewPassword) {
        isValid = false;
        setMessage("パスワードが異なります");
      }
    }
    return isValid;
  };
  console.log("state", state);
  useEffect(() => {
    console.log("1");
    const mode = getParameterByName("mode");
    //const mode = "verifyEmail";
    const actionCode = getParameterByName("oobCode");

    const continueUrl =
      mode === "recoverEmail"
        ? "/"
        : getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/mail/")[0];
    const mail =
      mode === "recoverEmail"
        ? ""
        : continueUrl === "/login"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/mail/")[1]
        : continueUrl === "/admin"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/mail/")[1]
            .split("/grant/")[0]
        : continueUrl === "/verified"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/mail/")[1]
        : continueUrl === "/userverified"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/mail/")[1]
        : continueUrl === "/emailupdated"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/mail/")[1]
        : "";

    const grant =
      mode === "recoverEmail"
        ? ""
        : continueUrl === "/login"
        ? ""
        : continueUrl === "/emailupdated"
        ? ""
        : continueUrl === "/admin"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/company/")[1]
            .split("/kankatsuShukan/")[0]
        : "";
    const company =
      mode === "recoverEmail"
        ? ""
        : continueUrl === "/login"
        ? ""
        : continueUrl === "/emailupdated"
        ? ""
        : continueUrl === "/admin"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/company/")[1]
            .split("/kankatsuShukan/")[0]
        : "";

    const kankatsuShukan =
      mode === "recoverEmail"
        ? ""
        : continueUrl === "/login"
        ? ""
        : continueUrl === "/emailupdated"
        ? ""
        : continueUrl === "/admin"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/kankatsuShukan/")[1]
            .split("/shitenId/")[0]
        : "";
    const shiten =
      mode === "recoverEmail"
        ? ""
        : continueUrl === "/login"
        ? ""
        : continueUrl === "/emailupdated"
        ? ""
        : continueUrl === "/admin"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/shitenId/")[1]
            .split("/eigyosho/")[0]
        : "";

    const eigyosho =
      mode === "recoverEmail"
        ? ""
        : continueUrl === "/login"
        ? ""
        : continueUrl === "/admin"
        ? getParameterByName("continueUrl")
            .split(`${process.env.REACT_APP_SITE_URL}`)[1]
            .split("/eigyosho/")[1]
        : "";

    console.log("continueUrl", continueUrl);
    console.log("mail", mail);
    console.log("grant", grant);
    console.log("company", company);
    console.log("kankatsuShukan", kankatsuShukan);
    console.log("shiten", shiten);
    console.log("eigyosho", eigyosho);

    if (!mode || !actionCode || !continueUrl) {
      throw new Error("invalid url");
    }
    console.log("2");
    setState({
      mode,
      actionCode,
      continueUrl,
      mail,
    });

    if (!actionCode) {
      throw new Error("invalid url");
    }
    if (
      (mode === "verifyEmail" && continueUrl === "/login") ||
      (mode === "verifyEmail" && continueUrl === "/admin")
    ) {
      console.log("3");
      getAuth();
      applyActionCode(auth, actionCode)
        .then((resp) => {
          console.log(resp);
          console.log(auth);
          console.log(continueUrl);
          console.log("success apply action code");
        })
        .catch((err) => {
          console.log("5");
          console.log(err);
          alert("無効なアクセスです");
          console.log("login", err);
          signOut(auth).then((res) => {
            history.push("/");
          });
        });
    } else if (mode === "recoverEmail") {
      console.log("6");
      getAuth();

      console.log(state.mode);
      console.log("else", auth, actionCode);
      applyActionCode(auth, actionCode)
        .then((response) => {
          console.log("7");
          console.log("reset", response);
        })
        .catch((error) => {
          console.log("9");
          console.log("err", error.response);
          alert("無効なアクセスです");
          signOut(auth).then((res) => {
            history.push("/");
          });
        });
    } else if (
      (mode === "verifyEmail" && continueUrl === "/verified") ||
      (mode === "verifyEmail" && continueUrl === "/userverified") ||
      (mode === "verifyEmail" && continueUrl === "/emailupdated")
    ) {
      getAuth();
      applyActionCode(auth, actionCode).catch((error) => {
        console.log("err", error.response);
        alert("無効なアクセスです");
        signOut(auth).then((res) => {
          history.push("/");
        });
      });
    } else {
    }
  }, []);

  const move = () => {
    console.log(state?.continueUrl);
    state.continueUrl === "/verified"
      ? history.push("/admin")
      : state.continueUrl === "/userverified"
      ? history.push("/")
      : state.continueUrl === "/login"
      ? history.push("/userlogin")
      : history.push(state?.continueUrl);
  };

  const move1 = () => {
    history.push("/");
  };

  const move2 = () => {
    history.push("/admin");
  };

  const changePw = (e) => {
    e.preventDefault();
    console.log("changePw");
    if (validatePw()) {
      setError("");
      getAuth();
      console.log(auth, state.actionCode);
      verifyPasswordResetCode(auth, state.actionCode).then(function (email) {
        getAuth();
        confirmPasswordReset(auth, state.actionCode, newPassword)
          .then(async (resp) => {
            console.log("CP resp", resp);
            setUpdated(true);
            setEmail("");
            setNewPassword("");
            setConfirmNewPassword("");
          })
          .catch(function (error) {
            // エラーの原因としてはトークンの有効期限切れ、もしくは弱すぎるパスワード

            console.log("pwChangeError", error);
            alert("無効なアクセスです");
            signOut(auth).then((res) => {
              history.push("/");
            });
          });
      });
    }
  };

  const theme = createTheme();

  return (
    <div>
      {state?.mode === "resetPassword" ? (
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              elevation={6}
              square
              container
              direction="column"
              alignItems="center"
            >
              <Grid
                sx={{
                  width: "50%",
                  my: 8,
                  mx: 4,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  color="primary"
                  align="center"
                >
                  SENKO-SO-MATCH
                </Typography>
                <Typography
                  component="h1"
                  variant="h5"
                  color="primary"
                  marginTop={3}
                  align="center"
                >
                  {updated === true
                    ? "パスワード再設定完了"
                    : "パスワードを変更いたします"}
                </Typography>
                <Typography
                  component="h1"
                  variant="subtitle1"
                  color="#000000"
                  marginTop={3}
                  align="center"
                  sx={{ width: "100%" }}
                >
                  {updated === true ? (
                    <div>
                      パスワードの再設定が完了いたしました。
                      <br />
                      新しいパスワードでログインしてください。
                    </div>
                  ) : (
                    ""
                  )}
                </Typography>
                <Grid container direction="column" alignItems="center">
                  <Box
                    component="form"
                    noValidate
                    sx={{ mt: 1, width: "50%" }}
                    name="login_form"
                  >
                    {inputError ? (
                      <div className="auth__error">
                        パスワードは8文字以上20 文字以下で入力してください
                      </div>
                    ) : (
                      ""
                    )}
                    {error && <div className="auth__error">{error}</div>}
                    {newPassword === confirmNewPassword
                      ? ""
                      : message && <div className="auth__error">{message}</div>}

                    {updated === true ? (
                      ""
                    ) : (
                      <TextField
                        margin="normal"
                        type="email"
                        fullWidth
                        value={email}
                        required
                        label="メールアドレス"
                        placeholder="メールアドレス"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                    {updated === true ? (
                      ""
                    ) : (
                      <TextField
                        margin="normal"
                        type="password"
                        fullWidth
                        value={newPassword}
                        required
                        inputProps={{ maxLength: 20, minLength: 8 }}
                        inputRef={inputRef}
                        label="新しいパスワード"
                        placeholder="新しいパスワード"
                        autoFocus
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          handleChange();
                        }}
                      />
                    )}
                    {updated === true ? (
                      ""
                    ) : (
                      <TextField
                        margin="normal"
                        type="password"
                        fullWidth
                        value={confirmNewPassword}
                        required
                        inputProps={{ maxLength: 20, minLength: 8 }}
                        inputRef={inputRef}
                        label="新しいパスワード(確認)"
                        placeholder="新しいパスワード(確認)"
                        autoFocus
                        onChange={(e) => {
                          setConfirmNewPassword(e.target.value);
                          handleChange();
                          validatePw();
                        }}
                      />
                    )}
                    {updated === true && state.continueUrl === "/login" ? (
                      <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                          move1();
                        }}
                      >
                        トップへ ＞
                      </Button>
                    ) : updated === true &&
                      state.continueUrl === "/admin/login" ? (
                      <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                          move2();
                        }}
                      >
                        トップへ ＞
                      </Button>
                    ) : newPassword === confirmNewPassword &&
                      email !== "" &&
                      newPassword !== "" ? (
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="success"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={(e) => changePw(e)}
                      >
                        送信
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="success"
                        sx={{ mt: 3, mb: 2 }}
                        disabled
                      >
                        送信
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      ) : state?.mode === "signIn" ? (
        "now singining..."
      ) : state?.mode === "verifyEmail" ? (
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              elevation={6}
              square
              container
              direction="column"
              alignItems="center"
            >
              <Grid
                sx={{
                  width: "50%",
                  my: 8,
                  mx: 4,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  color="primary"
                  align="center"
                >
                  SENKO-SO-MATCH
                </Typography>
                <Typography
                  component="h1"
                  variant="subtitle1"
                  color="#000000"
                  marginTop={3}
                  align="center"
                  sx={{ width: "100%" }}
                >
                  {regError === true ? (
                    <div>すでに登録されています</div>
                  ) : (
                    <div>
                      メールアドレスの確認が完了しました。
                      <br />
                      次へをクリックしてください。
                    </div>
                  )}
                </Typography>
                <Grid container direction="column" alignItems="center">
                  <Box
                    component="form"
                    noValidate
                    sx={{ mt: 1, width: "50%" }}
                    name="login_form"
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      color="success"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => {
                        move();
                      }}
                    >
                      次へ ＞
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      ) : state?.mode === "recoverEmail" ? (
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              elevation={6}
              square
              container
              direction="column"
              alignItems="center"
            >
              <Grid
                sx={{
                  width: "50%",
                  my: 8,
                  mx: 4,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  color="primary"
                  align="center"
                >
                  SENKO-SO-MATCH
                </Typography>
                <Typography
                  component="h1"
                  variant="subtitle1"
                  color="#000000"
                  marginTop={3}
                  align="center"
                  sx={{ width: "100%" }}
                >
                  <div>
                    ログインメールアドレスの変更をキャンセルしました。
                    <br />
                    元のメールアドレスでログインしてください。
                  </div>
                </Typography>
                <Grid container direction="column" alignItems="center">
                  <Box
                    component="form"
                    noValidate
                    sx={{ mt: 1, width: "50%" }}
                    name="login_form"
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      color="success"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => {
                        move1();
                      }}
                    >
                      次へ ＞
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      ) : (
        <div>error: modeが選択されていない不正なURLです。</div>
      )}
    </div>
  );
};

export default Action;
