import { useState } from "react";
import { Link } from "react-router-dom";
import "./forms.css";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";

const theme = createTheme();

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const { setTimeActive } = useAuthValue();
  const [flag, setFlag] = useState(false);
  const history = useHistory();

  const login = (e) => {
    e.preventDefault();
    setFlag(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        if (!auth.currentUser.emailVerified) {
          const ActionCodeSettiings = {
            url: `${process.env.REACT_APP_SITE_URL}/verified/mail/${email}`,
          };

          sendEmailVerification(auth.currentUser, ActionCodeSettiings)
            .then(() => {
              setError("メールアドレスが認証されていません");
              setError2("確認メールをご確認ください");
            })
            .catch((err) => {
              console.log(err.message);
              alert(err.message);
            });
        } else {
          const getUser = {
            firebaseUuid: auth.currentUser.uid,
          };
          const headers = {
            "Content-Type": "application/json",
            //Authorization: `Bearer ${props.accessToken}`,
          };
          axios
            .post(`${process.env.REACT_APP_API_URL}user/getUserInfo`, getUser, {
              headers: headers,
            })
            .then((response) => {
              console.log(auth.currentUser);
              console.log("success");
              console.log(
                "update",
                auth.currentUser.email !== response.data?.result[0].email
              );
              if (auth.currentUser.email !== response.data?.result[0].email) {
                const updateUser = {
                  email: auth.currentUser.email,
                  firebaseUuid: auth.currentUser.uid,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}user/updateUserByUuid`,
                    updateUser,
                    { headers: headers }
                  )
                  .then((res) => console.log(res));
              }

              response.data?.result[0].type === 2 ||
              response.data?.result[0].type === 3
                ? history.push("/admin")
                : setError("アクセス権限がありません");
            })
            .catch((error) => {
              setError("ユーザーが存在しません");
              console.log("err", error.response.data);
            });
        }
      })

      .catch((err) => setError("IDまたはパスワードが異なります"));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          component={Paper}
          elevation={6}
          square
          container
          direction="column"
          alignItems="center"
        >
          <Grid
            sx={{
              width: "50%",
              my: 8,
              mx: 4,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              align="center"
            >
              SENKO-SO-MATCH
            </Typography>
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              marginTop={3}
              align="center"
            >
              登録サイトログイン
            </Typography>
            <Grid container direction="column" alignItems="center">
              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: "50%" }}
                name="login_form"
              >
                {error && <div className="auth__error">{error}</div>}
                {error2 && <div className="auth__error">{error2}</div>}
                {flag === true ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="email"
                    fullWidth
                    value={email}
                    required
                    label="メールアドレス"
                    placeholder="メールアドレスを入力してください。"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}

                {flag === true ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="password"
                    fullWidth
                    value={password}
                    required
                    label="パスワード"
                    placeholder="パスワードを入力してください。"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                )}

                {error !== "" ? (
                  <Button
                    variant="contained"
                    fullWidth
                    color="error"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {
                      setEmail("");
                      setPassword("");
                      setError("");
                      setError2("");
                      setFlag(false);
                    }}
                  >
                    もどる
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(e) => login(e)}
                  >
                    ログイン
                  </Button>
                )}
                <Typography variant="body1" color="info" align="center">
                  パスワードを忘れた場合は
                  <Link to="/admin/pwreset">こちら</Link>
                  をクリック
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default AdminLogin;
