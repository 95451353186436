import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MuiDrawer from "@mui/material/Drawer";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { storeListItems, userListItems } from "./MenuList";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import AspectRatio from "@mui/joy/AspectRatio";
import { CssVarsProvider } from "@mui/joy/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Cancel, Tag, Verified } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Thumbs, FreeMode, Navigation, Pagination } from "swiper";
import Conditions from "./Conditions";
import UserMenu from "./UserMenu";
import Map from "./Map";
import dayjs from "dayjs";
// import css
import "./App.css";
import "swiper/css";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const today = new Date();
const ago = new Date(today.setDate(today.getDate() - 14));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const resFavoriteList = (resData) => {
  const favoriteData = [];

  resData.forEach((okini) => {
    const storageId = okini.storageId;
    favoriteData.push(storageId);
  });

  return favoriteData;
};

function StorageDetail(props) {
  const { currentUser, userInfo, masterData, totalCount, newCount } =
    useAuthValue();
  const matches = useMediaQuery("(min-width:1050px)");
  const history = useHistory();
  const [favorite, setFavorite] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [favoriteData, setFavoriteData] = useState([]);
  const [favoriteData1, setFavoriteData1] = useState([]);
  const [image, setImage] = useState(["https://picsum.photos/600/400"]);

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const item = props.location.state.item;
  const image1 = props.location.state?.item.image;

  const searchParams = props.location.state?.searchParams;
  const search2Params = props.location.state?.search2Params;

  const addFavorite = (item, e) => {
    const addLike = {
      storageId: item,
      userId: userInfo?.userId,
    };
    if (e.target.checked === true) {
      userInfo?.userId !== undefined
        ? axios
            .post(`${process.env.REACT_APP_API_URL}storage/addlike`, addLike, {
              headers: headers,
            })
            .then((response) => {
              getOkini();
            })
        : history.push({
            pathname: "/login",
          });
    } else {
      const delId = {
        okiniId: [favoriteData.find((e) => e.storageId === item).okiniId],
      };

      userInfo?.userId !== undefined
        ? axios
            .post(
              `${process.env.REACT_APP_API_URL}storage/deletelikeM`,
              delId,
              { headers: headers }
            )
            .then((response) => {
              getOkini();
            })
        : history.push({
            pathname: "/login",
          });
    }
  };

  const getOkini = () => {
    setFavoriteData([]);
    setFavoriteData1([]);
    const userFavorite = {
      userId: userInfo?.userId,
      sortKey: "updateAt",
      ascdesc: "desc",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/getLike`, userFavorite, {
        headers: headers,
      })
      .then((response) => {
        const data = response.data.result;
        const data1 = data.filter((e) => e.status === 1);

        const data2 = resFavoriteList(data1);
        const set = new Set(data2);

        setFavoriteData(data);
        setFavoriteData1([...set]);
      });
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: searchParams,
      },
    });
  };

  const moveSearchList = (item) => {
    props.location.state.type === 0
      ? history.push({
          pathname: "/favorite",
          state: {
            searchParams: searchParams,
          },
        })
      : props.location.state.type === 1
      ? history.push({
          pathname: "/",
          state: {
            searchParams: searchParams,
          },
        })
      : history.push({
          pathname: "/search",
          state: {
            searchParams: searchParams,
          },
        });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  useEffect(() => {
    getOkini();
  }, []);

  return (
    <>
      <Grid>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="absolute"
            sx={{
              "@media print": {
                display: "none",
              },
            }}
          >
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block", marginRight: 5 }}
                onClick={() => moveTop()}
                className="pointer"
              >
                {matches ? "SENKO-SO-MATCH" : "SSM"}
              </Typography>
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  登録
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  ccolor="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  {totalCount}
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block", marginRight: 3 }}
                >
                  棟
                </Typography>
              ) : (
                ""
              )}

              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  新着
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  {newCount}
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block", flexGrow: 1 }}
                >
                  棟
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Button onClick={() => moveTop()}>
                  <Typography color="#FFFFFF">ホーム</Typography>
                </Button>
              ) : (
                ""
              )}
              {currentUser?.email !== undefined && matches ? (
                <Button onClick={() => moveFavorite()}>
                  <Typography color="#FFFFFF">お気に入り</Typography>
                </Button>
              ) : (
                ""
              )}
              {currentUser?.email !== undefined ? (
                <UserMenu />
              ) : (
                <Button
                  sx={{ marginLeft: matches ? 0 : 15 }}
                  component={Link}
                  to="/login"
                  variant="outlined"
                  color="inherit"
                >
                  <Typography color="#FFFFFF">ログイン</Typography>
                </Button>
              )}
            </Toolbar>
          </AppBar>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              "@media print": { bgcolor: "#FFFFFF" },
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Grid
                    marginBottom={-5}
                    sx={{
                      "@media print": {
                        display: "none",
                      },
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        background: "gray",
                        color: "#ffffff",
                        ":hover": { background: "#6C676E" },
                      }}
                      onClick={() => moveSearchList(item)}
                    >
                      ＜ 戻る
                    </Button>
                  </Grid>
                  <Grid
                    className="storeexpand"
                    sx={{
                      "@media print": {
                        display: "none",
                      },
                    }}
                  >
                    <Grid className="storeexpand">
                      <Conditions
                        searchParams={searchParams}
                        search2Params={search2Params}
                        prefecture={
                          props.location.state?.prefecture ||
                          props.location.state.searchParams?.prefectureId ||
                          props.location.state.search2Params?.prefectureId
                        }
                        area={
                          props.location.state?.area ||
                          props.location.state.searchParams?.areaId ||
                          props.location.state.search2Params?.areaId
                        }
                        sokoTypeId={
                          props.location.state?.sokoType ||
                          props.location.state.searchParams?.sokoTypeId ||
                          props.location.state.search2Params?.sokoTypeId
                        }
                        contractId={
                          props.location.state?.contract ||
                          props.location.state.searchParams?.contractId ||
                          props.location.state.search2Params?.contractId
                        }
                        kinoKeitaiId={
                          props.location.state?.kinoKeitai ||
                          props.location.state.searchParams?.KinoKeitaiId ||
                          props.location.state.search2Params?.KinoKeitaiId
                        }
                        specId={
                          props.location.state?.spec ||
                          props.location.state.searchParams?.specId ||
                          props.location.state.search2Params?.specId
                        }
                        taiouGyotaiId={
                          props.location.state?.taiouGyotai ||
                          props.location.state.searchParams?.taiouGyotaiId ||
                          props.location.state.search2Params?.taiouGyotaiId
                        }
                      />
                    </Grid>
                  </Grid>

                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      "@media print": {
                        mt: -50,
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={5} xl={5} marginY={1}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          marginTop={-2}
                          sx={{
                            "@media print": {
                              display: "none",
                            },
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            marginRight={1}
                            onClick={() => moveTop()}
                            className="pointer"
                          >
                            TOP
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {">"}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            marginRight={1}
                            onClick={() => moveSearchList()}
                            className="pointer"
                          >
                            {item.okiniId !== undefined
                              ? "お気に入り"
                              : "倉庫検索結果"}
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {">"}
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {item.sokoName}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={5}>
                          <Typography
                            variant="h5"
                            marginTop={2}
                            marginBottom={2}
                            sx={{
                              "@media print": {
                                position: "absolute",
                                width: "100%",
                                top: 5,
                              },
                            }}
                          >
                            {item.sokoName}
                          </Typography>
                          <Grid>
                            {new Date(item.createAt).getTime() <
                            ago.getTime() ? (
                              ""
                            ) : (
                              <Chip
                                label="新着"
                                variant="outlined"
                                color="error"
                                sx={{ fontWeight: "bold", marginRight: 2 }}
                              />
                            )}
                            {item.soon === 0 ? (
                              ""
                            ) : (
                              <Chip
                                label="引合有り"
                                variant="outlined"
                                color="error"
                                sx={{ fontWeight: "bold", marginRight: 1 }}
                              />
                            )}
                          </Grid>
                        </Stack>
                        <Grid
                          sx={{
                            "@media print": { display: "none" },
                          }}
                        >
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            autoplay={{
                              delay: 10000,
                              disableOnInteraction: false,
                            }}
                            pagination={{
                              clickable: true,
                            }}
                            navigation={true}
                            loop={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[Autoplay, Thumbs, Navigation, Pagination]}
                          >
                            {image1?.length === 0 ? (
                              <SwiperSlide>
                                <CssVarsProvider>
                                  <AspectRatio objectFit="contain">
                                    <img
                                      src={`${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`}
                                    />
                                  </AspectRatio>
                                </CssVarsProvider>
                              </SwiperSlide>
                            ) : (
                              image1.map((elms, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <CssVarsProvider>
                                      <AspectRatio objectFit="contain">
                                        <img src={elms} />
                                      </AspectRatio>
                                    </CssVarsProvider>
                                  </SwiperSlide>
                                );
                              })
                            )}
                          </Swiper>
                        </Grid>
                        <Grid
                          sx={{
                            "@media not print": {
                              display: "none",
                            },
                            "@media print": {
                              position: "absolute",
                              width: "50%",
                              top: 65,
                              right: 360,
                            },
                          }}
                        >
                          <CssVarsProvider>
                            <AspectRatio
                              objectFit="contain"
                              sx={{
                                "-webkit-print-color-adjust": "exact",
                                "@media print": {
                                  width: "93%",
                                  ml: -1,
                                },
                              }}
                            >
                              <img
                                src={
                                  image1?.length === 0
                                    ? `${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`
                                    : image1[0]
                                }
                              />
                            </AspectRatio>
                          </CssVarsProvider>
                        </Grid>
                        <Grid
                          marginTop={3}
                          marginBottom={3}
                          sx={{
                            "@media print": { display: "none" },
                          }}
                        >
                          <Swiper
                            className="mySwiper"
                            onSwiper={setThumbsSwiper}
                            loop={true}
                            spaceBetween={5}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                          >
                            {image1?.length === 0 ? (
                              <SwiperSlide>
                                <CssVarsProvider>
                                  <AspectRatio objectFit="contain">
                                    <img
                                      src={`${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`}
                                    />
                                  </AspectRatio>
                                </CssVarsProvider>
                              </SwiperSlide>
                            ) : (
                              image1.map((item1, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <CssVarsProvider>
                                      <AspectRatio objectFit="contain">
                                        <img src={item1} />
                                      </AspectRatio>
                                    </CssVarsProvider>
                                  </SwiperSlide>
                                );
                              })
                            )}
                          </Swiper>
                        </Grid>
                        <Grid
                          marginTop={3}
                          marginBottom={3}
                          sx={{
                            "@media not print": {
                              display: "none",
                            },
                            "@media print": {
                              position: "absolute",
                              width: 420,
                              top: 265,
                              left: 5,
                            },
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <CssVarsProvider>
                              <AspectRatio
                                objectFit="contain"
                                sx={{
                                  "-webkit-print-color-adjust": "exact",
                                  "@media print": {
                                    width: "29%",
                                  },
                                }}
                              >
                                <img
                                  src={
                                    image1?.length === 0
                                      ? `${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`
                                      : image1[0]
                                  }
                                />
                              </AspectRatio>
                            </CssVarsProvider>
                            {image1?.length > 1 ? (
                              <CssVarsProvider>
                                <AspectRatio
                                  objectFit="contain"
                                  sx={{
                                    "-webkit-print-color-adjust": "exact",
                                    "@media print": {
                                      width: "29%",
                                    },
                                  }}
                                >
                                  <img src={image1[1]} />
                                </AspectRatio>
                              </CssVarsProvider>
                            ) : (
                              ""
                            )}
                            {image1?.length > 2 ? (
                              <CssVarsProvider>
                                <AspectRatio
                                  objectFit="contain"
                                  sx={{
                                    "-webkit-print-color-adjust": "exact",
                                    "@media print": {
                                      width: "29%",
                                    },
                                  }}
                                >
                                  <img src={image1[2]} />
                                </AspectRatio>
                              </CssVarsProvider>
                            ) : (
                              ""
                            )}
                          </Stack>
                        </Grid>
                        <Grid
                          sx={{
                            "@media print": {
                              position: "absolute",
                              width: "100%",
                              top: 370,
                              ml: -3,
                            },
                          }}
                        >
                          {item.kinoKeitaiId.map((tag, index) => (
                            <Chip
                              key={index}
                              label={
                                kinoKeitaiMList.find(
                                  (e) => e.kinoKeitaiId === tag
                                ).name
                              }
                              sx={{
                                marginTop: 1,
                                marginRight: 1,
                                "-webkit-print-color-adjust": "exact",
                              }}
                            />
                          ))}
                          {item.specId.map((tag, index) => (
                            <Chip
                              key={index}
                              label={
                                specMList.find((e) => e.specId === tag).name
                              }
                              sx={{
                                marginTop: 1,
                                marginRight: 1,
                                "-webkit-print-color-adjust": "exact",
                              }}
                            />
                          ))}
                          {item.sokoEtc === undefined ||
                          item.sokoEtc === null ||
                          item.sokoEtc === "" ? (
                            ""
                          ) : (
                            <Chip
                              label={item.sokoEtc}
                              sx={{
                                marginTop: 1,
                                marginRight: 1,
                                "-webkit-print-color-adjust": "exact",
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          marginTop={2}
                        >
                          <Grid
                            sx={{
                              width: "100%",
                              height: 200,
                              "@media print": {
                                position: "absolute",
                                width: "40%",
                                top: 65,
                                right: 15,
                                left: 410,
                              },
                            }}
                          >
                            <Map
                              address={
                                item.prefectureId === ""
                                  ? ""
                                  : prefectureMList.find(
                                      (e) =>
                                        e.prefectureId === item.prefectureId
                                    ).name +
                                    item.city +
                                    item.street
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={5}
                        xl={5}
                        marginY={1}
                        sx={{
                          "@media print": {
                            position: "absolute",
                            width: "100%",
                            top: 420,
                            fontSize: 15,
                          },
                        }}
                      >
                        <Grid container direction="column" alignItems="center">
                          <Grid sx={{ width: "100%", marginTop: 4 }}>
                            <FormControl sx={{ width: "100%", marginLeft: 2 }}>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  mt={4}
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>倉庫種別</b>
                                </Typography>
                                <Grid mt={4} sx={{ width: "70%" }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    {item.sokoTypeId.map((tag, index) => (
                                      <Typography
                                        variant="subtitle1"
                                        key={index}
                                        sx={{
                                          "@media print": {
                                            fontSize: 15,
                                          },
                                        }}
                                      >
                                        {
                                          sokoTypeMList.find(
                                            (e) => e.sokoTypeId === tag
                                          ).name
                                        }
                                      </Typography>
                                    ))}
                                  </Stack>
                                </Grid>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>会社</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {
                                    companyMList.find(
                                      (e) => e.companyId === item.companyId
                                    ).name
                                  }
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>管轄主管支店</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.kankatsuShukanShitenId === 0
                                    ? ""
                                    : kankatsuShukanShitenMList.find(
                                        (e) =>
                                          e.kankatsuShukanShitenId ===
                                          item.kankatsuShukanShitenId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>支店</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.shitenId === 0
                                    ? ""
                                    : shitenMList.find(
                                        (e) => e.shitenId === item.shitenId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>営業所</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {
                                    eigyoshoMList.find(
                                      (e) => e.eigyoshoId === item.eigyoshoId
                                    ).name
                                  }
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>担当者</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.tantouName}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>電話番号</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.tel}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>管轄営業部</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {
                                    kankatsuEigyobuMList.find(
                                      (e) =>
                                        e.kankatsuEigyobuId ===
                                        item.kankatsuEigyobu
                                    ).name
                                  }
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>契約期間</b>
                                </Typography>
                                <Grid
                                  sx={{
                                    width: "70%",
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    {item.contractId.map((tag, index) => (
                                      <Typography
                                        variant="subtitle1"
                                        key={index}
                                        sx={{
                                          "@media print": {
                                            fontSize: 15,
                                          },
                                        }}
                                      >
                                        {
                                          contractMList.find(
                                            (e) => e.contractId === tag
                                          ).name
                                        }
                                      </Typography>
                                    ))}
                                  </Stack>
                                </Grid>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>入庫可能日</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {dayjs(item.availableDate).format(
                                    "YYYY 年 MM 月 DD 日 "
                                  )}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>利用可能面積</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.availableArea + "坪"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>坪単価（税別）</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.unitPrice + "円／坪"}
                                  {item.negotiable === 0 ? "" : " 応相談"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>共益費</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.commonFee === 0
                                    ? "無し"
                                    : item.commonFee + "円"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>希望業態</b>
                                </Typography>
                                <Grid
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      width: "60%",
                                    },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        "@media print": {
                                          fontSize: 15,
                                        },
                                      }}
                                    >
                                      {item.taiouGyotaiId.map(
                                        (tag) =>
                                          taiouGyotaiMList.find(
                                            (e) => e.taiouGyotaiId === tag
                                          ).name + " "
                                      )}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>竣工</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.completionYear +
                                    " 年 " +
                                    item.completionMonth +
                                    " 月 "}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>住所</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {
                                    prefectureMList.find(
                                      (e) =>
                                        e.prefectureId === item.prefectureId
                                    ).name
                                  }

                                  {" " + item.city + " " + item.street}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>交通</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.accessByTrain}
                                  <br />
                                  {item.accessByCar}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>備考(倉庫)</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.sokoDescription}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start">
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "30%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  <b>備考(空坪)</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    width: "70%",
                                    "@media print": {
                                      fontSize: 15,
                                    },
                                  }}
                                >
                                  {item.storageDescription}
                                </Typography>
                              </Stack>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={2} xl={2} marginY={1}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          sx={{
                            marginTop: matches ? 5 : 3,
                          }}
                        >
                          <Grid>
                            <Checkbox
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 40 },
                                marginBottom: 2,
                                "@media print": {
                                  display: "none",
                                },
                              }}
                              color="error"
                              checked={favoriteData1.includes(item.storageId)}
                              onClick={(e) => addFavorite(item.storageId, e)}
                            />
                          </Grid>
                          <Typography
                            variant="subtitle1"
                            marginTop={-1}
                            sx={{
                              "@media print": {
                                display: "none",
                              },
                            }}
                          >
                            <b>お気に入りに追加</b>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            marginTop={3}
                            sx={{
                              "@media print": {
                                position: "absolute",
                                top: 0,
                                left: 460,
                              },
                            }}
                          >
                            <b>データ最終更新日</b>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              "@media print": {
                                position: "absolute",
                                top: 25,
                                left: 610,
                              },
                            }}
                          >
                            {dayjs(item.updateAt).format("YYYY/MM/DD")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Copyright sx={{ pt: 2, "@media print": { display: "none" } }} />
            </Container>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default StorageDetail;
