import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Conditions from "./Conditions";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import AspectRatio from "@mui/joy/AspectRatio";
import { CssVarsProvider } from "@mui/joy/styles";
import { useMediaQuery } from "@mui/material";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import dayjs from "dayjs";

const today = new Date();
const ago = new Date(today.setDate(today.getDate() - 14));
const start = new Date().getFullYear();
let end = start + 3;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const areaList = ["全てを選択", "〜100坪", "〜500坪", "〜1000坪", "1001坪以上"];

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const resFavoriteList = (resData) => {
  const favoriteData = [];
  resData.forEach((okini) => {
    const storageId = okini.storageId;
    favoriteData.push(storageId);
  });
  return favoriteData;
};

function UserResultData(props) {
  const { currentUser, userInfo, masterData } = useAuthValue();
  const [favorite, setFavorite] = useState([]);
  const [image, setImage] = useState([]);
  const [favoriteData, setFavoriteData] = useState([]);
  const [favoriteData1, setFavoriteData1] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const matches = useMediaQuery("(min-width:1050px)");
  const history = useHistory();

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const moveDetail = (item) => {
    history.push({
      pathname: "/storagedetail",
      state: {
        item: item,
        searchParams:
          props.search2Params === "" ? props.searchParams : props.search2Params,
        type: 2,
      },
    });
  };

  const getOkini = () => {
    setFavoriteData([]);
    setFavoriteData1([]);
    const userFavorite = {
      userId: userInfo?.userId,
      sortKey: "updateAt",
      ascdesc: "desc",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/getLike`, userFavorite, {
        headers: headers,
      })
      .then((response) => {
        const data = response.data.result;
        const data1 = data.filter((e) => e.status === 1);
        const data2 = resFavoriteList(data1);
        const set = new Set(data2);
        setFavoriteData(data);
        setFavoriteData1([...set]);
      });
  };

  const addFavorite = (item, e) => {
    const addLike = {
      storageId: item,
      userId: userInfo?.userId,
    };
    if (e.target.checked === true) {
      userInfo?.userId !== undefined
        ? axios
            .post(`${process.env.REACT_APP_API_URL}storage/addlike`, addLike, {
              headers: headers,
            })
            .then((response) => {
              getOkini();
            })
        : history.push({
            pathname: "/login",
          });
    } else {
      const delId = {
        okiniId: [favoriteData.find((e) => e.storageId === item).okiniId],
      };
      userInfo?.userId !== undefined
        ? axios
            .post(
              `${process.env.REACT_APP_API_URL}storage/deletelikeM`,
              delId,
              { headers: headers }
            )
            .then((response) => {
              getOkini();
            })
        : history.push({
            pathname: "/login",
          });
    }
  };

  useEffect(() => {
    props.resultArray.map((item) => {
      const sokoImg = getStorage();

      const sokoId = item.sokoId;

      const getImg = async (sokoId) => {
        const image = [];
        const listRef = ref(
          sokoImg,
          `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
        );
        const list = await listAll(listRef);

        const urls = await Promise.all(
          list.items.map((ref) => getDownloadURL(ref))
        );

        const decodeUrls = urls.map(
          (item) =>
            `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
            decodeURIComponent(item).split("thumb/")[1].split("?")[0]
        );

        setImage(...decodeUrls);

        Object.assign(
          props.resultArray.find((e) => sokoId === item.sokoId),
          { image1: [...decodeUrls] }
        );
      };
      getOkini();
      getImg(sokoId);
    });
  }, [props.resultArray]);

  return (
    <>
      <Grid>
        {props.resultArray.map((item) => (
          <Grid key={item.storageId}>
            <Grid sx={{ width: "100%", mt: 3 }}>
              <Paper
                sx={{
                  p: 2,
                }}
              >
                <Stack direction="row" alignItems="center" spacing={5}>
                  <Typography variant="h6" marginLeft={2}>
                    <b>{item.sokoName}</b>
                  </Typography>
                  <Grid>
                    {new Date(item.createAt).getTime() < ago.getTime() ? (
                      ""
                    ) : (
                      <Chip
                        label="新着"
                        variant="outlined"
                        color="error"
                        sx={{ fontWeight: "bold", marginRight: 1 }}
                      />
                    )}
                    {item.soon === 0 ? (
                      ""
                    ) : (
                      <Chip
                        label="引合有り"
                        variant="outlined"
                        color="error"
                        sx={{ fontWeight: "bold", marginRight: 1 }}
                      />
                    )}
                  </Grid>
                </Stack>
                <Grid container sx={{ width: "100%", mt: 3 }}>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{ marginRight: matches ? 5 : "" }}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid width="90%">
                        <CssVarsProvider>
                          <AspectRatio objectFit="contain">
                            <img
                              src={
                                item?.image[0] === undefined
                                  ? `${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`
                                  : item?.image[0]
                              }
                            />
                          </AspectRatio>
                        </CssVarsProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      marginTop: matches ? "" : 3,
                      marginLeft: matches ? "" : 5,
                    }}
                  >
                    <Grid>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "25%" }}
                        >
                          <b>住所</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "75%" }}
                        >
                          {
                            prefectureMList.find(
                              (e) => e.prefectureId === item.prefectureId
                            ).name
                          }
                          {" " + item.city + " " + item.street}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "25%" }}
                        >
                          <b>利用可能坪数</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "75%" }}
                        >
                          {item.availableArea}坪
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "25%" }}
                        >
                          <b>対応貨物</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "75%" }}
                        >
                          {item.taiouGyotaiId.map(
                            (item1) =>
                              taiouGyotaiMList.find(
                                (e) => e.taiouGyotaiId === item1
                              ).name + " "
                          )}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "25%" }}
                        >
                          <b>坪単価（税別）</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "75%" }}
                        >
                          {item.unitPrice}円／坪
                          {item.negotiable === 0 ? "" : " 応相談"}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "25%" }}
                        >
                          <b>入庫可能日</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "75%" }}
                        >
                          {dayjs(item.availableDate).format(
                            "YYYY 年 MM 月 DD 日"
                          )}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "25%" }}
                        >
                          <b>支店、営業所</b>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          marginBottom={1}
                          sx={{ width: "75%" }}
                        >
                          {
                            shitenMList.find(
                              (e) => e.shitenId === item.shitenId
                            )?.name
                          }{" "}
                          {
                            eigyoshoMList.find(
                              (e) => e.eigyoshoId === item.eigyoshoId
                            ).name
                          }
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid>
                      {item.kinoKeitaiId === undefined
                        ? ""
                        : item.kinoKeitaiId.map((tag, index) => (
                            <Chip
                              key={item.storageId + index}
                              label={
                                kinoKeitaiMList.find(
                                  (e) => e.kinoKeitaiId === tag
                                ).name
                              }
                              sx={{ marginTop: 1, marginRight: 1 }}
                            />
                          ))}
                      {item.specId === undefined
                        ? ""
                        : item.specId.map((tag, index) => (
                            <Chip
                              key={item.storageId + index}
                              label={
                                specMList.find((e) => e.specId === tag).name
                              }
                              sx={{ marginTop: 1, marginRight: 1 }}
                            />
                          ))}
                      {item.sokoTypeId === undefined
                        ? ""
                        : item.sokoTypeId.map((tag, index) => (
                            <Chip
                              key={item.storageId + index}
                              label={
                                sokoTypeMList.find((e) => e.sokoTypeId === tag)
                                  .name
                              }
                              sx={{ marginTop: 1, marginRight: 1 }}
                            />
                          ))}
                      {item.sokoEtc === undefined ||
                      item.sokoEtc === null ||
                      item.sokoEtc === "" ? (
                        ""
                      ) : (
                        <Chip
                          label={item.sokoEtc}
                          sx={{ marginTop: 1, marginRight: 1 }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} xl={2}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      sx={{
                        marginTop: matches ? "" : 3,
                      }}
                    >
                      <Grid>
                        <Checkbox
                          icon={<FavoriteBorder />}
                          checkedIcon={<Favorite />}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 40 },
                            marginBottom: 2,
                          }}
                          color="error"
                          onClick={(e) => {
                            addFavorite(item.storageId, e);
                          }}
                          checked={favoriteData1.includes(item.storageId)}
                        />
                      </Grid>

                      <Button
                        variant="contained"
                        onClick={() => {
                          moveDetail(item);
                        }}
                      >
                        詳細を確認
                      </Button>
                      <Typography variant="subtitle1" marginTop={3}>
                        <b>データ最終更新日</b>
                      </Typography>
                      <Typography variant="subtitle1">
                        {dayjs(item.updateAt).format("YYYY/MM/DD")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default UserResultData;
