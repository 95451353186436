import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import PreViewStorage from "./PreViewStorage";
import { object, string, number, date, InferType } from "yup";
import { useHistory, Link } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import dayjs from "dayjs";

import "./App.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const today = dayjs(new Date()).format("YYYY/MM/DD");

const start = new Date().getFullYear() - 3;
let end = new Date().getFullYear() + 3;
const start1 = 1;
const end1 = 31;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const dayList = [...Array(end1 - start1 + 1)].map((_, i) => start1 + i);

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

const resGetUser = (resData) => {
  const userObjectAarray = [];
  const { result } = resData;
  result.forEach((user) => {
    const email = user.email;
    const isNotification = user.isNotification;
    userObjectAarray.push({ email: email, isNotification: isNotification });
  });
  return userObjectAarray;
};

let year = "";
let month = "";
let days = "";

let day1 = "";

let area1 = [];

function AdminAddEmptyArea() {
  const { currentUser, userInfo, masterData } = useAuthValue();
  const [isLoading, setIsLoading] = useState(true);
  const [sokoData, setSokoData] = useState([]);
  const [sokoName, setSokoName] = useState("");
  const [sokoId, setSokoId] = useState("");
  const [sokoType, setSokoType] = useState("");
  const [contractType, setContractType] = useState("");
  const [contract, setContract] = useState([]);
  const [soon, setSoon] = useState("");
  const [availableArea, setAvailableArea] = useState("");
  const [floor, setFloor] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [negotiable, setNegotiable] = useState("");
  const [commonFee, setCommonFee] = useState("");
  const [commonFeeChk, setCommonFeeChk] = useState("");
  const [area, setArea] = useState([]);
  const [availableY, setAvailableY] = useState("");
  const [availableM, setAvailableM] = useState("");
  const [availableD, setAvailableD] = useState("");
  const [status, setStatus] = useState("1");
  const [description, setDescription] = useState("");
  const [userData, setUserData] = useState([]);
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [clickFlag, setClickFlag] = useState(false);
  const history = useHistory();

  const areaMList = masterData.areaMList.sort((a, b) => a.areaId - b.areaId);
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList.sort(
    (a, b) => a.prefectureId - b.prefectureId
  );
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const validateSoko = () => {
    let isValid = true;
    if (sokoId === "") {
      isValid = false;
      setError1("倉庫が選択されていません");
    }
    return isValid;
  };

  const validateContract = () => {
    let isValid = true;
    if (contract.length === 0) {
      isValid = false;
      setError2("契約期間が選択されていません");
    }
    return isValid;
  };

  const validateDate = () => {
    let isValid = true;
    if (availableY === "" || availableM === "" || availableD === "") {
      isValid = false;
      setError3("入庫可能日が入力されていません");
    }
    return isValid;
  };

  const validateDate1 = () => {
    const day = dayjs(new Date(year + "/" + month + "/" + days)).format(
      "YYYY/MM/DD"
    );
    console.log(day);
    day1 = day;
    let isValid = true;
    if (day < today) {
      isValid = false;
      setError5("過去の日付は選択できません");
    }
    return isValid;
  };
  const validateArea = () => {
    let isValid = true;
    if (availableArea === "") {
      isValid = false;
      setError4("利用可能面積が入力されていません");
    }
    return isValid;
  };
  const validateChk = () => {
    if (
      validateSoko() === true &&
      validateContract() === true &&
      validateDate() === true &&
      validateDate1() === true &&
      validateArea() === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resSokoData = (resData) => {
    const sokoDataObjectAarray = [];
    const { result } = resData;

    result.forEach((soko) => {
      const sokoId = soko.sokoId;
      const imageList = [];
      const sokoImg = getStorage();
      const getImg = async (sokoId) => {
        const listRef = ref(
          sokoImg,
          `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
        );
        const list = await listAll(listRef);
        const urls = await Promise.all(
          list.items.map((ref) => getDownloadURL(ref))
        );
        const decodeUrls = urls.map(
          (item) =>
            `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
            decodeURIComponent(item).split("thumb/")[1].split("?")[0]
        );

        imageList.push(decodeUrls);
      };

      getImg(sokoId);

      const sokoName = soko.sokoName;
      const prefecture = soko.prefectureId[0];
      const area = soko.areaId;
      const city = soko.city;
      const street = soko.street;
      const sokoType = soko.sokoTypeId;
      const companyId = soko.companyId;
      const kankatsuShukanShitenId = soko.kankatsuShukanShitenId;
      const shitenId = soko.shitenId;
      const kankatsuEigyobuId = soko.kankatsuEigyobuId;
      const eigyoshoId = soko.eigyoshoId;
      const tantouName = soko.tantouName;
      const tel = soko.tel;
      const accessByCar = soko.accessByCar;
      const accessByTrain = soko.accessByTrain;
      const completionYear = soko.completionYear;
      const completionMonth = soko.completionMonth;
      const kinoKeitaiId = soko.kinoKeitaiId;
      const specId = soko.specId;
      const taiouGyotaiId = soko.taiouGyotaiId;
      const description = soko.description;
      const sokoStatus = soko.status === 1 ? "公開中" : "非公開";
      const updateAt = dayjs(soko.updateAt).format("YYYY/MM/DD hh:mm");
      const createAt = dayjs(soko.reateAt).format("YYYY/MM/DD hh:mm");

      const sokoTypeName = sokoType.map(
        (item) => sokoTypeMList.find((e) => e.sokoTypeId === item).name
      );

      sokoDataObjectAarray.push({
        image: imageList,
        sokoId: sokoId,
        sokoName: sokoName,
        area: area,
        prefecture: prefecture,
        city: city,
        street: street,
        sokoType: sokoTypeName.join(","),
        sokoTypeId: sokoType,
        companyId: companyId,
        kankatsuShukanShitenId: kankatsuShukanShitenId,
        shitenId: shitenId,
        eigyoshoId: eigyoshoId,
        kankatsuEigyobuId: kankatsuEigyobuId,
        tantouName: tantouName,
        tel: tel,
        accessByCar: accessByCar,
        accessByTrain: accessByTrain,
        completionYear: completionYear,
        completionMonth: completionMonth,
        kinoKeitaiId: kinoKeitaiId,
        specId: specId,
        taiouGyotaiId: taiouGyotaiId,
        description: description,
        status: status,
        updateAt: updateAt,
        createAt: createAt,
      });
    });
    return sokoDataObjectAarray;
  };

  const handleAreaData = (event) => {
    const {
      target: { value },
    } = event;
    setArea(typeof value === "string" ? value.split(",") : value);
    area1 = [];
    area1.push(...value);
    if (area1.length !== 0) {
      selectSokoData1(area1);
    } else {
      selectSokoData2();
    }
  };

  const chipDelete = (item) => {
    setArea(area.filter((value) => value !== item));
    area1 = area.filter((value) => value !== item);
    if (area1.length !== 0) {
      selectSokoData1(area1);
    } else {
      selectSokoData2();
    }
  };

  const isSelectedContract = (value) => contract.includes(value);

  const handleContractChange = (value) => {
    isSelectedContract(value)
      ? setContract(contract.filter((checkedValue) => checkedValue !== value))
      : setContract([...contract, value]);
  };

  const handleNegotiable = (value) => {
    if (negotiable === 0 || negotiable === "") {
      setNegotiable(Number(value));
    } else {
      setNegotiable(0);
    }
  };

  const handleCommonFeeChkChange = (value) => {
    if (value === commonFeeChk) {
      setCommonFeeChk("");
    } else {
      setCommonFeeChk("");
      setCommonFeeChk(value);
    }
  };
  const selectSokoData = () => {
    const selectSokoData = {
      status: ["0", "1"],
      sortKey: "updateAt",
      ascdesc: "desc",
      limit: 9999,
      offset: 0,
      companyId: userInfo.type === 2 ? userInfo.companyId : undefined,
      areaId: area.length === 0 ? "" : area.map(String),
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}soko/search`, selectSokoData, {
        headers: headers,
      })
      .then((response) => {
        const sokoDataArray = resSokoData(response.data);
        const sortData = sokoDataArray.sort(
          (a, b) => Date.parse(b.updateAt) - Date.parse(a.updateAt)
        );
        setSokoData(sortData);
      });
  };

  const selectSokoData1 = (item) => {
    const selectSokoData = {
      status: ["0", "1"],
      sortKey: "updateAt",
      ascdesc: "desc",
      limit: 9999,
      offset: 0,
      companyId: userInfo.type === 2 ? userInfo.companyId : undefined,
      areaId: item,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}soko/search`, selectSokoData, {
        headers: headers,
      })
      .then((response) => {
        const sokoDataArray = resSokoData(response.data);
        const sortData = sokoDataArray.sort(
          (a, b) => Date.parse(b.updateAt) - Date.parse(a.updateAt)
        );
        setSokoData(sortData);
      });
  };

  const selectSokoData2 = () => {
    const selectSokoData = {
      status: ["0", "1"],
      sortKey: "updateAt",
      ascdesc: "desc",
      limit: 9999,
      offset: 0,
      companyId: userInfo.type === 2 ? userInfo.companyId : undefined,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}soko/search`, selectSokoData, {
        headers: headers,
      })
      .then((response) => {
        const sokoDataArray = resSokoData(response.data);
        const sortData = sokoDataArray.sort(
          (a, b) => Date.parse(b.updateAt) - Date.parse(a.updateAt)
        );
        setSokoData(sortData);
      });
  };
  const sendMail = (status, sokoName) => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const body = {
      token: `${process.env.REACT_APP_SENDGRID_API_KEY}`,
      personalizations: [
        {
          to: [
            {
              email: `${process.env.REACT_APP_SENDMAIL_EMAIL}`,
            },
          ],
          BCC: userData,
          subject: "SENKO-SO-MATCH 新着通知",
        },
      ],
      from: {
        email: `${process.env.REACT_APP_SENDMAIL_EMAIL}`,
      },
      content: [
        {
          type: "text/plain",
          value: `SENKO-SO-MATCH \n\n空坪の情報が公開されました\n\n${sokoName}\n\n`,
        },
      ],
    };
    if (status === "1" || status === 1) {
      axios
        .post(`${process.env.REACT_APP_API_URL}sendgrid/sendMail`, body, {
          headers: headers,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const moveList = () => {
    history.push({
      pathname: "/admin/emptyarealist",
    });
  };
  const getUsers = () => {
    const getUsers = {
      sortKey: "createAt",
      ascdesc: "desc",
      limit: 9999,
      ofset: 0,
      type: 1,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}user/getUsers`, getUsers, {
        headers: headers,
      })
      .then((response) => {
        const userDataArray = resGetUser(response.data);
        const userList = userDataArray.filter((e) => e.isNotification === 1);
        const userList1 = userList.map((item) => {
          return {
            email: item.email,
          };
        });
        setUserData(userList1);
      });
  };

  useEffect(() => {
    const getSokoData = {
      status: ["0", "1"],
      sortKey: "updateAt",
      ascdesc: "desc",
      limit: 9999,
      offset: 0,
      companyId: userInfo.type === 2 ? userInfo.companyId : undefined,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}soko/search`, getSokoData, {
        headers: headers,
      })
      .then((response) => {
        const sokoDataArray = resSokoData(response.data);
        const sortData = sokoDataArray.sort(
          (a, b) => Date.parse(b.updateAt) - Date.parse(a.updateAt)
        );
        setSokoData(sortData);
      });
    getUsers();
    setIsLoading(false);
  }, []);

  const AddStorage = () => {
    setClickFlag(true);
    if (validateChk()) {
      const addStorage = {
        sokoId: sokoId,
        contractType: 0,
        availableYear: availableY,
        availableMonth: availableM,
        availableDay: availableD,
        availableDate: availableY + "/" + availableM + "/" + availableD,
        soon: 0,
        availableArea: availableArea,
        floorId: 0,
        unitPrice: Number(unitPrice),
        negotiable: negotiable === "" ? 0 : Number(negotiable),
        commonFee: commonFeeChk === "0" ? 0 : Number(commonFee),
        status: Number(status),
        contractId: contract.length === 0 ? "" : contract.map(String),
        description: description,
        registUserId: userInfo.userId,
      };
      const sokoName =
        sokoId === "" ? "" : sokoData.find((e) => e.sokoId === sokoId).sokoName;
      axios
        .post(`${process.env.REACT_APP_API_URL}storage/add2`, addStorage, {
          headers: headers,
        })
        .then(function (res) {
          toast.success(
            () => <Typography variant="h5">登録しました</Typography>,
            {
              duration: 3000,
              position: "top-center",
            }
          );
          sendMail(status, sokoName);
          setTimeout(() => {
            setClickFlag(false);
            moveList();
          }, 4000);
        })
        .catch(function (err) {
          setClickFlag(false);
          toast.error(
            () => <Typography variant="h5">登録できませんでした</Typography>,
            {
              duration: 3000,
              position: "top-center",
            }
          );
        });
    } else {
      setClickFlag(false);
      toast.error(
        () => <Typography variant="h5">未入力項目があります </Typography>,
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }
  };
  return (
    <>
      {isLoading ? (
        <Grid>
          <Typography>loading...</Typography>
        </Grid>
      ) : (
        <ThemeProvider theme={mdTheme}>
          <Toaster position="top-right" />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="absolute">
              <Toolbar
                sx={{
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  SENKO-SO-MATCH(登録サイト)
                </Typography>
                <AdminMenu />
              </Toolbar>
            </AppBar>
            <List component="nav" sx={{ mt: 10, width: 240 }}>
              <AdminListItems />
              <Divider sx={{ my: 1 }} />
            </List>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Container maxWidth="xl" sx={{ mt: 0, mb: { xs: 8, xl: 8 } }}>
                <Grid container spacing={3} className="upperbar" width="auto">
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                      }}
                    >
                      <Grid container alignItems="flex-end" spacing={4}>
                        <Grid item>
                          <Grid container justify="flex-end" spacing={3}>
                            <Grid item>
                              <Button
                                variant="contained"
                                sx={{
                                  background: "gray",
                                  color: "#ffffff",
                                  ":hover": { background: "blue" },
                                }}
                                onClick={() => moveList()}
                              >
                                ＜ 戻る
                              </Button>
                            </Grid>
                            <Grid item>
                              <Grid item>
                                <Typography variant="h5">
                                  空坪(登録画面)
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                  公開状況:
                                  {status === "" || status === "0"
                                    ? "非公開"
                                    : "公開"}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                  更新日時:
                                  {/* {createAt === "" || status === "0"
                              ? "非公開"
                              : "公開"} */}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Hidden mdDown>
                          <Grid
                            item
                            sx={{
                              width: {
                                md: "18.5vw",
                                lg: "36.5vw",
                                xl: "41.5vw",
                              },
                            }}
                          ></Grid>
                        </Hidden>
                        <Grid item>
                          <Grid container justify="flex-end" spacing={3}>
                            <Grid item>
                              <PreViewStorage
                                item={{
                                  okiniId: "",
                                  id: "",
                                  sokoId: sokoId,
                                  availableArea: availableArea,
                                  availableDate: {
                                    availableYear: availableY,
                                    availableDay: availableD,
                                    availableMonth: availableM,
                                    availableDate:
                                      availableY +
                                      "/" +
                                      availableM +
                                      "/" +
                                      availableD +
                                      " " +
                                      "00:00:00",
                                  },
                                  status: status,
                                  updateAt: "",
                                  sokoName:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).sokoName,
                                  imageList: sokoData.imageList,
                                  address: {
                                    prefectureId:
                                      sokoId === ""
                                        ? ""
                                        : sokoData.find(
                                            (e) => e.sokoId === sokoId
                                          ).prefecture,
                                    city:
                                      sokoId === ""
                                        ? ""
                                        : sokoData.find(
                                            (e) => e.sokoId === sokoId
                                          ).city,
                                    street:
                                      sokoId === ""
                                        ? ""
                                        : sokoData.find(
                                            (e) => e.sokoId === sokoId
                                          ).street,
                                  },
                                  shitenId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).shitenId,
                                  eigyoshoId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).eigyoshoId,
                                  kankatsuEigyobuId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).kankatsuEigyobuId,
                                  area: area,
                                  sokoTypeId: sokoId === "" ? "" : sokoType,
                                  companyId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).companyId,
                                  kankatsuShukanShitenId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).kankatsuShukanShitenId,
                                  tantouName:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).tantouName,
                                  tel:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).tel,
                                  accessByCar:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).accessByCar,
                                  accessByTrain:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).accessByTrain,
                                  completionYear:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).completionYear,
                                  completionMonth:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).completionMonth,
                                  kinoKeitaiId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).kinoKeitaiId,
                                  specId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).specId,
                                  taiouGyotaiId:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).taiouGyotaiId,
                                  availableArea: availableArea,
                                  commonFee: commonFee,
                                  contractId: contract,
                                  contractType: contractType,
                                  storageDescription: description,
                                  sokoDescription:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).description,
                                  floorId: "",
                                  negotiable: negotiable,
                                  soon: soon,
                                  unitPrice: unitPrice,
                                  status: status,
                                  sokoStatus:
                                    sokoId === ""
                                      ? ""
                                      : sokoData.find(
                                          (e) => e.sokoId === sokoId
                                        ).status,
                                  createAt: dayjs(new Date()).format(
                                    "YYYY/MM/DD hh:mm:ss"
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="error"
                                disabled
                              >
                                削除
                              </Button>
                            </Grid>
                            <Grid item>
                              {sokoId === "" ||
                              contract.length === 0 ||
                              availableY === "" ||
                              availableM === "" ||
                              availableD === "" ||
                              availableArea === "" ? (
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  onClick={() => {
                                    validateChk();
                                  }}
                                >
                                  保存
                                </Button>
                              ) : clickFlag === true ? (
                                <Button variant="contained" disabled>
                                  保存
                                </Button>
                              ) : day1 >= today ? (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    AddStorage();
                                  }}
                                >
                                  保存
                                </Button>
                              ) : (
                                <Button variant="contained" disabled>
                                  保存
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} xl={12} marginTop={15}>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid marginX="5%">
                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                <Stack direction="row" alignItems="center">
                                  倉庫を選ぶ
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>

                            <FormControl sx={{ width: "65%", mb: 1 }}>
                              <Stack direction="row" alignItems="center">
                                <FormControl sx={{ width: "40%" }}>
                                  <InputLabel id="area">
                                    地域で絞込み
                                  </InputLabel>
                                  <Select
                                    labelId="area"
                                    multiple
                                    value={area}
                                    onChange={handleAreaData}
                                    input={
                                      <OutlinedInput
                                        id="area"
                                        label="地域で絞込み"
                                      />
                                    }
                                    renderValue={(selected) => (
                                      <div className="tags-input-container">
                                        {selected.map((item, index) => (
                                          <Chip
                                            key={item}
                                            label={
                                              areaMList.find(
                                                (e) => e.areaId === item
                                              ).name
                                            }
                                            onDelete={(event) =>
                                              chipDelete(item)
                                            }
                                            onMouseDown={(event) => {
                                              event.stopPropagation();
                                            }}
                                          />
                                        ))}
                                      </div>
                                    )}
                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem key="" value="99" disabled>
                                      地域で絞込み
                                    </MenuItem>
                                    {areaMList.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={item.name}
                                          value={item.areaId}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                {/* <FormControl
                                  sx={{ marginRight: "5%", width: "10%" }}
                                >
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ height: 56 }}
                                    onClick={() => {
                                      selectSokoData();
                                    }}
                                  >
                                    <SearchIcon />
                                  </Button>
                                </FormControl> */}
                                <FormControl
                                  sx={{ marginLeft: "10%", width: "45%" }}
                                >
                                  <InputLabel id="soko">倉庫を選ぶ</InputLabel>
                                  <Select
                                    labelId="soko"
                                    fullWidth
                                    label="倉庫を選ぶ"
                                    value={sokoId}
                                    onChange={(e) => {
                                      setSokoId(e.target.value);
                                      validateSoko();
                                    }}
                                  >
                                    <MenuItem value="">倉庫を選ぶ</MenuItem>
                                    {sokoData.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.sokoId}
                                        >
                                          {item.sokoName}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Stack>
                              {sokoId !== ""
                                ? ""
                                : error1 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error1}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                <Stack direction="row" alignItems="center">
                                  契約期間
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <Grid sx={{ width: "90%" }}>
                                <FormGroup row={true}>
                                  {contractMList
                                    .sort((a, b) => a.contractId - b.contractId)
                                    .map((item, index) => {
                                      const isItemSelected = isSelectedContract(
                                        item.contractId
                                      );
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          sx={{ marginRight: 5 }}
                                          value={item.contractId}
                                          onChange={() => {
                                            handleContractChange(
                                              item.contractId
                                            );
                                            validateContract();
                                          }}
                                          control={
                                            <Checkbox
                                              checked={isItemSelected}
                                            />
                                          }
                                          label={item.name}
                                        />
                                      );
                                    })}
                                </FormGroup>
                                {contract.length !== 0
                                  ? ""
                                  : error2 && (
                                      <Typography
                                        variant="subtitle1"
                                        color="error"
                                      >
                                        {error2}
                                      </Typography>
                                    )}
                              </Grid>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Grid marginTop={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                <Stack direction="row" alignItems="center">
                                  入庫可能日
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>

                            <FormControl sx={{ width: "65%" }}>
                              <Stack
                                direction="row"
                                alignItems="flex-end"
                                spacing={1}
                              >
                                <FormControl sx={{ width: "30%" }}>
                                  <InputLabel id="year">西暦</InputLabel>
                                  <Select
                                    labelId="year"
                                    fullWidth
                                    label="西暦"
                                    value={availableY}
                                    onChange={(e) => {
                                      year = e.target.value;
                                      setAvailableY(e.target.value);
                                      validateDate();
                                      validateDate1();
                                    }}
                                  >
                                    <MenuItem value="">西暦</MenuItem>
                                    {yearRange.map((item) => {
                                      return (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <Grid>
                                  <Typography>年</Typography>
                                </Grid>
                                <FormControl sx={{ width: "30%" }}>
                                  <InputLabel id="month">月</InputLabel>
                                  <Select
                                    labelId="month"
                                    fullWidth
                                    label="月"
                                    value={availableM}
                                    onChange={(e) => {
                                      month = e.target.value;
                                      setAvailableM(e.target.value);
                                      validateDate();
                                      validateDate1();
                                    }}
                                  >
                                    <MenuItem value="">月</MenuItem>
                                    {monthList.map((item) => {
                                      return (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <Grid>
                                  <Typography>月</Typography>
                                </Grid>
                                <FormControl sx={{ width: "30%" }}>
                                  <InputLabel id="days">日</InputLabel>
                                  <Select
                                    labelId="days"
                                    fullWidth
                                    label="日"
                                    value={availableD}
                                    onChange={(e) => {
                                      days = e.target.value;
                                      setAvailableD(e.target.value);
                                      validateDate();
                                      validateDate1();
                                    }}
                                  >
                                    <MenuItem value="">日</MenuItem>
                                    {dayList.map((item) => {
                                      return (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <Grid>
                                  <Typography>日</Typography>
                                </Grid>
                              </Stack>
                              {availableY !== "" &&
                              availableM !== "" &&
                              availableD !== ""
                                ? ""
                                : error3 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error3}
                                    </Typography>
                                  )}
                              {day1 >= today
                                ? ""
                                : error5 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error5}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Stack>
                        </Grid>

                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                <Stack direction="row" alignItems="center">
                                  利用可能面積
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <Stack
                                direction="row"
                                alignItems="flex-end"
                                spacing={1}
                              >
                                <FormControl>
                                  <Grid sx={{ width: 200 }}>
                                    <TextField
                                      margin="normal"
                                      type="number"
                                      fullWidth
                                      required
                                      inputProps={{ min: 0, step: 100 }}
                                      placeholder="半角数字で入力"
                                      value={availableArea}
                                      onChange={(e) => {
                                        setAvailableArea(
                                          e.target.value.replace(/[^0-9]/g, "")
                                        );
                                        validateArea();
                                      }}
                                    />
                                  </Grid>
                                </FormControl>
                                <Grid>
                                  <Typography sx={{ mb: 1 }}>坪</Typography>
                                </Grid>
                              </Stack>
                              {availableArea !== ""
                                ? ""
                                : error4 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error4}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                坪単価（税別）
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <FormControl>
                                  <Grid sx={{ width: 200 }}>
                                    <TextField
                                      margin="normal"
                                      type="number"
                                      fullWidth
                                      required
                                      inputProps={{ min: 0, step: 100 }}
                                      placeholder="坪単価を半角数字で入力"
                                      value={unitPrice}
                                      onChange={(e) =>
                                        setUnitPrice(
                                          e.target.value.replace(/[^0-9]/g, "")
                                        )
                                      }
                                    />
                                  </Grid>
                                </FormControl>
                                <Grid>
                                  <Typography sx={{ marginTop: 1 }}>
                                    円／坪
                                  </Typography>
                                </Grid>
                                <FormControlLabel
                                  value="1"
                                  control={
                                    <Checkbox checked={negotiable === 1} />
                                  }
                                  label="応相談"
                                  onChange={(e) =>
                                    handleNegotiable(Number(e.target.value))
                                  }
                                />
                              </Stack>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Grid marginTop={2} marginBottom={1}>
                          <Stack direction="row" alignItems="flex-start">
                            <FormControl
                              sx={{ marginX: "5%", width: "20%", marginTop: 2 }}
                            >
                              <Typography variant="h6">共益費</Typography>
                            </FormControl>

                            <FormControl sx={{ width: "65%" }}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={
                                    <Checkbox
                                      checked={commonFeeChk.includes("1")}
                                    />
                                  }
                                  label="有り"
                                  onChange={() => handleCommonFeeChkChange("1")}
                                  sx={{ marginTop: 1, marginRight: 5 }}
                                />
                                <FormControl>
                                  <Grid sx={{ width: 200 }}>
                                    <TextField
                                      margin="normal"
                                      type="number"
                                      fullWidth
                                      required
                                      inputProps={{ min: 0, step: 100 }}
                                      placeholder="半角数字で入力"
                                      value={commonFee}
                                      onChange={(e) =>
                                        setCommonFee(
                                          e.target.value.replace(/[^0-9]/g, "")
                                        )
                                      }
                                    />
                                  </Grid>
                                </FormControl>
                                <Grid>
                                  <Typography sx={{ marginTop: 1 }}>
                                    円
                                  </Typography>
                                </Grid>
                              </Stack>
                              <FormControlLabel
                                value="0"
                                control={
                                  <Checkbox
                                    checked={commonFeeChk.includes("0")}
                                  />
                                }
                                label="無し"
                                onChange={() => handleCommonFeeChkChange("0")}
                              />
                            </FormControl>
                          </Stack>
                        </Grid>

                        <Divider />

                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">公開非公開</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <RadioGroup
                                row
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <FormControlLabel
                                  value="1"
                                  sx={{ marginRight: 5 }}
                                  control={<Radio />}
                                  label="公開する"
                                />
                                <FormControlLabel
                                  value="0"
                                  sx={{ marginRight: 5 }}
                                  control={<Radio />}
                                  label="非公開"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="flex-start">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">契約完了</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <FormControl
                                sx={{ marginX: "5%", width: "20%" }}
                              ></FormControl>

                              <FormControl sx={{ width: "65%" }}>
                                <FormGroup row={true}>
                                  <FormControlLabel
                                    value="1"
                                    control={<Checkbox />}
                                    label={
                                      <span style={{ color: "red" }}>
                                        公開削除
                                      </span>
                                    }
                                    disabled
                                  />
                                </FormGroup>
                                <RadioGroup
                                  row
                                  value={contractType}
                                  onChange={(e) =>
                                    setContractType(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="0"
                                    sx={{ marginRight: 5 }}
                                    control={<Radio />}
                                    label="成約"
                                    disabled
                                  />
                                  <FormControlLabel
                                    value="1"
                                    sx={{ marginRight: 5 }}
                                    control={<Radio />}
                                    label="成約以外"
                                    disabled
                                  />
                                </RadioGroup>
                              </FormControl>
                            </FormControl>
                            <Divider />
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="flex-start">
                            <FormControl
                              sx={{ marginX: "5%", width: "20%", marginTop: 2 }}
                            >
                              <Typography variant="h6">備考</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <TextField
                                margin="normal"
                                type="text"
                                fullWidth
                                required
                                multiline
                                rows={3}
                                placeholder="入力してください"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Copyright sx={{ pt: 2 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
}

export default AdminAddEmptyArea;
