import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MuiDrawer from "@mui/material/Drawer";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { storeListItems, userListItems } from "./MenuList";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import AspectRatio from "@mui/joy/AspectRatio";
import { CssVarsProvider } from "@mui/joy/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Checkbox from "@mui/material/Checkbox";
import { Cancel, Tag } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdClose } from "react-icons/md";
import { Autoplay, Thumbs, FreeMode, Navigation } from "swiper";
import Map from "./Map";
import dayjs from "dayjs";
// import css
import "./App.css";
import "swiper/css";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  zIndex: 300,
};

function PreViewStorage(props) {
  const { currentUser, storeInfo, masterData } = useAuthValue();
  const { window } = props;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [image, setImage] = useState(["https://picsum.photos/600/400"]);
  const [isLoading, setIsLoading] = useState(false);
  const [preViewModal, setPreViewModal] = useState(false);
  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const matches = useMediaQuery("(min-width:850px)");

  const item = props.item;

  const handlePreViewModalOpen = () => {
    setPreViewModal(true);
  };

  const handlePreViewModalClose = () => {
    setPreViewModal(false);
  };

  useEffect(() => {
    const sokoImg = getStorage();
    const sokoId = item.sokoId;
    const getImg = async (sokoId) => {
      const image = [];
      const listRef = ref(
        sokoImg,
        `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
      );
      const list = await listAll(listRef);

      const urls = await Promise.all(
        list.items.map((ref) => getDownloadURL(ref))
      );

      const decodeUrls = urls.map(
        (item) =>
          `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
          decodeURIComponent(item).split("thumb/")[1].split("?")[0]
      );

      setImage(...decodeUrls);
      Object.assign(item, { imageList: [...decodeUrls] });
    };
    if (item.sokoId !== "") {
      getImg(item.sokoId);
    }
  }, [item]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          handlePreViewModalOpen(props);
        }}
      >
        <Typography variant="h6" sx={{ width: 150 }}>
          プレビュー
        </Typography>
      </Button>

      <Modal
        hideBackdrop
        open={preViewModal}
        aria-labelledby="title"
        aria-describedby="description"
      >
        <Grid>
          <Box sx={{ display: "flex" }}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Grid className="closebutton1">
                    <IconButton onClick={handlePreViewModalClose}>
                      <MdClose size={"3rem"} color="#FFFFFF" />
                    </IconButton>
                  </Grid>
                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      ...modalStyle,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={5} xl={5} marginY={1}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          marginTop={-2}
                        >
                          <Typography variant="subtitle1" marginRight={1}>
                            TOP
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {">"}
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {item.okiniId !== undefined
                              ? "お気に入り"
                              : "倉庫検索結果"}
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {">"}
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {item.sokoName}
                          </Typography>
                        </Stack>
                        <Typography variant="h5" marginTop={2} marginBottom={2}>
                          {item.sokoName}
                        </Typography>
                        <Grid>
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            parallax={true}
                            autoplay={{
                              delay: 10000,
                              disableOnInteraction: false,
                            }}
                            loop={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[Autoplay, Thumbs]}
                          >
                            {item?.imageList?.length === 0 ? (
                              <SwiperSlide>
                                <CssVarsProvider>
                                  <AspectRatio objectFit="contain">
                                    <img
                                      src={`${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`}
                                    />
                                  </AspectRatio>
                                </CssVarsProvider>
                              </SwiperSlide>
                            ) : (
                              item?.imageList?.map((item, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <CssVarsProvider>
                                      <AspectRatio objectFit="contain">
                                        <img src={item} />
                                      </AspectRatio>
                                    </CssVarsProvider>
                                  </SwiperSlide>
                                );
                              })
                            )}
                          </Swiper>
                        </Grid>
                        <Grid marginTop={3} marginBottom={3}>
                          <Swiper
                            className="mySwiper"
                            //onSwiper={setThumbsSwiper}
                            loop={true}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                          >
                            {item?.imageList?.length === 0 ? (
                              <SwiperSlide>
                                <CssVarsProvider>
                                  <AspectRatio objectFit="contain">
                                    <img
                                      src={`${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`}
                                    />
                                  </AspectRatio>
                                </CssVarsProvider>
                              </SwiperSlide>
                            ) : (
                              item?.imageList?.map((item, index) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <CssVarsProvider>
                                      <AspectRatio objectFit="contain">
                                        <img src={item} />
                                      </AspectRatio>
                                    </CssVarsProvider>
                                  </SwiperSlide>
                                );
                              })
                            )}
                          </Swiper>
                        </Grid>
                        <Grid>
                          {item.kinoKeitaiId === ""
                            ? ""
                            : item.kinoKeitaiId.map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={
                                    kinoKeitaiMList.find(
                                      (e) => e.kinoKeitaiId === tag
                                    ).name
                                  }
                                  sx={{ marginTop: 1, marginRight: 1 }}
                                />
                              ))}
                          {item.specId === ""
                            ? ""
                            : item.specId.map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={
                                    specMList.find((e) => e.specId === tag).name
                                  }
                                  sx={{ marginTop: 1, marginRight: 1 }}
                                />
                              ))}
                          {item.etc === undefined ||
                          item.etc === null ||
                          item.etc === "" ? (
                            ""
                          ) : (
                            <Chip
                              label={item.etc}
                              sx={{ marginTop: 1, marginRight: 1 }}
                            />
                          )}
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          marginTop={2}
                        >
                          <Grid
                            sx={{
                              width: "100%",
                              height: 200,
                            }}
                          >
                            <Map
                              address={
                                item.address.prefectureId === ""
                                  ? ""
                                  : prefectureMList.find(
                                      (e) =>
                                        e.prefectureId ===
                                        item.address.prefectureId
                                    ).name +
                                    item.address.city +
                                    item.address.street
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={12} lg={5} xl={5} marginY={1}>
                        <Grid container direction="column" alignItems="center">
                          <Grid sx={{ width: "100%", marginTop: 4 }}>
                            <FormControl sx={{ width: "100%", marginLeft: 2 }}>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  mt={4}
                                  sx={{ width: "30%" }}
                                >
                                  <b>倉庫種別</b>
                                </Typography>
                                <Grid mt={4} sx={{ width: "70%" }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    {item.sokoTypeId === ""
                                      ? ""
                                      : item.sokoTypeId.map((tag, index) => (
                                          <Typography
                                            variant="subtitle1"
                                            key={index}
                                          >
                                            {
                                              sokoTypeMList.find(
                                                (e) => e.sokoTypeId === tag
                                              ).name
                                            }
                                          </Typography>
                                        ))}
                                  </Stack>
                                </Grid>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>会社</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.companyId === 0 || item.companyId === ""
                                    ? ""
                                    : companyMList.find(
                                        (e) => e.companyId === item.companyId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>管轄主管支店</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.kankatsuShukanShitenId === 0 ||
                                  item.kankatsuShukanShitenId === ""
                                    ? ""
                                    : kankatsuShukanShitenMList.find(
                                        (e) =>
                                          e.kankatsuShukanShitenId ===
                                          item.kankatsuShukanShitenId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>支店</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.shitenId === 0 || item.shitenId === ""
                                    ? ""
                                    : shitenMList.find(
                                        (e) => e.shitenId === item.shitenId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>営業所</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.eigyoshoId === 0 ||
                                  item.eigyoshoId === ""
                                    ? ""
                                    : eigyoshoMList.find(
                                        (e) => e.eigyoshoId === item.eigyoshoId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>担当者</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.tantouName}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>電話番号</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.tel}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>管轄営業部</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.kankatsuEigyobuId === ""
                                    ? ""
                                    : kankatsuEigyobuMList.find(
                                        (e) =>
                                          e.kankatsuEigyobu ===
                                          item.kankatsuEigyobuId
                                      ).name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>契約期間</b>
                                </Typography>
                                <Grid sx={{ width: "70%" }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    {item.contractId === ""
                                      ? ""
                                      : item.contractId.map((tag, index) => (
                                          <Typography
                                            variant="subtitle1"
                                            key={index}
                                          >
                                            {
                                              contractMList.find(
                                                (e) => e.contractId === tag
                                              ).name
                                            }
                                          </Typography>
                                        ))}
                                  </Stack>
                                </Grid>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>入庫可能日</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.availableDate.availableYear === ""
                                    ? ""
                                    : new Date(
                                        item.availableDate.availableDate
                                      ).getFullYear()}
                                  {item.availableDate.availableYear === ""
                                    ? ""
                                    : " 年 "}
                                  {item.availableDate.availableMonth === ""
                                    ? ""
                                    : (
                                        "0" +
                                        (new Date(
                                          item.availableDate.availableDate
                                        ).getMonth() +
                                          1)
                                      ).slice(-2)}
                                  {item.availableDate.availableMonth === ""
                                    ? ""
                                    : " 月 "}
                                  {item.availableDate.availableDay === ""
                                    ? ""
                                    : (
                                        "0" +
                                        new Date(
                                          item.availableDate.availableDate
                                        ).getDate()
                                      ).slice(-2)}
                                  {item.availableDate.availableDay === ""
                                    ? ""
                                    : " 日 "}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>利用可能面積</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.availableArea + "坪"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>坪単価（税別）</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.unitPrice + "円／坪"}
                                  {item.negotiable === 0 ? "" : "応相談"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>共益費</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.commonFee === 0
                                    ? "無し"
                                    : item.commonFee + "円"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>希望業態</b>
                                </Typography>
                                <Grid sx={{ width: "70%" }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Typography variant="subtitle1">
                                      {item.taiouGyotaiId === ""
                                        ? ""
                                        : item.taiouGyotaiId.map(
                                            (tag) =>
                                              taiouGyotaiMList.find(
                                                (e) => e.taiouGyotaiId === tag
                                              ).name + " "
                                          )}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>竣工</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.completionYear === ""
                                    ? ""
                                    : item.completionYear}
                                  {item.completionYear === "" ? "" : " 年 "}
                                  {item.completionMonth === ""
                                    ? ""
                                    : item.completionMonth}
                                  {item.completionMonth === "" ? "" : " 月 "}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>住所</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.address.prefectureId === ""
                                    ? ""
                                    : prefectureMList.find(
                                        (e) =>
                                          e.prefectureId ===
                                          item.address.prefectureId
                                      ).name}

                                  {" " +
                                    item.address.city +
                                    " " +
                                    item.address.street}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>交通</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.accessByTrain}
                                  <br />
                                  {item.accessByCar}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>備考(倉庫)</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.sokoDescription}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start">
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "30%" }}
                                >
                                  <b>備考(空坪)</b>
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ width: "70%" }}
                                >
                                  {item.storageDescription}
                                </Typography>
                              </Stack>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={2} xl={2} marginY={1}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          sx={{
                            marginTop: matches ? 5 : 3,
                          }}
                        >
                          <Grid>
                            <Checkbox
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 40 },
                                marginBottom: 2,
                              }}
                              color="error"
                            />
                          </Grid>
                          <Typography variant="subtitle1" marginTop={-1}>
                            <b>お気に入りに追加</b>
                          </Typography>
                          <Typography variant="subtitle1" marginTop={3}>
                            <b>データ最終更新日</b>
                          </Typography>
                          <Typography variant="subtitle1">
                            {item.updateAt === ""
                              ? ""
                              : dayjs(item.updateAt).format("YYYY / MM / DD")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      marginTop={5}
                    >
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={handlePreViewModalClose}
                      >
                        とじる
                      </Button>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
      </Modal>
    </>
  );
}

export default PreViewStorage;
