import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory, Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import StateConditions from "./StateConditions";
import { useMediaQuery } from "@mui/material";
import "./App.css";
import NewChild from "./NewChild";
import NewChild1 from "./NewChild1";
import UserMenu from "./UserMenu";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import dayjs from "dayjs";

const date = new Date();

const resSokoData = (resData) => {
  const dataObjectAarray = [];
  const { result } = resData;

  const getdata = result.forEach((soko) => {
    const sokoId = soko.sokoId;

    const imageList = [];
    const sokoImg = getStorage();
    const getImg = async (sokoId) => {
      const listRef = ref(
        sokoImg,
        `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
      );
      const list = await listAll(listRef);

      const urls = await Promise.all(
        list.items.map((ref) => getDownloadURL(ref))
      );
      const decodeUrls = urls.map(
        (item) =>
          `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
          decodeURIComponent(item).split("thumb/")[1].split("?")[0]
      );

      imageList.push(...decodeUrls);
    };
    getImg(sokoId);

    const sokoName = soko.sokoName;
    const prefecture = soko.prefectureId;
    const area = soko.areaId;
    const city = soko.city;
    const street = soko.street;
    const sokoType = soko.sokoTypeId;
    const companyId = soko.companyId;
    const kankatsuShukanShitenId = soko.kankatsuShukanShitenId;
    const shitenId = soko.shitenId;
    const kankatsuEigyobuId = soko.kankatsuEigyobuId;
    const eigyoshoId = soko.eigyoshoId;
    const tantouName = soko.tantouName;
    const tel = soko.tel;
    const accessByCar = soko.accessByCar;
    const accessByTrain = soko.accessByTrain;
    const completionYear = soko.completionYear;
    const completionMonth = soko.completionMonth;
    const kinoKeitaiId = soko.kinoKeitaiId;
    const specId = soko.specId;
    const taiouGyotaiId = soko.taiouGyotaiId;
    const description = soko.description;
    const status = soko.status;
    const etc = soko.etc;
    const updateAt = dayjs(soko.updateAt).format("YYYY/MM/DD hh:mm");
    const createAt = dayjs(soko.reateAt).format("YYYY/MM/DD hh:mm");

    dataObjectAarray.push({
      image: imageList,
      topImg: imageList[0],
      sokoId: sokoId,
      sokoName: sokoName,
      prefectureId: prefecture,
      area: area,
      city: city,
      street: street,
      sokoTypeId: sokoType,
      companyId: companyId,
      kankatsuShukanShitenId: kankatsuShukanShitenId,
      shitenId: shitenId,
      kankatsuEigyobuId: kankatsuEigyobuId,
      eigyoshoId: eigyoshoId,
      tantouName: tantouName,
      tel: tel,
      accessByCar: accessByCar,
      accessByTrain: accessByTrain,
      completionYear: completionYear,
      completionMonth: completionMonth,
      kinoKeitaiId: kinoKeitaiId,
      specId: specId,
      taiouGyotaiId: taiouGyotaiId,
      description: description,
      status: status,
      updateAt: updateAt,
      createAt: createAt,
      etc: etc,
    });
  });

  return dataObjectAarray;
};

const resStorageData = (resData) => {
  const dataObjectAarray = [];
  const { result } = resData;
  const getdata = result.forEach((storage) => {
    const storageId = storage.storageId;
    const availableArea = storage.availableArea;
    const availableDate = storage.availableDate;
    const availableDay = storage.availableDay;
    const availableMonth = storage.availableMonth;
    const availableYear = storage.availableYear;
    const commonFee = storage.commonFee;
    const contractId = storage.contractId;
    const storageDescription = storage.description;
    const floorId = storage.floorId;
    const negotiable = storage.negotiable;
    const soon = storage.soon;
    const unitPrice = storage.unitPrice;
    const sokoId = storage.sokoId;
    const sokoName = storage["soko.sokoName"];
    const prefecture = storage["soko.prefectureId"][0];
    const area = storage["soko.areaId"];
    const city = storage["soko.city"];
    const street = storage["soko.street"];
    const sokoType = storage["soko.sokoTypeId"];
    const companyId = storage["soko.companyId"];
    const kankatsuShukanShitenId = storage["soko.kankatsuShukanShitenId"];
    const shitenId = storage["soko.shitenId"];
    const kankatsuEigyobuId = storage["soko.kankatsuEigyobuId"];
    const eigyoshoId = storage["soko.eigyoshoId"];
    const tantouName = storage["soko.tantouName"];
    const tel = storage["soko.tel"];
    const accessByCar = storage["soko.accessByCar"];
    const accessByTrain = storage["soko.accessByTrain"];
    const completionYear = storage["soko.completionYear"];
    const completionMonth = storage["soko.completionMonth"];
    const kinoKeitaiId = storage["soko.kinoKeitaiId"];
    const specId = storage["soko.specId"];
    const taiouGyotaiId = storage["soko.taiouGyotaiId"];
    const sokoDescription = storage["soko.description"];
    const status = storage.status === 1 ? "公開中" : "非公開";
    const sokoStatus = storage["soko.status"];
    const updateAt = dayjs(storage.updateAt).format("YYYY/MM/DD hh:mm");
    const createAt = dayjs(storage.createAt).format("YYYY/MM/DD hh:mm");
    const imageList = [];

    const sokoImg = getStorage();
    const getImg = async (sokoId) => {
      const listRef = ref(
        sokoImg,
        `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
      );
      const list = await listAll(listRef);

      const urls = await Promise.all(
        list.items.map((ref) => getDownloadURL(ref))
      );
      const decodeUrls = urls.map(
        (item) =>
          `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
          decodeURIComponent(item).split("thumb/")[1].split("?")[0]
      );
      imageList.push(...decodeUrls);
    };
    getImg(sokoId);

    dataObjectAarray.push({
      image: imageList,
      topImg: imageList[0],
      storageId: storageId,
      sokoId: sokoId,
      sokoName: sokoName,
      prefectureId: prefecture,
      area: area,
      city: city,
      street: street,
      sokoTypeId: sokoType,
      companyId: companyId,
      kankatsuShukanShitenId: kankatsuShukanShitenId,
      shitenId: shitenId,
      kankatsuEigyobuId: kankatsuEigyobuId,
      eigyoshoId: eigyoshoId,
      tantouName: tantouName,
      tel: tel,
      accessByCar: accessByCar,
      accessByTrain: accessByTrain,
      completionYear: completionYear,
      completionMonth: completionMonth,
      kinoKeitaiId: kinoKeitaiId,
      specId: specId,
      taiouGyotaiId: taiouGyotaiId,
      availableArea: availableArea,
      availableDate: availableDate,
      availableDay: availableDay,
      availableMonth: availableMonth,
      availableYear: availableYear,
      commonFee: commonFee,
      contractId: contractId,
      storageDescription: storageDescription,
      sokoDescription: sokoDescription,
      floorId: floorId,
      negotiable: negotiable,
      soon: soon,
      unitPrice: unitPrice,
      status: status,
      sokoStatus: sokoStatus,
      updateAt: updateAt,
      createAt: createAt,
    });
  });
  return dataObjectAarray;
};

const today = dayjs(date).format("YYYY/MM/DD hh:mm:ss");

const oneYearAfter = dayjs(date).add(1, "y").format("YYYY/MM/DD hh:mm:ss");

const ago = dayjs(date).subtract(2, "w").format("YYYY/MM/DD hh:mm:ss");

const harfYear = dayjs(date).subtract(6, "M").format("YYYY/MM/DD hh:mm:ss");

const start = new Date().getFullYear();
let end = start + 3;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const spaceList = [
  { id: 0, name: "全てを選択", value: 0, mark: "" },
  { id: 100, name: "〜100坪", value: 100, mark: "<=" },
  { id: 500, name: "〜500坪", value: 500, mark: "<=" },
  { id: 999, name: "〜999坪", value: 999, mark: "<=" },
  { id: 1000, name: "1000坪以上", value: 1000, mark: ">=" },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

function UserTopPage(props) {
  const { currentUser, userInfo, masterData, totalCount, newCount } =
    useAuthValue();
  const [areaCountList, setAreaCountList] = useState();
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [searchKeyword1, setSearchKeyword1] = useState("");
  const [company, setCompany] = useState("");
  const [kankatsuShukanShiten, setKankatsuShukanShiten] = useState("");
  const [shiten, setShiten] = useState("");
  const [kankatsuEigyobu, setKankatsuEigyobu] = useState("");
  const [sokoType, setSokoType] = useState([]);
  const [kinoKeitai, setKinoKeitai] = useState([]);
  const [spec, setSpec] = useState([]);
  const [taiouGyotai, setTaiouGyotai] = useState([]);
  const [area, setArea] = useState([]);
  const [prefecture, setPrefecture] = useState([]);
  const [contract, setContract] = useState([]);
  const [space, setSpace] = useState("");
  const [completionYear, setCompletionYear] = useState("");
  const [completionMonth, setCompletionMonth] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [wareHouseCount, SetWareHouseCount] = useState();
  const [newSokoArray, setNewSokoArray] = useState();
  const [shinsetsuSokoList, setShinsetsuSokoList] = useState();
  const [sCount, setSCount] = useState(0);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const newSoko = {
    startAt: ago,
    endAt: today,
    sortKey: "updateAt",
    ascdesc: "desc",
    limit: 10,
    offset: 0,
    status: [1],
    areaId: "",
    shitenId: "",
    prefectureId: "",
    availableArea: "",
    sokoTypeId: "",
    companyId: "",
    contractId: "",
    kankatsuShukanShitenId: "",
    kankatsuEigyobuId: "",
    availableYear: "",
    availableMonth: "",
    kinoKeitaiId: "",
    specId: "",
    taiouGyotaiId: "",
  };

  const shinsetsuSoko = {
    status: ["1"],
    ascdesc: "desc",
    sortKey: "updateAt",
    limit: 100,
    offset: 0,
  };

  const keyword = {
    startAt: "2022/01/01 00:00:00",
    endAt: oneYearAfter,
    sortKey: "updateAt",
    ascdesc: "desc",
    status: [1],
    limit: 50,
    offset: 0,
    keyward: "",
    prefecture: [],
    area: [],
    sokoType: [],
    contract: [],
    kinoKeitai: [],
    spec: [],
    taiouGyotai: [],
  };

  const search = {
    startAt: "2022/01/01 00:00:00",
    endAt: oneYearAfter,
    sortKey: "updateAt",
    ascdesc: "desc",
    limit: 50,
    offset: 0,
    status: [1],
    areaId: area.length === 0 ? "" : area.map(String),
    shitenId: shiten,
    prefectureId: prefecture.length === 0 ? "" : prefecture.map(String),
    availableArea:
      space === 0 || space === ""
        ? ""
        : spaceList.find((e) => e.id === space).value,
    comparisonForAvailableArea:
      space === 0 || space === ""
        ? ""
        : spaceList.find((e) => e.id === space).mark,
    sokoTypeId: sokoType.length === 0 ? "" : sokoType.map(String),
    companyId: company,
    contractId: contract.length === 0 ? "" : contract.map(String),
    kankatsuShukanShitenId: kankatsuShukanShiten,
    kankatsuEigyobuId: kankatsuEigyobu,
    availableYear: completionYear,
    availableMonth: completionMonth,
    kinoKeitaiId: kinoKeitai.length === 0 ? "" : kinoKeitai.map(String),
    specId: spec.length === 0 ? "" : spec.map(String),
    taiouGyotaiId: taiouGyotai.length === 0 ? "" : taiouGyotai.map(String),
  };
  const isSelectedKinoKeitai = (value) => kinoKeitai.includes(value);
  const isSelectedContract = (value) => contract.includes(value);
  const isSelectedSpec = (value) => spec.includes(value);
  const isSelectedSokoType = (value) => sokoType.includes(value);
  const isSelectedTaiouGyotai = (value) => taiouGyotai.includes(value);

  const isSelectedAll =
    taiouGyotaiMList?.length > 0 &&
    taiouGyotai.length === taiouGyotaiMList?.length;

  const isIndeterminate =
    taiouGyotai.length > 0 && taiouGyotai.length < taiouGyotaiMList?.length;

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: search,
      },
    });
  };

  const seachCount = (searchParams) => {
    const params = Object.assign(searchParams, { areaId: "" });
    console.log("prm", params);
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/search7count`, params, {
        headers: headers,
      })
      .then((res) => {
        setSCount(res.data.result[0]["count(*)"]);
      });
  };

  const moveSearch = (searchParams) => {
    history.push({
      pathname: "/search",
      state: {
        searchParams: searchParams,
      },
    });
  };

  const moveKeyword = (keyword, searchParams) => {
    const searchWord = searchKeyword1.includes(" " || "　")
      ? searchKeyword1.trim().split(/\s+/)
      : [searchKeyword1];
    Object.assign(keyword, { keyward: searchWord });
    history.push({
      pathname: "/search",
      state: {
        searchKeyword: keyword,
        searchParams: searchParams,
        prefecture: [],
        area: [],
        sokoType: [],
        contract: [],
        kinoKeitai: [],
        spec: [],
        taiouGyotai: [],
      },
    });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  const clearSearch = () => {
    setArea([]);
    setPrefecture([]);
    setSpace("");
    setSokoType([]);
    setCompany("");
    setShiten("");
    setContract([]);
    setKankatsuShukanShiten("");
    setKankatsuEigyobu("");
    setCompletionYear("");
    setCompletionMonth("");
    setKinoKeitai([]);
    setSpec([]);
    setTaiouGyotai([]);
  };

  const setKankatsuShu = (data) => {
    setCompany(data);
    const kankatsuSSId = kankatsuShukanShitenMList.filter(
      (item) => item.companyId === data
    )[0].kankatsuShukanShitenId;
    if (data > 1) {
      setKankatsuShukanShiten(kankatsuSSId);
      setShiten1(kankatsuSSId);
    }
  };

  const setShiten1 = (data) => {
    const shitenId1 = shitenMList.filter(
      (item) => item.kankatsuShitenId === data
    )[0].shitenId;
    setShiten(shitenId1);
  };

  const kankatsuShuList = kankatsuShukanShitenMList.filter(
    (item) => item.companyId === company
  );

  const shitenList = shitenMList.filter(
    (item) => item.kankatsuShitenId === kankatsuShukanShiten
  );

  const eigyoshoList = eigyoshoMList.filter((item) => item.shitenId === shiten);

  const eigyosho = eigyoshoList.map((item) => item.kankatsuEigyobuId);

  const kankatsuEigyobuList = [];
  const kankatsuEigyobuList1 = eigyosho.map((el) => {
    kankatsuEigyobuMList.find((item) => item.kankatsuEigyobu === el);
    return kankatsuEigyobuMList.find((e) => e.kankatsuEigyobu === el);
  });
  const set = new Set(kankatsuEigyobuList1);
  kankatsuEigyobuList.push(...set);

  const handleKinoKeitaiChange = (value) => {
    isSelectedKinoKeitai(value)
      ? setKinoKeitai(
          kinoKeitai.filter((checkedValue) => checkedValue !== value)
        )
      : setKinoKeitai([...kinoKeitai, value]);
  };

  const handleContractChange = (value) => {
    isSelectedContract(value)
      ? setContract(contract.filter((checkedValue) => checkedValue !== value))
      : setContract([...contract, value]);
  };

  const handleSokoTypeChange = (value) => {
    isSelectedSokoType(value)
      ? setSokoType(sokoType.filter((checkedValue) => checkedValue !== value))
      : setSokoType([...sokoType, value]);
  };

  const handleSpecChange = (value) => {
    isSelectedSpec(value)
      ? setSpec(spec.filter((checkedValue) => checkedValue !== value))
      : setSpec([...spec, value]);
  };

  const handleTaiouGyotaiChange = (value) => {
    isSelectedTaiouGyotai(value)
      ? setTaiouGyotai(
          taiouGyotai.filter((checkedValue) => checkedValue !== value)
        )
      : setTaiouGyotai([...taiouGyotai, value]);
  };

  const handleSelectedAll = () => {
    isSelectedAll
      ? setTaiouGyotai([])
      : setTaiouGyotai(taiouGyotaiMList.map((item) => item.taiouGyotaiId));
  };

  const handleKeyword = (e) => {
    if (e.keyCode !== 13) return;
    const value = e.target.value;
    if (!value.trim()) return;
    if (searchKeyword.indexOf(value) === -1) {
      setSearchKeyword([...searchKeyword, value]);
    }
    e.target.value = "";
  };

  const keywordChipDelete = (index) => {
    setSearchKeyword(searchKeyword.filter((el, i) => i !== index));
  };

  const areaChipDelete = (value) => {
    setArea(area.filter((el, i) => i !== value));
  };
  const preChipDelete = (value) => {
    setPrefecture(prefecture.filter((el, i) => i !== value));
  };
  const getData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}soko/search`, shinsetsuSoko, {
        headers: headers,
      })
      .then((res) => {
        const sokoData = resSokoData(res.data);
        const sokoData1 = sokoData.filter(
          (e) =>
            new Date(e.completionYear + "/" + e.completionMonth).getTime() >=
            harfYear
        );
        setShinsetsuSokoList(sokoData1);
        axios
          .post(`${process.env.REACT_APP_API_URL}storage/search7`, newSoko, {
            headers: headers,
          })
          .then((res) => {
            const dataArray = resStorageData(res.data);
            setNewSokoArray(dataArray);
          })
          .then(() => setIsLoading(false));
      })
      .catch((err) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}storage/search7`, newSoko, {
            headers: headers,
          })
          .then((res) => {
            const dataArray = resStorageData(res.data);
            setNewSokoArray(dataArray);
          })
          .then(() => setIsLoading(false));
      });
  };
  useEffect(() => {
    getData();
    //getdata();
  }, []);

  useEffect(() => {
    seachCount(search);
  }, [
    area,
    prefecture,
    space,
    company,
    kankatsuShukanShiten,
    shiten,
    kankatsuEigyobu,
    completionYear,
    completionMonth,
    contract,
    kinoKeitai,
    sokoType,
    spec,
    taiouGyotai,
  ]);

  return (
    <>
      {isLoading ? (
        <Grid>
          <Typography>loading...</Typography>
        </Grid>
      ) : (
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="absolute">
              <Toolbar
                sx={{
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className="pointer"
                  sx={{ display: "block", marginRight: 5 }}
                  onClick={() => moveTop()}
                >
                  {matches ? "SENKO-SO-MATCH" : "SSM"}
                </Typography>
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    登録
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    ccolor="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    {totalCount}
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block", marginRight: 3 }}
                  >
                    棟
                  </Typography>
                ) : (
                  ""
                )}

                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    新着
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    {newCount}
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block", flexGrow: 1 }}
                  >
                    棟
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Button onClick={() => moveTop()}>
                    <Typography color="#FFFFFF">ホーム</Typography>
                  </Button>
                ) : (
                  ""
                )}
                {currentUser?.email !== undefined && matches ? (
                  <Button onClick={() => moveFavorite()}>
                    <Typography color="#FFFFFF">お気に入り</Typography>
                  </Button>
                ) : (
                  ""
                )}
                {currentUser?.email !== undefined ? (
                  <UserMenu />
                ) : (
                  <Button
                    sx={{ marginLeft: matches ? 0 : 15 }}
                    component={Link}
                    to="/login"
                    variant="outlined"
                    color="inherit"
                  >
                    <Typography color="#FFFFFF">ログイン</Typography>
                  </Button>
                )}
              </Toolbar>
            </AppBar>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={1} marginBottom={3}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          marginTop={2}
                          paddingBottom={5}
                          sx={{ bgcolor: "#BDBDB7", borderRadius: 5 }}
                        >
                          <Grid sx={{ width: matches ? "70%" : "90%" }}>
                            <Grid>
                              <Typography
                                variant={matches ? "h6" : "subtitle1"}
                                color="inherit"
                                noWrap
                                sx={{
                                  display: "block",
                                  marginRight: 3,
                                  marginTop: 2,
                                  marginBottom: 1,
                                }}
                              >
                                キーワードから倉庫を探す
                              </Typography>
                            </Grid>
                            <Grid>
                              <Stack direction="row" alignItems="center">
                                <FormControl
                                  sx={{ width: matches ? "90%" : "80%" }}
                                >
                                  <Grid
                                    sx={{ width: "100%" }}
                                    //className="tags-input-container"
                                  >
                                    {/* {searchKeyword.map((tag, index) => (
                                      <Chip
                                        key={tag}
                                        label={tag}
                                        size={matches ? "medium" : "small"}
                                        onDelete={() =>
                                          keywordChipDelete(index)
                                        }
                                      />
                                    ))}
                                    <input
                                      onKeyDown={handleKeyword}
                                      type="text"
                                      className="tags-input"
                                      placeholder="キーワードを入力→Enterキー"
                                    /> */}
                                    <TextField
                                      margin="normal"
                                      type="text"
                                      fullWidth
                                      value={searchKeyword1}
                                      sx={{ bgcolor: "#fff" }}
                                      placeholder="キーワードを入力"
                                      onChange={(e) =>
                                        setSearchKeyword1(e.target.value)
                                      }
                                    />
                                  </Grid>
                                </FormControl>
                                <FormControl sx={{ width: "10%" }}>
                                  <Grid>
                                    {searchKeyword1 === "" ? (
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        disabled
                                        sx={{ width: "10%", height: 60 }}
                                      >
                                        <SearchIcon />
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ width: "10%", height: 60 }}
                                        onClick={() => {
                                          moveKeyword(keyword, search);
                                        }}
                                      >
                                        <SearchIcon />
                                      </Button>
                                    )}
                                  </Grid>
                                </FormControl>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          sx={{ marginTop: 3 }}
                        >
                          <Grid sx={{ width: matches ? "70%" : "100%" }}>
                            <Grid>
                              <Typography
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{
                                  display: "block",
                                  marginRight: 3,
                                  marginBottom: 1,
                                }}
                              >
                                こだわり条件から倉庫を探す
                              </Typography>
                            </Grid>

                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ margin: 1, width: "20%" }}>
                                <Grid>
                                  <Typography
                                    variant={matches ? "h6" : "subtitle1"}
                                    color="inherit"
                                    noWrap
                                    sx={{
                                      display: "block",
                                      width: 160,
                                    }}
                                  >
                                    地域を選ぶ
                                  </Typography>
                                </Grid>
                              </FormControl>
                              <FormControl sx={{ marginLeft: 3, width: "70%" }}>
                                <Grid>
                                  <StateConditions
                                    area={area}
                                    setArea={setArea}
                                    prefecture={prefecture}
                                    setPrefecture={setPrefecture}
                                    searchParams={search}
                                    seachCount={seachCount}
                                  />
                                </Grid>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid sx={{ width: matches ? "70%" : "100%" }}>
                            {area === undefined
                              ? ""
                              : area.map((tag, index) => (
                                  <Chip
                                    key={index}
                                    label={
                                      areaMList.find((e) => e.areaId === tag)
                                        .name
                                    }
                                    sx={{ marginTop: 1, marginRight: 1 }}
                                    onDelete={() => areaChipDelete(index)}
                                  />
                                ))}
                            {prefecture === undefined
                              ? ""
                              : prefecture.map((tag, index) => (
                                  <Chip
                                    key={index}
                                    label={
                                      prefectureMList.find(
                                        (e) => e.prefectureId === tag
                                      ).name
                                    }
                                    sx={{ marginTop: 1, marginRight: 1 }}
                                    onDelete={() => preChipDelete(index)}
                                  />
                                ))}
                          </Grid>
                          <Grid
                            sx={{
                              width: matches ? "70%" : "100%",
                              marginTop: 3,
                            }}
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: matches ? "70%" : "100%" }}>
                              <Grid sx={{ marginTop: 2, marginBottom: 1 }}>
                                <Grid>
                                  <Stack direction="row" alignItems="center">
                                    <FormControl
                                      sx={{
                                        margin: 1,
                                        width: matches ? "20%" : "30%",
                                      }}
                                    >
                                      <Grid>
                                        <Typography
                                          variant={matches ? "h6" : "subtitle1"}
                                          color="inherit"
                                          noWrap
                                          sx={{
                                            display: "block",
                                            width: 160,
                                          }}
                                        >
                                          広さを選ぶ
                                        </Typography>
                                      </Grid>
                                    </FormControl>
                                    <FormControl
                                      sx={{
                                        marginLeft: matches ? 4 : 0,
                                        width: "70",
                                      }}
                                    >
                                      <InputLabel id="space">
                                        広さを選択
                                      </InputLabel>
                                      <Select
                                        sx={{ width: 200 }}
                                        labelId="space"
                                        fullWidth
                                        label="広さを選択"
                                        value={space}
                                        onChange={(e) =>
                                          setSpace(e.target.value)
                                        }
                                      >
                                        <MenuItem value="">広さを選択</MenuItem>
                                        {spaceList.map((item, index) => {
                                          return (
                                            <MenuItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            sx={{
                              width: matches ? "70%" : "100%",
                              marginTop: 3,
                            }}
                          >
                            <Divider />
                          </Grid>

                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid sx={{ marginTop: 1, width: "100%" }}>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                  sx={{ marginTop: 2 }}
                                >
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl sx={{ margin: 2 }}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ marginBottom: 1 }}
                                      >
                                        会社名
                                      </Typography>
                                      <FormControl>
                                        <InputLabel id="company">
                                          会社名を選択
                                        </InputLabel>
                                        <Select
                                          sx={{ width: 200 }}
                                          labelId="company"
                                          fullWidth
                                          label="会社名を選択"
                                          value={company}
                                          onChange={(e) => {
                                            setKankatsuShu(e.target.value);
                                            setCompany(e.target.value);
                                          }}
                                        >
                                          <MenuItem value="">
                                            会社名を選択
                                          </MenuItem>
                                          {companyMList
                                            .sort((a, b) => a.sort - b.sort)
                                            .map((item, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={item.companyId}
                                                >
                                                  {item.name}
                                                </MenuItem>
                                              );
                                            })}
                                        </Select>
                                      </FormControl>
                                    </FormControl>
                                    <FormControl sx={{ margin: 2 }}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ marginBottom: 1 }}
                                      >
                                        管轄主管支店
                                      </Typography>
                                      <FormControl>
                                        <InputLabel id="kankatsuShukanShiten">
                                          {kankatsuShuList.length === 1
                                            ? "なし"
                                            : "管轄主管支店を選択"}
                                        </InputLabel>

                                        {kankatsuShuList.length === 1 ? (
                                          <Select
                                            sx={{ width: 200 }}
                                            labelId="kankatsuShukanShiten"
                                            fullWidth
                                            label={
                                              kankatsuShuList.length === 1
                                                ? "なし"
                                                : "管轄主管支店を選択"
                                            }
                                            value={
                                              kankatsuShuList.length === 1
                                                ? ""
                                                : kankatsuShukanShiten
                                            }
                                            onChange={(e) =>
                                              setKankatsuShukanShiten(
                                                e.target.value
                                              )
                                            }
                                            disabled
                                          >
                                            {kankatsuShuList.length === 1 ? (
                                              ""
                                            ) : (
                                              <MenuItem value="" disabled>
                                                管轄主管支店を選択
                                              </MenuItem>
                                            )}

                                            {kankatsuShuList.length === 1
                                              ? ""
                                              : kankatsuShuList
                                                  .sort(
                                                    (a, b) => a.sort - b.sort
                                                  )
                                                  .map((item, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={index}
                                                        value={
                                                          item.kankatsuShukanShitenId
                                                        }
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    );
                                                  })}
                                          </Select>
                                        ) : (
                                          <Select
                                            sx={{ width: 200 }}
                                            labelId="kankatsuShukanShiten"
                                            fullWidth
                                            label={
                                              kankatsuShuList.length === 1
                                                ? "なし"
                                                : "管轄主管支店を選択"
                                            }
                                            value={
                                              kankatsuShuList.length === 1
                                                ? ""
                                                : kankatsuShukanShiten
                                            }
                                            onChange={(e) =>
                                              setKankatsuShukanShiten(
                                                e.target.value
                                              )
                                            }
                                          >
                                            {kankatsuShuList.length === 1 ? (
                                              ""
                                            ) : (
                                              <MenuItem value="" disabled>
                                                管轄主管支店を選択
                                              </MenuItem>
                                            )}

                                            {kankatsuShuList.length === 1
                                              ? ""
                                              : kankatsuShuList
                                                  .sort(
                                                    (a, b) => a.sort - b.sort
                                                  )
                                                  .map((item, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={index}
                                                        value={
                                                          item.kankatsuShukanShitenId
                                                        }
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    );
                                                  })}
                                          </Select>
                                        )}
                                      </FormControl>
                                    </FormControl>
                                    <FormControl sx={{ margin: 2 }}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ marginBottom: 1 }}
                                      >
                                        支店名
                                      </Typography>
                                      <FormControl>
                                        <InputLabel id="sokoType">
                                          {shitenList[0]?.shitenId === 28 ||
                                          shitenList[0]?.shitenId === 25
                                            ? "支店名を選択"
                                            : shitenList.length === 1
                                            ? "なし"
                                            : "支店名を選択"}
                                        </InputLabel>
                                        {shitenList[0]?.shitenId === 28 ||
                                        shitenList[0]?.shitenId === 25 ||
                                        (shitenList.length !== 1 &&
                                          shitenList.length !== 0) ? (
                                          <Select
                                            sx={{ width: 200 }}
                                            labelId="sokoType"
                                            fullWidth
                                            label={
                                              shitenList[0]?.shitenId === 28 ||
                                              shitenList[0]?.shitenId === 25
                                                ? "支店名を選択"
                                                : shitenList.length === 1
                                                ? "なし"
                                                : "支店名を選択"
                                            }
                                            value={
                                              shitenList[0]?.shitenId === 28 ||
                                              shitenList[0]?.shitenId === 25
                                                ? shiten
                                                : shitenList.length === 1
                                                ? ""
                                                : shiten
                                            }
                                            onChange={(e) =>
                                              shitenList[0]?.shitenId === 28 ||
                                              shitenList[0]?.shitenId === 25
                                                ? setShiten(e.target.value)
                                                : shitenList.length === 1
                                                ? ""
                                                : setShiten(e.target.value)
                                            }
                                          >
                                            {shitenList[0]?.shitenId === 28 ||
                                            shitenList[0]?.shitenId === 25 ? (
                                              <MenuItem value="">
                                                支店名を選択
                                              </MenuItem>
                                            ) : shitenList.length === 1 ? (
                                              ""
                                            ) : (
                                              <MenuItem value="" disabled>
                                                支店名を選択
                                              </MenuItem>
                                            )}
                                            {shitenList[0]?.shitenId === 28 ||
                                            shitenList[0]?.shitenId === 25
                                              ? shitenList
                                                  .sort(
                                                    (a, b) => a.sort - b.sort
                                                  )
                                                  .map((item, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={index}
                                                        value={item.shitenId}
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    );
                                                  })
                                              : shitenList.length === 1
                                              ? ""
                                              : shitenList
                                                  .sort(
                                                    (a, b) => a.sort - b.sort
                                                  )
                                                  .map((item, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={index}
                                                        value={item.shitenId}
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    );
                                                  })}
                                          </Select>
                                        ) : (
                                          <Select
                                            sx={{ width: 200 }}
                                            labelId="sokoType"
                                            fullWidth
                                            label={
                                              shitenList[0]?.shitenId === 28 ||
                                              shitenList[0]?.shitenId === 25
                                                ? "支店名を選択"
                                                : shitenList.length === 1
                                                ? "なし"
                                                : "支店名を選択"
                                            }
                                            value={
                                              shitenList[0]?.shitenId === 28 ||
                                              shitenList[0]?.shitenId === 25
                                                ? shiten
                                                : shitenList.length === 1
                                                ? ""
                                                : shiten
                                            }
                                            onChange={(e) =>
                                              shitenList[0]?.shitenId === 28 ||
                                              shitenList[0]?.shitenId === 25
                                                ? setShiten(e.target.value)
                                                : shitenList.length === 1
                                                ? ""
                                                : setShiten(e.target.value)
                                            }
                                            disabled
                                          >
                                            {shitenList[0]?.shitenId === 28 ||
                                            shitenList[0]?.shitenId === 25 ? (
                                              <MenuItem value="">
                                                支店名を選択
                                              </MenuItem>
                                            ) : shitenList.length === 1 ? (
                                              ""
                                            ) : (
                                              <MenuItem value="" disabled>
                                                支店名を選択
                                              </MenuItem>
                                            )}
                                            {shitenList[0]?.shitenId === 28 ||
                                            shitenList[0]?.shitenId === 25
                                              ? shitenList
                                                  .sort(
                                                    (a, b) => a.sort - b.sort
                                                  )
                                                  .map((item, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={index}
                                                        value={item.shitenId}
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    );
                                                  })
                                              : shitenList.length === 1
                                              ? ""
                                              : shitenList
                                                  .sort(
                                                    (a, b) => a.sort - b.sort
                                                  )
                                                  .map((item, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={index}
                                                        value={item.shitenId}
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    );
                                                  })}
                                          </Select>
                                        )}
                                      </FormControl>
                                    </FormControl>
                                    <FormControl sx={{ margin: 2 }}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ marginBottom: 1 }}
                                      >
                                        管轄営業部
                                      </Typography>
                                      <FormControl>
                                        <InputLabel id="kankatsuEigyobu">
                                          管轄営業部を選択
                                        </InputLabel>
                                        <Select
                                          sx={{ width: 200 }}
                                          labelId="kankatsuEigyobu"
                                          fullWidth
                                          label="管轄営業部を選択"
                                          value={kankatsuEigyobu}
                                          onChange={(e) =>
                                            setKankatsuEigyobu(e.target.value)
                                          }
                                        >
                                          <MenuItem value="">
                                            管轄営業部を選択
                                          </MenuItem>
                                          {kankatsuEigyobuList
                                            .sort((a, b) => a.sort - b.sort)
                                            .map((item, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={item.kankatsuEigyobu}
                                                >
                                                  {item.name}
                                                </MenuItem>
                                              );
                                            })}
                                        </Select>
                                      </FormControl>
                                    </FormControl>

                                    <FormControl sx={{ margin: 2 }}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ marginBottom: 1 }}
                                      >
                                        入庫可能日
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-end"
                                        spacing={1}
                                      >
                                        <FormControl>
                                          <InputLabel id="year">
                                            西暦
                                          </InputLabel>
                                          <Select
                                            sx={{ width: 85 }}
                                            labelId="year"
                                            fullWidth
                                            label="西暦"
                                            value={completionYear}
                                            onChange={(e) =>
                                              setCompletionYear(e.target.value)
                                            }
                                          >
                                            <MenuItem value="">西暦</MenuItem>
                                            {yearRange.map((item) => {
                                              return (
                                                <MenuItem
                                                  key={item}
                                                  value={item}
                                                >
                                                  {item}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                        <Grid>
                                          <Typography>年</Typography>
                                        </Grid>
                                        <FormControl>
                                          <InputLabel id="month">月</InputLabel>
                                          <Select
                                            sx={{ width: 85 }}
                                            labelId="month"
                                            fullWidth
                                            label="月"
                                            value={completionMonth}
                                            onChange={(e) => {
                                              setCompletionMonth(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <MenuItem value="">月</MenuItem>
                                            {monthList.map((item) => {
                                              return (
                                                <MenuItem
                                                  key={item}
                                                  value={item}
                                                >
                                                  {item}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                        <Grid>
                                          <Typography>月</Typography>
                                        </Grid>
                                      </Stack>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid sx={{ width: "70%", marginTop: 3 }}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{ display: "block", marginRight: 3 }}
                                >
                                  【契約期間】
                                </Typography>
                              </Grid>
                              <Grid sx={{ marginTop: 1 }}>
                                <Grid>
                                  <FormGroup row={true}>
                                    {contractMList
                                      .sort(
                                        (a, b) => a.contractId - b.contractId
                                      )
                                      .map((item, index) => {
                                        const isItemSelected =
                                          isSelectedContract(item.contractId);
                                        return (
                                          <FormControlLabel
                                            sx={{ marginRight: 5 }}
                                            key={index}
                                            value={item.contractId}
                                            onChange={() =>
                                              handleContractChange(
                                                item.contractId
                                              )
                                            }
                                            control={
                                              <Checkbox
                                                checked={isItemSelected}
                                              />
                                            }
                                            label={item.name}
                                          />
                                        );
                                      })}
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid sx={{ width: "70%", marginTop: 3 }}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{ display: "block", marginRight: 3 }}
                                >
                                  【機能・形態】
                                </Typography>
                              </Grid>
                              <Grid sx={{ marginTop: 1 }}>
                                <Grid>
                                  <FormGroup row={true}>
                                    {kinoKeitaiMList
                                      .sort(
                                        (a, b) =>
                                          a.kinoKeitaiId - b.kinoKeitaiId
                                      )
                                      .map((item, index) => {
                                        const isItemSelected =
                                          isSelectedKinoKeitai(
                                            item.kinoKeitaiId
                                          );
                                        return (
                                          <FormControlLabel
                                            sx={{ marginRight: 5 }}
                                            key={index}
                                            value={item.kinoKeitaiId}
                                            onChange={() =>
                                              handleKinoKeitaiChange(
                                                item.kinoKeitaiId
                                              )
                                            }
                                            control={
                                              <Checkbox
                                                checked={isItemSelected}
                                              />
                                            }
                                            label={item.name}
                                          />
                                        );
                                      })}
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid sx={{ width: "70%", marginTop: 3 }}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{ display: "block", marginRight: 3 }}
                                >
                                  【スペック】
                                </Typography>
                              </Grid>
                              <Grid sx={{ marginTop: 1 }}>
                                <Typography
                                  variant="subtitle1"
                                  color="inherit"
                                  noWrap
                                  sx={{
                                    display: "block",
                                    marginLeft: 3,
                                    marginBottom: -1,
                                  }}
                                >
                                  <b>倉庫種別</b>
                                </Typography>
                                <Grid sx={{ marginLeft: 3 }}>
                                  <FormGroup row={true}>
                                    {sokoTypeMList
                                      .sort(
                                        (a, b) => a.sokoTypeId - b.sokoTypeId
                                      )
                                      .map((item, index) => {
                                        const isItemSelected =
                                          isSelectedSokoType(item.sokoTypeId);
                                        return (
                                          <FormControlLabel
                                            key={index}
                                            sx={{ marginRight: 5 }}
                                            value={item.sokoTypeId}
                                            onChange={() =>
                                              handleSokoTypeChange(
                                                item.sokoTypeId
                                              )
                                            }
                                            control={
                                              <Checkbox
                                                checked={isItemSelected}
                                              />
                                            }
                                            label={item.name}
                                          />
                                        );
                                      })}
                                  </FormGroup>
                                </Grid>
                                <Typography
                                  variant="subtitle1"
                                  color="inherit"
                                  noWrap
                                  sx={{
                                    display: "block",
                                    marginTop: 2,
                                    marginLeft: 3,
                                    marginBottom: -1,
                                  }}
                                >
                                  <b>倉庫スペック</b>
                                </Typography>
                                <Grid sx={{ marginLeft: 3 }}>
                                  <FormGroup row={true}>
                                    {specMList
                                      .sort((a, b) => a.specId - b.specId)
                                      .map((item, index) => {
                                        const isItemSelected = isSelectedSpec(
                                          item.specId
                                        );
                                        return (
                                          <FormControlLabel
                                            key={index}
                                            sx={{ marginRight: 5 }}
                                            value={item.specId}
                                            onChange={() =>
                                              handleSpecChange(item.specId)
                                            }
                                            control={
                                              <Checkbox
                                                checked={isItemSelected}
                                              />
                                            }
                                            label={item.name}
                                          />
                                        );
                                      })}
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid sx={{ width: "70%", marginTop: 3 }}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{ display: "block", marginRight: 3 }}
                                >
                                  【希望業態】
                                </Typography>
                              </Grid>
                              <Grid sx={{ marginTop: 1 }}>
                                <Grid>
                                  <FormControlLabel
                                    key="99"
                                    sx={{ marginRight: 5 }}
                                    onChange={handleSelectedAll}
                                    control={
                                      <Checkbox
                                        indeterminate={isIndeterminate}
                                      />
                                    }
                                    checked={isSelectedAll}
                                    label="全てを選択"
                                  />
                                  <FormGroup row={true}>
                                    {taiouGyotaiMList
                                      .sort(
                                        (a, b) =>
                                          a.taiouGyotaiId - b.taiouGyotaiId
                                      )
                                      .map((item, index) => {
                                        const isItemSelected =
                                          isSelectedTaiouGyotai(
                                            item.taiouGyotaiId
                                          );

                                        return (
                                          <FormControlLabel
                                            key={index}
                                            sx={{ marginRight: 5 }}
                                            value={item.taiouGyotaiId}
                                            control={
                                              <Checkbox
                                                checked={isItemSelected}
                                              />
                                            }
                                            label={item.name}
                                            onChange={() => {
                                              handleTaiouGyotaiChange(
                                                item.taiouGyotaiId
                                              );
                                            }}
                                          />
                                        );
                                      })}
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid sx={{ width: "70%", marginTop: 3 }}>
                            <Divider />
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid sx={{ width: "100%" }}>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="flex-end"
                                  sx={{
                                    marginBottom: 2,
                                    marginLeft: matches ? 10 : 7,
                                  }}
                                >
                                  <FormControl
                                    sx={{ width: matches ? "30%" : "55%" }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        clearSearch();
                                        setSCount(0);
                                      }}
                                      size={matches ? "medium" : "small"}
                                      sx={{
                                        background: "gray",
                                        ":hover": { background: "#6C676E" },
                                      }}
                                    >
                                      <Typography
                                        color="inherit"
                                        variant={
                                          matches ? "subtitle1" : "caption"
                                        }
                                      >
                                        選択フィルターを外す
                                      </Typography>
                                    </Button>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid sx={{ width: "70%" }}>
                              <Grid sx={{ marginTop: 1, width: "100%" }}>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                  sx={{ marginTop: 2 }}
                                >
                                  <FormControl
                                    sx={{ width: matches ? "30%" : "55%" }}
                                  >
                                    <Button
                                      size={matches ? "medium" : "small"}
                                      variant="contained"
                                      onClick={() => {
                                        moveSearch(search);
                                      }}
                                    >
                                      <Typography
                                        color="#FFFFFF"
                                        variant={
                                          matches ? "subtitle1" : "caption"
                                        }
                                        sx={{ fontWeight: "bold" }}
                                      >
                                        {`検索する　${sCount}件`}
                                      </Typography>
                                    </Button>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 5 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{
                                    display: "block",
                                    marginRight: 3,
                                    marginBottom: -1,
                                  }}
                                >
                                  <b>〜新着倉庫〜</b>
                                </Typography>
                                {isLoading ? (
                                  ""
                                ) : (
                                  <NewChild
                                    newSokoArray={newSokoArray}
                                    searchParams={search}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: 5 }}
                          >
                            <Grid sx={{ width: "70%" }}>
                              <Grid>
                                <Typography
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{
                                    display: "block",
                                    marginRight: 3,
                                    marginBottom: -1,
                                  }}
                                >
                                  <b>〜新設倉庫〜</b>
                                </Typography>
                                {isLoading ? (
                                  ""
                                ) : (
                                  <NewChild1
                                    shinsetsuSokoList={shinsetsuSokoList}
                                    searchParams={search}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Copyright sx={{ pt: 2 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
}

export default UserTopPage;
