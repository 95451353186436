import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import toast, { Toaster } from "react-hot-toast";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar, jaJP } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useHistory, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
const mdTheme = createTheme();

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //Authorization: `Bearer ${props.accessToken}`,
};

const resGetFreeSpace = (
  resData,
  shitenMList,
  eigyoshoMList,
  kankatsuEigyobuMList,
  prefectureMList
) => {
  const freeSpaceObjectAarray = [];
  const { result } = resData;
  result.forEach(async (storage) => {
    const id = storage.storageId;
    const availableArea = storage.availableArea;
    const availableDate = storage.availableDate;
    const availableDay = storage.availableDay;
    const availableMonth = storage.availableMonth;
    const availableYear = storage.availableYear;
    const commonFee = storage.commonFee;
    const contractId = storage.contractId;
    const contractType = storage.contractType;
    const storageDescription = storage.description;
    const floorId = storage.floorId;
    const negotiable = storage.negotiable;
    const soon = storage.soon;
    const unitPrice = storage.unitPrice;
    const sokoId = storage.sokoId;
    const sokoName = storage["soko.sokoName"];
    const prefecture = storage["soko.prefectureId"][0];
    const area = storage["soko.areaId"];
    const city = storage["soko.city"];
    const street = storage["soko.street"];
    const sokoType = storage["soko.sokoTypeId"];
    const companyId = storage["soko.companyId"];
    const kankatsuShukanShitenId = storage["soko.kankatsuShukanShitenId"];
    const shitenId = storage["soko.shitenId"];
    const kankatsuEigyobuId = storage["soko.kankatsuEigyobuId"];
    const eigyoshoId = storage["soko.eigyoshoId"];
    const tantouName = storage["soko.tantouName"];
    const tel = storage["soko.tel"];
    const accessByCar = storage["soko.accessByCar"];
    const accessByTrain = storage["soko.accessByTrain"];
    const completionYear = storage["soko.completionYear"];
    const completionMonth = storage["soko.completionMonth"];
    const kinoKeitaiId = storage["soko.kinoKeitaiId"];
    const specId = storage["soko.specId"];
    const taiouGyotaiId = storage["soko.taiouGyotaiId"];
    const sokoDescription = storage["soko.description"];
    const status = storage.status;
    const sokoStatus = storage["soko.status"];
    const etc = storage["soko.etc"];
    const updateAt = dayjs(storage.updateAt).format("YYYY/MM/DD hh:mm");
    const createAt = dayjs(storage.reateAt).format("YYYY/MM/DD hh:mm");

    freeSpaceObjectAarray.push({
      id: id,
      sokoId: sokoId,
      availableArea: availableArea,
      availableDate1:
        new Date(availableDate).getFullYear() +
        "年" +
        (new Date(availableDate).getMonth() + 1) +
        "月",
      availableDate: {
        availableYear: availableYear,
        availableDay: availableDay,
        availableMonth: availableMonth,
        availableDate: availableDate,
      },
      status: status,
      updateAt: updateAt,
      sokoName: sokoName,
      address1:
        prefectureMList.find((e) => e.prefectureId === prefecture).name +
        city +
        street,
      address: {
        prefectureId: prefecture,
        city: city,
        street: street,
      },
      shitenId: shitenId,
      shitenName:
        shitenId === 0
          ? ""
          : shitenMList.find((e) => e.shitenId === shitenId).name,
      eigyoshoId: eigyoshoId,
      eigyoshoName: eigyoshoMList.find((e) => e.eigyoshoId === eigyoshoId).name,
      kankatsuEigyobuId: kankatsuEigyobuId,
      kankatsuEigyobuName: kankatsuEigyobuMList.find(
        (e) => e.kankatsuEigyobu === kankatsuEigyobuId
      ).name,
      area: area,
      sokoTypeId: sokoType,
      companyId: companyId,
      kankatsuShukanShitenId: kankatsuShukanShitenId,
      tantouName: tantouName,
      tel: tel,
      accessByCar: accessByCar,
      accessByTrain: accessByTrain,
      completionYear: completionYear,
      completionMonth: completionMonth,
      kinoKeitaiId: kinoKeitaiId,
      specId: specId,
      taiouGyotaiId: taiouGyotaiId,
      availableArea: availableArea,
      commonFee: commonFee,
      contractId: contractId,
      contractType: contractType,
      storageDescription: storageDescription,
      sokoDescription: sokoDescription,
      floorId: floorId,
      negotiable: negotiable,
      soon: soon,
      unitPrice: unitPrice,
      status1:
        status === 1
          ? "公開中"
          : status === 3 && contractType === 1
          ? "非公開(成約)"
          : status === 3 && contractType === 2
          ? "非公開(成約以外)"
          : "非公開",
      status: status,
      etc: etc,
      sokoStatus: sokoStatus,
      createAt: createAt,
    });
  });
  return freeSpaceObjectAarray;
};

function AdminEmptyAreaList() {
  const { currentUser, masterData, userInfo } = useAuthValue();
  const [freeSpaceData, setFreeSpaceData] = useState([]);
  const [delData, setDelData] = useState([]);
  const [flag, setFlag] = useState("0");
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;

  const moveEdit = (row) => {
    history.push({
      pathname: "/admin/editemptyarea",
      state: {
        item: row,
      },
    });
  };

  const moveAdd = () => {
    history.push("/admin/addemptyarea");
  };

  const handleAlertOpen = () => {
    setAlert(true);
  };

  const handleAlertClose = () => {
    setAlert(false);
  };

  const handleHikiaiChange = (e) => {
    e.stopPropagation();

    const updateData = freeSpaceData.find((el) => el.id === e.target.value);

    const upDateItem = {
      storageId: updateData.id,
      sokoId: updateData.sokoId,
      contractType: 0,
      availableYear: updateData.availableDate.availableYear,
      availableMonth: updateData.availableDate.availableMonth,
      availableDay: updateData.availableDate.availableDay,
      availableDate:
        updateData.availableDate.availableYear +
        "/" +
        updateData.availableDate.availableMonth +
        "/" +
        updateData.availableDate.availableDay,
      soon:
        freeSpaceData.find((el) => el.id === e.target.value).soon === 0
          ? 1
          : freeSpaceData.find((el) => el.id === e.target.value).soon === 1
          ? 0
          : 1,
      availableArea: updateData.availableArea,
      floorId: updateData.filterId,
      unitPrice: Number(updateData.unitPrice),
      negotiable:
        updateData.negotiable === "" ? 0 : Number(updateData.negotiable),
      commonFee:
        updateData.commonFeeChk === "0" ? 0 : Number(updateData.commonFee),
      status: updateData.status,
      contractId:
        updateData.contractId.length === 0
          ? ""
          : updateData.contractId.map(String),
      description: updateData.storageDescription,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/update2`, upDateItem, {
        headers: headers,
      })
      .then((res) => {
        handleAlertClose();
        toast.success(
          () => <Typography variant="h5">更新しました</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        setTimeout(() => {
          getData();
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          () => <Typography variant="h5">更新できませんでした</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      });
  };

  const columns = [
    {
      field: "shitenName",
      headerName: "支店名",
      width: 150,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "eigyoshoName",
      headerName: "営業所名",
      width: 250,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "sokoName",
      headerName: "倉庫名",
      width: 200,
      renderCell: (params) => (
        <Typography
          className="sokotype pointer underline"
          onClick={() => moveEdit(params.row)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "availableArea",
      headerName: "坪数",
      width: 100,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "address1",
      headerName: "倉庫所在地",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "kankatsuEigyobuName",
      headerName: "管轄営業部",
      width: 250,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "availableDate1",
      headerName: "入庫可能年月",
      width: 160,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "status1",
      headerName: "ステータス",
      width: 140,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "updateAt",
      headerName: "更新日時",
      width: 150,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "soon",
      headerName: "引合有無",
      width: 140,
      renderCell: (params) => {
        return (
          <FormControlLabel
            value={params.row.id}
            control={<Checkbox checked={params.value === 1} />}
            onChange={(e) => handleHikiaiChange(e)}
            sx={{ marginLeft: 2 }}
          />
        );
      },
    },
  ];

  const getData = () => {
    setIsLoading(true);
    const getAllFreeSpace = {
      sortKey: "updateAt",
      ascdesc: "desc",
      status: ["0", "1", "3"],
      companyId: userInfo.type === 2 ? userInfo.companyId : undefined,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getStorageItemsWithSokoInfo`,
        getAllFreeSpace,
        { headers: headers }
      )
      .then(async (response) => {
        const freeSpaceDataArray = resGetFreeSpace(
          response.data,
          shitenMList,
          eigyoshoMList,
          kankatsuEigyobuMList,
          prefectureMList
        );
        const sortData = freeSpaceDataArray.sort(
          (a, b) => Date.parse(b.updateAt) - Date.parse(a.updateAt)
        );
        console.log(sortData);
        setFreeSpaceData(sortData);
        setIsLoading(false);
      });
  };

  const delItems = () => {
    delData.map((item) => {
      const delData = freeSpaceData.find((e) => e.id === item);
      const delItem = {
        storageId: delData.id,
        sokoId: delData.sokoId,
        contractType: 0,
        availableYear: delData.availableDate.availableYear,
        availableMonth: delData.availableDate.availableMonth,
        availableDay: delData.availableDate.availableDay,
        availableDate:
          delData.availableDate.availableYear +
          "/" +
          delData.availableDate.availableMonth +
          "/" +
          delData.availableDate.availableDay,
        soon: 0,
        availableArea: delData.availableArea,
        floorId: 0,
        unitPrice: Number(delData.unitPrice),
        negotiable: delData.negotiable === "" ? 0 : Number(delData.negotiable),
        commonFee: delData.commonFeeChk === "0" ? 0 : Number(delData.commonFee),
        status: 2,
        contractId:
          delData.contractId.length === 0 ? "" : delData.contractId.map(String),
        description: delData.storageDescription,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}storage/update2`, delItem, {
          headers: headers,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            () => <Typography variant="h5">削除できませんでした</Typography>,
            {
              duration: 3000,
              position: "top-center",
            }
          );
        });
    });
    handleAlertClose();
    toast.success(() => <Typography variant="h5">削除しました</Typography>, {
      duration: 3000,
      position: "top-center",
    });
    setTimeout(() => {
      getData();
    }, 4000);
  };

  useEffect(() => {
    getData();
  }, []);

  const flagChange = (data) => {
    setFlag(data);
  };
  const freeSpaceData1 = freeSpaceData.filter((e) => e.status === 1);
  const freeSpaceData2 = freeSpaceData.filter((e) => e.status === 0);
  const freeSpaceData3 = freeSpaceData.filter((e) => e.status === 3);

  const rows =
    flag === "0"
      ? freeSpaceData
      : flag === "1"
      ? freeSpaceData1
      : flag === "2"
      ? freeSpaceData2
      : freeSpaceData3;

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Toaster position="top-right" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute" color="error">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                SENKO-SO-MATCH(登録サイト)
              </Typography>
              <AdminMenu />
            </Toolbar>
          </AppBar>
          <List component="nav" sx={{ mt: 10, width: 240 }}>
            <AdminListItems />
            <Divider sx={{ my: 1 }} />
          </List>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    空坪一覧
                  </Typography>
                  <Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        sx={{ width: 150 }}
                        onClick={moveAdd}
                      >
                        新規登録
                      </Button>
                    </Grid>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      spacing={3}
                      marginTop={3}
                      marginBottom={2}
                    >
                      <Grid>
                        <Button
                          variant="outlined"
                          value="0"
                          sx={{ width: 130 }}
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          全て（{freeSpaceData.length}）
                        </Button>
                      </Grid>

                      <Grid>
                        <Button
                          variant="outlined"
                          value="1"
                          sx={{ width: 130 }}
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          公開中（{freeSpaceData1.length}）
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant="outlined"
                          value="2"
                          sx={{ width: 130 }}
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          非公開（{freeSpaceData2.length}）
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant="outlined"
                          value="3"
                          sx={{ width: 130 }}
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          公開削除（{freeSpaceData3.length}）
                        </Button>
                      </Grid>
                    </Stack>
                    <Grid>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ width: 150 }}
                        onClick={() => {
                          handleAlertOpen();
                        }}
                      >
                        まとめて削除
                      </Button>
                    </Grid>
                  </Grid>
                  <Dialog
                    open={alert}
                    onClose={(e) => {
                      handleAlertClose(e);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">確認事項</DialogTitle>
                    <DialogContent sx={{ width: 500 }}>
                      <Typography variant="subtitle1" marginTop={2}>
                        {delData.length}件削除しますか？
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        sx={{
                          width: "30%",
                          marginRight: 1,
                          marginBottom: 2,
                        }}
                        variant="contained"
                        color="inherit"
                        onClick={(e) => {
                          handleAlertClose(e);
                        }}
                      >
                        <Typography variant="subtitle1">いいえ</Typography>
                      </Button>
                      <Button
                        sx={{
                          width: "30%",
                          marginRight: 2,
                          marginBottom: 2,
                        }}
                        variant="contained"
                        color="error"
                        onClick={(e) => {
                          delItems();
                        }}
                      >
                        <Typography variant="subtitle1">削除</Typography>
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ width: "auto", height: "80vh" }}>
                      {isLoading ? (
                        <Grid>
                          <p>Loading</p>
                        </Grid>
                      ) : (
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          pageSize={20}
                          rowsPerPageOptions={[20]}
                          checkboxSelection
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                              printOptions: { disableToolbarButton: true },
                              csvOptions: { disableToolbarButton: true },
                            },
                          }}
                          localeText={
                            jaJP.components.MuiDataGrid.defaultProps.localeText
                          }
                          onRowSelectionModelChange={(storageData) => {
                            setDelData(storageData);
                          }}
                        />
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Copyright sx={{ pt: 2 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default AdminEmptyAreaList;
