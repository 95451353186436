import { useState } from "react";
import { Link } from "react-router-dom";
import "./forms.css";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useMediaQuery } from "@mui/material";

const theme = createTheme();

function UserLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const { setTimeActive } = useAuthValue();
  const [flag, setFlag] = useState(false);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");

  const ref = document.referrer;
  console.log("login", props);
  console.log("loginRef", window);
  const login = (e) => {
    e.preventDefault();
    setFlag(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (!auth.currentUser.emailVerified) {
          const ActionCodeSettiings = {
            url: `${process.env.REACT_APP_SITE_URL}/userverified/mail/${email}`,
          };
          sendEmailVerification(auth.currentUser, ActionCodeSettiings)
            .then(() => {
              setError("メールアドレスが認証されていません");
            })
            .catch((err) => {
              console.log(err.message);
              alert(err.message);
            });
        } else {
          const getUser = {
            firebaseUuid: auth.currentUser.uid,
          };
          const headers = {
            "Content-Type": "application/json",
            //Authorization: `Bearer ${props.accessToken}`,
          };
          axios
            .post(`${process.env.REACT_APP_API_URL}user/getUserInfo`, getUser, {
              headers: headers,
            })
            .then((response) => {
              console.log(auth.currentUser);
              console.log("success");
              console.log(
                "update",
                auth.currentUser.email !== response.data?.result[0].email
              );
              if (auth.currentUser.email !== response.data?.result[0].email) {
                const updateUser = {
                  email: auth.currentUser.email,
                  firebaseUuid: auth.currentUser.uid,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}user/updateUserByUuid`,
                    updateUser,
                    { headers: headers }
                  )
                  .then((res) => console.log(res));
              }
              response.data?.result[0].type === 1 ||
              response.data?.result[0].type === 2 ||
              response.data?.result[0].type === 3
                ? history.push("/")
                : setError("ユーザーが存在しません");
            })
            .catch((error) => {
              setError("ユーザーが存在しません");
              console.log("err", error);
            });
        }
      })
      .catch((err) => setError("IDまたはパスワードが異なります"));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          component={Paper}
          elevation={6}
          square
          container
          direction="column"
          alignItems="center"
        >
          <Grid
            sx={{
              width: matches ? "50%" : "90%",
              my: 8,
              mx: matches ? 4 : 0,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant={matches ? "h5" : "h6"}
              color="primary"
              align="center"
            >
              SENKO-SO-MATCH
            </Typography>
            <Typography
              component="h1"
              variant={matches ? "h5" : "h6"}
              color="primary"
              marginTop={3}
              align="center"
            >
              ログイン画面
            </Typography>
            <Grid container direction="column" alignItems="center">
              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: matches ? "50%" : "80%" }}
                name="login_form"
              >
                {error && <div className="auth__error">{error}</div>}
                {flag === true ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="email"
                    fullWidth
                    value={email}
                    required
                    label="メールアドレス"
                    autoComplete="email"
                    placeholder="メールアドレスを入力してください。"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
                {flag === true ? (
                  ""
                ) : (
                  <TextField
                    margin="normal"
                    type="password"
                    fullWidth
                    value={password}
                    required
                    label="パスワード"
                    autoComplete="current-password"
                    placeholder="パスワードを入力してください。"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                )}

                {error !== "" ? (
                  <Button
                    variant="contained"
                    fullWidth
                    color="error"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {
                      setEmail("");
                      setPassword("");
                      setError("");
                      setFlag(false);
                    }}
                  >
                    もどる
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(e) => login(e)}
                  >
                    ログイン
                  </Button>
                )}

                <Typography
                  variant="body1"
                  color="info"
                  align="center"
                  marginTop={2}
                >
                  ＞ パスワードを忘れた方は<Link to="/pwreset">こちら</Link>
                </Typography>
                <Typography
                  variant="body1"
                  color="info"
                  align="center"
                  marginTop={2}
                >
                  ＞ 新規アカウント登録は<Link to="/register">こちら</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default UserLogin;
