import { useState, useRef } from "react";
import "./forms.css";
import { auth } from "./firebase";
import { useHistory, Link } from "react-router-dom";
import {
  getAuth,
  EmailAuthProvider,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MuiAppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import UserMenu from "./UserMenu";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

const date = new Date();

const oneYearAfter = dayjs(date).add(1, "y").format("YYYY/MM/DD hh:mm:ss");

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const theme = createTheme();

function UserChangePW(props) {
  const { currentUser, totalCount, newCount } = useAuthValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [updated, setUpdated] = useState(false);
  const [inputError, setInputError] = useState(false);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  //const {setTimeActive} = useAuthValue()

  const inputRef = useRef(null);

  const handleChange = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };

  const searchParams = {
    startAt: "2022/01/01 00:00:00",
    endAt: oneYearAfter,
    sortKey: "createAt",
    ascdesc: "asc",
    limit: 50,
    offset: 0,
    status: ["1"],
    areaId: "",
    shitenId: "",
    prefectureId: "",
    availableArea: "",
    sokoTypeId: "",
    companyId: "",
    contractId: "",
    kankatsuShukanShitenId: "",
    kankatsuEigyobuId: "",
    availableYear: "",
    availableMonth: "",
    kinoKeitaiId: "",
    specId: "",
    taiouGyotaiId: "",
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: searchParams,
      },
    });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  const validatePw = () => {
    let isValid = true;
    if (newPassword !== "" && confirmNewPassword !== "") {
      if (newPassword !== confirmNewPassword) {
        isValid = false;
        setMessage("パスワードが異なります");
      }
    }
    return isValid;
  };

  console.log(props);

  const changePw = (e) => {
    e.preventDefault();
    if (validatePw()) {
      setError("");
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      console.log(auth.currentUser.email);
      reauthenticateWithCredential(user, credential).then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            setUpdated(true);
            console.log("success");
            setEmail("");
            setNewPassword("");
            setConfirmNewPassword("");
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className="pointer"
              sx={{ display: "block", marginRight: 5 }}
              onClick={() => moveTop()}
            >
              {matches ? "SENKO-SO-MATCH" : "SSM"}
            </Typography>
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                登録
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                ccolor="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                {totalCount}
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block", marginRight: 3 }}
              >
                棟
              </Typography>
            ) : (
              ""
            )}

            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                新着
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                {newCount}
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block", flexGrow: 1 }}
              >
                棟
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Button onClick={() => moveTop()}>
                <Typography color="#FFFFFF">ホーム</Typography>
              </Button>
            ) : (
              ""
            )}
            {currentUser?.email !== undefined && matches ? (
              <Button onClick={() => moveFavorite()}>
                <Typography color="#FFFFFF">お気に入り</Typography>
              </Button>
            ) : (
              ""
            )}
            {currentUser?.email !== undefined ? (
              <UserMenu />
            ) : (
              <Button
                component={Link}
                to="/login"
                variant="outlined"
                color="inherit"
              >
                <Typography color="#FFFFFF">ログイン</Typography>
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container component="main">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                component={Paper}
                elevation={6}
                square
                container
                direction="column"
                alignItems="center"
              >
                <Grid
                  sx={{
                    width: matches ? "50%" : "90%",
                    my: 8,
                    mx: matches ? 4 : 0,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h1"
                    variant={matches ? "h5" : "h6"}
                    color="primary"
                    marginTop={3}
                    align="center"
                  >
                    {updated === true
                      ? "パスワード変更完了"
                      : "パスワードを変更いたします"}
                  </Typography>
                  <Typography
                    component="h1"
                    variant="subtitle1"
                    color="#000000"
                    marginTop={3}
                    align="center"
                    sx={{ width: "100%" }}
                  >
                    {updated === true
                      ? "パスワードの変更が完了いたしました"
                      : ""}
                  </Typography>
                  <Grid container direction="column" alignItems="center">
                    <Box
                      component="form"
                      noValidate
                      sx={{ mt: 1, width: matches ? "50%" : "80%" }}
                      onSubmit={changePw}
                      name="login_form"
                    >
                      {inputError ? (
                        <div className="auth__error">
                          パスワードは8文字以上20 文字以下で入力してください
                        </div>
                      ) : (
                        ""
                      )}
                      {error && <div className="auth__error">{error}</div>}
                      {newPassword === confirmNewPassword
                        ? ""
                        : message && (
                            <div className="auth__error">{message}</div>
                          )}
                      {updated === true ? (
                        ""
                      ) : (
                        <TextField
                          margin="normal"
                          type="password"
                          fullWidth
                          value={password}
                          required
                          label="現在のパスワード"
                          placeholder="現在のパスワード"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      )}
                      {updated === true ? (
                        ""
                      ) : (
                        <TextField
                          margin="normal"
                          type="password"
                          fullWidth
                          value={newPassword}
                          required
                          inputProps={{ maxLength: 20, minLength: 8 }}
                          inputRef={inputRef}
                          label="新しいパスワード"
                          placeholder="新しいパスワード"
                          autoFocus
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            handleChange();
                          }}
                        />
                      )}

                      {updated === true ? (
                        ""
                      ) : (
                        <TextField
                          margin="normal"
                          type="password"
                          fullWidth
                          value={confirmNewPassword}
                          required
                          inputProps={{ maxLength: 20, minLength: 8 }}
                          inputRef={inputRef}
                          label="新しいパスワード(確認)"
                          placeholder="新しいパスワード(確認)"
                          autoFocus
                          onChange={(e) => {
                            setConfirmNewPassword(e.target.value);
                            handleChange();
                            validatePw();
                          }}
                        />
                      )}

                      {updated === true ? (
                        <Button
                          variant="contained"
                          fullWidth
                          color="success"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={() => moveTop()}
                        >
                          トップページ ＞
                        </Button>
                      ) : newPassword === confirmNewPassword &&
                        password !== "" ? (
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          color="success"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          送信
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          color="success"
                          sx={{ mt: 3, mb: 2 }}
                          disabled
                        >
                          送信
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default UserChangePW;
