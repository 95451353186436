import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar, jaJP } from "@mui/x-data-grid";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import AdminEditWareHouse from "./AdminEditWareHouse";
import { useHistory, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import dayjs from "dayjs";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //Authorization: `Bearer ${props.accessToken}`,
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

function AdminWareHouseList() {
  const { currentUser, masterData, userInfo } = useAuthValue();
  const [wareHouseData, setWareHouseData] = useState([]);
  const [delData, setDelData] = useState([]);
  const [flag, setFlag] = useState("0");
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const resGetWareHouse = (
    resData,
    kankatsuEigyobuMList,
    prefectureMList,
    sokoTypeMList
  ) => {
    const wareHouseObjectAarray = [];
    const { result } = resData;

    // console.log("soko", result);
    result.forEach((soko) => {
      const id = soko.sokoId;
      const sokoImg = getStorage();
      const imageList = [];

      const getImg = async (sokoId) => {
        //console.log(sokoId);
        const listRef = ref(
          sokoImg,
          `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
        );
        const list = await listAll(listRef);
        //console.log(list);

        const urls = await Promise.all(
          list.items.map((ref) => getDownloadURL(ref))
        );
        //console.log(urls);
        const decodeUrls = urls.map(
          (item) =>
            `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
            decodeURIComponent(item).split("thumb/")[1].split("?")[0]
        );
        //console.log(decodeUrls);
        imageList.push(decodeUrls);
      };
      //console.log(imageList);
      getImg(id);

      const sokoName = soko.sokoName;
      const userId = soko.userId;
      const prefecture = soko.prefectureId[0];
      const city = soko.city;
      const street = soko.street;
      const sokoType = soko.sokoTypeId;
      const kankatsuEigyobuId = soko.kankatsuEigyobuId;
      const area = soko.areaId;
      const companyId = soko.companyId;
      const kankatsuShukanShitenId = soko.kankatsuShukanShitenId;
      const shitenId = soko.shitenId;
      const eigyoshoId = soko.eigyoshoId;
      const tantouName = soko.tantouName;
      const tel = soko.tel;
      const accessByCar = soko.accessByCar;
      const accessByTrain = soko.accessByTrain;
      const completionYear = soko.completionYear;
      const completionMonth = soko.completionMonth;
      const kinoKeitaiId = soko.kinoKeitaiId;
      const specId = soko.specId;
      const etc = soko.etc;
      const taiouGyotaiId = soko.taiouGyotaiId;
      const sokoDescription = soko.description;
      const updateAt = dayjs(soko.createAt).format("YYYY/MM/DD hh:mm");
      const createAt = dayjs(soko.createAt).format("YYYY/MM/DD hh:mm");
      const status = soko.status;

      const sokoTypeName = sokoType.map(
        (item) => sokoTypeMList.find((e) => e.sokoTypeId === item).name
      );

      wareHouseObjectAarray.push({
        id: id,
        sokoName: sokoName,
        userId: userId,
        address: { prefecture: prefecture, city: city, street: street },
        address1:
          prefectureMList.find((e) => e.prefectureId === prefecture).name +
          city +
          street,
        sokoTypeName: sokoTypeName.join(","),
        sokoType: sokoType,
        kankatsuEigyobuId: kankatsuEigyobuId,
        kankatsuEigyobuName: kankatsuEigyobuMList.find(
          (e) => e.kankatsuEigyobu === kankatsuEigyobuId
        ).name,
        status: status,
        status1: status === 1 ? "公開中" : "非公開",
        updateAt: updateAt,
        createAt: createAt,
        area: area,
        companyId: companyId,
        kankatsuShukanShitenId: kankatsuShukanShitenId,
        shitenId: shitenId,
        eigyoshoId: eigyoshoId,
        tantouName: tantouName,
        tel: tel,
        accessByCar: accessByCar,
        accessByTrain: accessByTrain,
        completionYear: completionYear,
        completionMonth: completionMonth,
        kinoKeitaiId: kinoKeitaiId,
        specId: specId,
        etc: etc,
        taiouGyotaiId: taiouGyotaiId,
        sokoDescription: sokoDescription,
        image: imageList,
      });
    });
    console.log(wareHouseObjectAarray);
    return wareHouseObjectAarray;
  };
  const moveEdit = (row) => {
    history.push({
      pathname: "/admin/editwarehouse",
      state: {
        item: row,
      },
    });
  };

  const moveAdd = (row) => {
    history.push("/admin/addwarehouse");
  };

  const handleAlertOpen = () => {
    setAlert(true);
  };

  const handleAlertClose = () => {
    setAlert(false);
  };

  const delItems = () => {
    delData.map((item) => {
      console.log(item);
      const delData = wareHouseData.find((e) => e.id === item);
      console.log("D", delData);
      const delItem = {
        sokoId: delData.id,
        sokoName: delData.sokoName,
        userId: delData.userId,
        sokoTypeId:
          delData.sokoType.length === 0 ? "" : delData.sokoType.map(String),
        companyId: Number(delData.companyId),
        kankatsuShukanShitenId: Number(delData.kankatsuShukanShitenId),
        shitenId: Number(delData.shitenId),
        eigyoshoId: Number(delData.eigyoshoId),
        tantouName: delData.tantouName,
        tel: delData.tel,
        prefectureId: [delData.address.prefecture],
        city: delData.address.city,
        street: delData.address.street,
        accessByCar: delData.accessByCar,
        accessByTrain: delData.accessByTrain,
        completionYear: Number(delData.completionYear),
        completionMonth: Number(delData.completionMonth),
        kankatsuEigyobuId: Number(delData.kankatsuEigyobuId),

        kinoKeitaiId:
          delData.kinoKeitaiId.length === 0
            ? ""
            : delData.kinoKeitaiId.map(String),
        specId: delData.specId.length === 0 ? "" : delData.specId.map(String),
        taiouGyotaiId:
          delData.taiouGyotaiId.length === 0
            ? ""
            : delData.taiouGyotaiId.map(String),
        status: Number(2),
        description: delData.sokoDescription,
        etc: delData.etc,
        areaId: delData.area.map(String),
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}soko/update3`, delItem, {
          headers: headers,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            () => <Typography variant="h5">削除できませんでした</Typography>,
            {
              duration: 3000,
              position: "top-center",
            }
          );
        });
    });
    handleAlertClose();
    toast.success(() => <Typography variant="h5">削除しました</Typography>, {
      duration: 3000,
      position: "top-center",
    });
    setTimeout(() => {
      getData();
    }, 4000);
  };

  const getData = () => {
    const getAllWareHouse = {
      status: ["0", "1"],
      sortKey: "updateAt",
      ascdesc: "desc",
      limit: 9999,
      offset: 0,
      companyId: userInfo.type === 2 ? userInfo.companyId : undefined,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}soko/search`, getAllWareHouse, {
        headers: headers,
      })
      .then((response) => {
        const wareHouseDataArray = resGetWareHouse(
          response.data,
          kankatsuEigyobuMList,
          prefectureMList,
          sokoTypeMList
        );
        console.log("WareHouse", wareHouseDataArray);
        const sortData = wareHouseDataArray.sort(
          (a, b) => Date.parse(b.updateAt) - Date.parse(a.updateAt)
        );
        // console.log("sortData", sortData);
        setWareHouseData(sortData);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "sokoName",
      headerName: "倉庫名",
      width: 250,

      renderCell: (params) => (
        <Typography
          className="sokotype pointer underline"
          onClick={() => moveEdit(params.row)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "address1",
      headerName: "倉庫所在地",
      width: 250,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "kankatsuEigyobuName",
      headerName: "管轄営業部",
      width: 300,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "sokoTypeName",
      headerName: "倉庫種別",
      width: 250,
      renderCell: (params) => (
        <Typography className="sokotype">{params.value}</Typography>
      ),
    },

    {
      field: "status1",
      headerName: "ステータス",
      width: 140,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "updateAt",
      headerName: "更新日時",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
  ];

  const flagChange = (data) => {
    setFlag(data);
  };
  const wareHouseData1 = wareHouseData.filter((e) => e.status === 1);
  const wareHouseData2 = wareHouseData.filter((e) => e.status === 0);

  const rows =
    flag === "0"
      ? wareHouseData
      : flag === "1"
      ? wareHouseData1
      : wareHouseData2;

  return (
    <>
      {isLoading ? (
        <Grid>
          <p>Loading</p>
        </Grid>
      ) : (
        <ThemeProvider theme={mdTheme}>
          <Toaster position="top-right" />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="absolute" color="error">
              <Toolbar
                sx={{
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  SENKO-SO-MATCH(登録サイト)
                </Typography>
                <AdminMenu />
              </Toolbar>
            </AppBar>
            <List component="nav" sx={{ mt: 10, width: 240 }}>
              <AdminListItems />
              <Divider sx={{ my: 1 }} />
            </List>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                      倉庫一覧
                    </Typography>
                    <Grid>
                      <Button
                        variant="contained"
                        sx={{ width: 150 }}
                        onClick={moveAdd}
                      >
                        新規登録
                      </Button>
                    </Grid>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      spacing={3}
                      marginTop={3}
                      marginBottom={2}
                    >
                      <Grid>
                        <Button
                          variant="outlined"
                          sx={{ width: 130 }}
                          value="0"
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          全て（{wareHouseData.length}）
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant="outlined"
                          sx={{ width: 130 }}
                          value="1"
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          公開中（{wareHouseData1.length}）
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant="outlined"
                          sx={{ width: 130 }}
                          value="2"
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          非公開（{wareHouseData2.length}）
                        </Button>
                      </Grid>
                    </Stack>
                    <Grid>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ width: 150 }}
                        onClick={() => {
                          handleAlertOpen();
                        }}
                      >
                        まとめて削除
                      </Button>
                    </Grid>
                    <Dialog
                      open={alert}
                      onClose={(e) => {
                        handleAlertClose(e);
                      }}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        確認事項
                      </DialogTitle>
                      <DialogContent sx={{ width: 500 }}>
                        <Typography variant="subtitle1" marginTop={2}>
                          {delData.length}件削除しますか？
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          sx={{
                            width: "30%",
                            marginRight: 1,
                            marginBottom: 2,
                          }}
                          variant="contained"
                          color="inherit"
                          onClick={(e) => {
                            handleAlertClose(e);
                          }}
                        >
                          <Typography variant="subtitle1">いいえ</Typography>
                        </Button>
                        <Button
                          sx={{
                            width: "30%",
                            marginRight: 2,
                            marginBottom: 2,
                          }}
                          variant="contained"
                          color="error"
                          onClick={(e) => {
                            delItems();
                          }}
                        >
                          <Typography variant="subtitle1">削除</Typography>
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        sx={{ width: "auto", height: "80vh" }}
                      >
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          pageSize={20}
                          rowsPerPageOptions={[20]}
                          checkboxSelection
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                              printOptions: { disableToolbarButton: true },
                              csvOptions: { disableToolbarButton: true },
                            },
                          }}
                          localeText={
                            jaJP.components.MuiDataGrid.defaultProps.localeText
                          }
                          onRowSelectionModelChange={(sokoData) => {
                            console.log(sokoData);
                            setDelData(sokoData);
                          }}
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Copyright sx={{ pt: 2 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
}

export default AdminWareHouseList;
