import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import PreViewSoko from "./PreViewSoko";
import MediaManager from "./MadiaManager";
import { useHistory, Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Hidden from "@mui/material/Hidden";

const style = {
  position: "fixed",
  top: "5%",
  bottom: "5%",
  left: "10%",
  right: "5%",
  width: "90%",
  height: "90%",
  pt: 2,
  px: 4,
  pb: 3,
};

const start = 1960;
let end = new Date().getFullYear() + 1;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

function AdminEditWareHouse(props) {
  const item = props.location.state.item;

  const { currentUser, userInfo, masterData } = useAuthValue();

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList.sort(
    (a, b) => a.prefectureId - b.prefectureId
  );
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const [isLoading, setIsLoading] = useState(true);
  const [sokoName, setSokoName] = useState(item.sokoName);
  const [sokoId, setSokoId] = useState(item.id);
  const [sokoType, setSokoType] = useState(item.sokoType);
  const [company, setCompany] = useState(item.companyId);
  const [kankatsuShukanShiten, setKankatsuShukanShiten] = useState(
    item.kankatsuShukanShitenId
  );
  const [kankatsuEigyobu, setKankatsuEigyobu] = useState(
    item.kankatsuEigyobuId
  );
  const [shiten, setShiten] = useState(item.shitenId);
  const [eigyosho, setEigyosho] = useState(item.eigyoshoId);
  const [tantouName, setTantouName] = useState(item.tantouName);
  const [tel, setTel] = useState(item.tel);
  const [kinoKeitai, setKinoKeitai] = useState(item.kinoKeitaiId);
  const [spec, setSpec] = useState(item.specId);
  const [taiouGyotai, setTaiouGyotai] = useState(item.taiouGyotaiId);
  const [prefecture, setPrefecture] = useState(item.address.prefecture);
  const [city, setCity] = useState(item.address.city);
  const [street, setStreet] = useState(item.address.street);
  const [accessByCar, setAccessByCar] = useState(item.accessByCar);
  const [accessByTrain, setAccessByTrain] = useState(item.accessByTrain);
  const [completionY, setCompletionY] = useState(item.completionYear);
  const [completionM, setCompletionM] = useState(item.completionMonth);
  const [status, setStatus] = useState(item.status);
  const [description, setDescription] = useState(item.sokoDescription);
  const [etc, setEtc] = useState(item.etc === null ? "" : item.etc);
  const [imageList, setImageList] = useState([]);
  const [eigyobuId, setEigyobuId] = useState(
    eigyoshoMList.filter((el) => el.eigyoshoId === item.eigyoshoId)[0]
      .kankatsuEigyobuId
  );
  const [alert, setAlert] = useState(false);
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [error6, setError6] = useState("");
  const [error7, setError7] = useState("");
  const [error8, setError8] = useState("");
  const [error9, setError9] = useState("");
  const [error10, setError10] = useState("");
  const [clickFlag, setClickFlag] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const clear = () => {
    setSokoName("");
    setSokoType([]);
    setCompany("");
    setKankatsuShukanShiten("");
    setShiten("");
    setEigyosho("");
    setTantouName("");
    setTel("");
    setPrefecture([]);
    setCity("");
    setStreet("");
    setAccessByCar("");
    setAccessByTrain("");
    setCompletionY("");
    setCompletionM("");
    setKinoKeitai([]);
    setSpec([]);
    setTaiouGyotai([]);
    setStatus("0");
    setDescription("");
  };
  const delSoko = () => {
    setAlert(false);
    const prefecture1 = [String(prefecture)];
    const updateSoko = {
      sokoId: sokoId,
      sokoName: sokoName,
      userId: userInfo.userId,
      sokoTypeId: sokoType.length === 0 ? "" : sokoType.map(String),
      companyId: Number(company),
      kankatsuShukanShitenId:
        kankatsuShukanShiten === "" ? 0 : Number(kankatsuShukanShiten),
      shitenId: shiten === "" ? 0 : Number(shiten),
      eigyoshoId: Number(eigyosho),
      tantouName: tantouName,
      tel: tel,
      prefectureId: prefecture.length === 0 ? "" : prefecture1,
      city: city,
      street: street,
      accessByCar: accessByCar,
      accessByTrain: accessByTrain,
      completionYear: Number(completionY),
      completionMonth: Number(completionM),
      kankatsuEigyobuId: kankatsuEigyobu === "" ? "" : Number(kankatsuEigyobu),
      kinoKeitaiId: kinoKeitai.length === 0 ? "" : kinoKeitai.map(String),
      specId: spec.length === 0 ? "" : spec.map(String),
      taiouGyotaiId: taiouGyotai.length === 0 ? "" : taiouGyotai.map(String),
      status: 2,
      description: description,
      etc: etc,
      areaId: [
        prefecture.length === 0
          ? ""
          : String(
              prefectureMList.find((e) => e.prefectureId === prefecture).areaId
            ),
      ],
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}soko/update3`, updateSoko, {
        headers: headers,
      })
      .then(function (res) {
        toast.success(
          () => <Typography variant="h5">削除しました</Typography>,
          { duration: 3000 }
        );
        setTimeout(() => {
          moveList();
        }, 4000);
      })
      .catch(function (err) {
        toast.error(
          () => <Typography variant="h5">削除できませんでした</Typography>,
          { duration: 3000 }
        );
      });
  };

  const moveList = () => {
    history.push({
      pathname: "/admin/warehouselist",
    });
  };

  const handleAlertOpen = () => {
    setAlert(true);
  };

  const handleAlertClose = (e) => {
    e.stopPropagation();
    setAlert(false);
  };

  const updateSoko = () => {
    if (validateChk()) {
      setClickFlag(true);
      const prefecture1 = [String(prefecture)];

      const updateSoko = {
        sokoId: sokoId,
        sokoName: sokoName,
        userId: userInfo.userId,
        sokoTypeId: sokoType.length === 0 ? "" : sokoType.map(String),
        companyId: Number(company),
        kankatsuShukanShitenId:
          kankatsuShukanShiten === "" ? 0 : Number(kankatsuShukanShiten),
        shitenId: shiten === "" ? 0 : Number(shiten),
        eigyoshoId: Number(eigyosho),
        tantouName: tantouName,
        tel: tel,
        prefectureId: prefecture.length === 0 ? "" : prefecture1,
        city: city,
        street: street,
        accessByCar: accessByCar,
        accessByTrain: accessByTrain,
        completionYear: Number(completionY),
        completionMonth: Number(completionM),
        kankatsuEigyobuId:
          kankatsuEigyobu === "" ? "" : Number(kankatsuEigyobu),
        kinoKeitaiId: kinoKeitai.length === 0 ? "" : kinoKeitai.map(String),
        specId: spec.length === 0 ? "" : spec.map(String),
        taiouGyotaiId: taiouGyotai.length === 0 ? "" : taiouGyotai.map(String),
        status: Number(status),
        description: description,
        etc: etc,
        areaId: [
          prefecture.length === 0
            ? ""
            : String(
                prefectureMList.find((e) => e.prefectureId === prefecture)
                  .areaId
              ),
        ],
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}soko/update3`, updateSoko, {
          headers: headers,
        })
        .then(function (res) {
          toast.success(
            () => <Typography variant="h5">更新しました</Typography>,
            {
              duration: 3000,
              position: "top-center",
            }
          );
          setTimeout(() => {
            setClickFlag(false);
            moveList();
          }, 4000);
        })
        .catch(function (err) {
          toast.error(
            () => <Typography variant="h5">更新できませんでした</Typography>,
            {
              duration: 3000,
              position: "top-center",
            }
          );
        });
    } else {
      toast.error(
        () => <Typography variant="h5">未入力項目があります</Typography>,
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }
  };

  const companyList = companyMList.find((e) => e.companyId === company);

  const setKankatsuShu = (data) => {
    setCompany(data);
    const kankatsuSSId = kankatsuShukanShitenMList.filter(
      (item) => item.companyId === data
    )[0].kankatsuShukanShitenId;
    if (data > 1) {
      setKankatsuShukanShiten(kankatsuSSId);
      setShiten1(kankatsuSSId);
    }
  };

  const setShiten1 = (data) => {
    const shitenId1 = shitenMList.filter(
      (item) => item.kankatsuShitenId === data
    )[0].shitenId;
    setShiten(shitenId1);
  };

  const kankatsuShuList = kankatsuShukanShitenMList.filter(
    (item) => item.companyId === company
  );
  const shitenList = shitenMList.filter(
    (item) => item.kankatsuShitenId === kankatsuShukanShiten
  );

  const eigyoshoList = eigyoshoMList.filter((item) => item.shitenId === shiten);

  const eigyoshoSet = (data) => {
    const eigyobu = eigyoshoMList.filter((item) => item.eigyoshoId === data)[0]
      .kankatsuEigyobuId;

    setEigyobuId(eigyobu);
    setKankatsuEigyobu(eigyobu);
    setEigyosho(data);
    validateEigyosho();
  };

  const kankatsuEigyobuList = kankatsuEigyobuMList.find(
    (item) => item.kankatsuEigyobu === eigyobuId
  );

  const isSelectedKinoKeitai = (value) => kinoKeitai.includes(value);
  const isSelectedSpec = (value) => spec.includes(value);
  const isSelectedSokoType = (value) => sokoType.includes(value);
  const isSelectedTaiouGyotai = (value) => taiouGyotai.includes(value);

  const isSelectedAll =
    taiouGyotaiMList?.length > 0 &&
    taiouGyotai.length === taiouGyotaiMList?.length;

  const isIndeterminate =
    taiouGyotai.length > 0 && taiouGyotai.length < taiouGyotaiMList?.length;

  const handleKinoKeitaiChange = (value) => {
    isSelectedKinoKeitai(value)
      ? setKinoKeitai(
          kinoKeitai.filter((checkedValue) => checkedValue !== value)
        )
      : setKinoKeitai([...kinoKeitai, value]);
  };

  const handleSokoTypeChange = (value) => {
    isSelectedSokoType(value)
      ? setSokoType(sokoType.filter((checkedValue) => checkedValue !== value))
      : setSokoType([...sokoType, value]);
  };

  const handleSpecChange = (value) => {
    isSelectedSpec(value)
      ? setSpec(spec.filter((checkedValue) => checkedValue !== value))
      : setSpec([...spec, value]);
  };

  const handleTaiouGyotaiChange = (value) => {
    isSelectedTaiouGyotai(value)
      ? setTaiouGyotai(
          taiouGyotai.filter((checkedValue) => checkedValue !== value)
        )
      : setTaiouGyotai([...taiouGyotai, value]);
  };

  const handleSelectedAll = () => {
    isSelectedAll
      ? setTaiouGyotai([])
      : setTaiouGyotai(taiouGyotaiMList.map((item) => item.taiouGyotaiId));
  };
  const validateSokoName = () => {
    let isValid = true;
    if (sokoName === "") {
      isValid = false;
      setError1("倉庫名が入力されていません");
    }
    return isValid;
  };

  const validateEigyosho = () => {
    let isValid = true;
    if (eigyosho === "") {
      isValid = false;
      setError2("営業所が選択されていません");
    }
    return isValid;
  };

  const validateTantou = () => {
    let isValid = true;
    if (tantouName === "") {
      isValid = false;
      setError3("担当者が入力されていません");
    }
    return isValid;
  };

  const validateTel = () => {
    let isValid = true;
    if (tel === "") {
      isValid = false;
      setError4("電話番号が入力されていません");
    }
    return isValid;
  };

  const validatePre = () => {
    let isValid = true;
    if (prefecture.length === 0) {
      isValid = false;
      setError5("都道府県が選択されていません");
    }
    return isValid;
  };

  const validateCity = () => {
    let isValid = true;
    if (city === "") {
      isValid = false;
      setError6("市区町村が入力されていません");
    }
    return isValid;
  };

  const validateStreet = () => {
    let isValid = true;
    if (street === "") {
      isValid = false;
      setError7("市区町村以下が入力されていません");
    }
    return isValid;
  };

  const validateKino = () => {
    let isValid = true;
    if (kinoKeitai.length === 0) {
      isValid = false;
      setError8("機能・形態が選択されていません");
    }
    return isValid;
  };

  const validateSokoType = () => {
    let isValid = true;
    if (sokoType.length === 0) {
      isValid = false;
      setError9("倉庫種別が選択されていません");
    }
    return isValid;
  };

  const validateSpec = () => {
    let isValid = true;
    if (spec.length === 0) {
      isValid = false;
      setError10("倉庫スペックが選択されていません");
    }
    return isValid;
  };

  const validateChk = () => {
    if (
      validateSokoName() &&
      validateEigyosho() &&
      validateTantou() &&
      validateTel() &&
      validatePre() &&
      validateCity() &&
      validateStreet() &&
      validateKino() &&
      validateSokoType() &&
      validateSpec() === true
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {isLoading ? (
        <Grid>
          <Typography>loading...</Typography>
        </Grid>
      ) : (
        <ThemeProvider theme={mdTheme}>
          <Toaster position="top-right" />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="absolute">
              <Toolbar
                sx={{
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  SENKO-SO-MATCH(登録サイト)
                </Typography>
                <AdminMenu />
              </Toolbar>
            </AppBar>
            <List component="nav" sx={{ mt: 10, width: 240 }}>
              <AdminListItems />
              <Divider sx={{ my: 1 }} />
            </List>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />

              <Container maxWidth="xl" sx={{ mt: 0, mb: { xs: 6, xl: 6 } }}>
                <Grid container spacing={3} className="upperbar" width="auto">
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                      }}
                    >
                      <Grid container alignItems="flex-end" spacing={4}>
                        <Grid item>
                          <Grid container justify="flex-end" spacing={3}>
                            <Grid item>
                              <Button
                                variant="contained"
                                sx={{
                                  background: "gray",
                                  color: "#ffffff",
                                  ":hover": { background: "blue" },
                                }}
                                onClick={() => moveList()}
                              >
                                ＜ 戻る
                              </Button>
                            </Grid>
                            <Grid item>
                              <Grid item>
                                <Typography variant="h5">
                                  倉庫(登録画面)
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                  公開状況:
                                  {status === "" || status === 0
                                    ? "非公開"
                                    : "公開"}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                  更新日時:{item.updateAt}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Hidden mdDown>
                          <Grid
                            item
                            sx={{
                              width: {
                                lg: "26vw",
                                xl: "37vw",
                              },
                            }}
                          ></Grid>
                        </Hidden>
                        <Grid item>
                          <Grid container justify="flex-end" spacing={3}>
                            <Grid item>
                              <PreViewSoko
                                item={{
                                  sokoId: item.id,
                                  sokoName: sokoName,
                                  userId: userInfo.userId,
                                  sokoTypeId: sokoType,
                                  companyId: Number(company),
                                  kankatsuShukanShitenId:
                                    kankatsuShukanShiten === ""
                                      ? 0
                                      : Number(kankatsuShukanShiten),
                                  shitenId: shiten === "" ? 0 : Number(shiten),
                                  eigyoshoId: Number(eigyosho),
                                  tantouName: tantouName,
                                  tel: tel,
                                  prefectureId:
                                    prefecture.length === 0 ? "" : prefecture,
                                  city: city,
                                  street: street,
                                  accessByCar: accessByCar,
                                  accessByTrain: accessByTrain,
                                  completionYear: Number(completionY),
                                  completionMonth: Number(completionM),
                                  kankatsuEigyobuId:
                                    kankatsuEigyobu === ""
                                      ? 0
                                      : Number(kankatsuEigyobu),
                                  kinoKeitaiId: kinoKeitai,
                                  specId: spec,
                                  taiouGyotaiId: taiouGyotai,
                                  status: Number(status),
                                  imageList: imageList,
                                  description: description,
                                  etc: etc,
                                  areaId: [
                                    prefecture.length === 0
                                      ? ""
                                      : String(
                                          prefectureMList.find(
                                            (e) => e.prefectureId === prefecture
                                          ).areaId
                                        ),
                                  ],
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  handleAlertOpen();
                                }}
                              >
                                削除
                              </Button>
                            </Grid>
                            <Grid item>
                              {clickFlag === true ? (
                                <Button variant="contained" disabled>
                                  保存
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    updateSoko();
                                  }}
                                >
                                  保存
                                </Button>
                              )}
                            </Grid>
                            <Dialog
                              open={alert}
                              onClose={(e) => {
                                handleAlertClose(e);
                              }}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                確認事項
                              </DialogTitle>
                              <DialogContent sx={{ width: 500 }}>
                                <Typography variant="subtitle1" marginTop={2}>
                                  削除しますか？
                                </Typography>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  sx={{
                                    width: "30%",
                                    marginRight: 1,
                                    marginBottom: 2,
                                  }}
                                  variant="contained"
                                  color="inherit"
                                  onClick={(e) => {
                                    handleAlertClose(e);
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    いいえ
                                  </Typography>
                                </Button>
                                <Button
                                  sx={{
                                    width: "30%",
                                    marginRight: 2,
                                    marginBottom: 2,
                                  }}
                                  variant="contained"
                                  color="error"
                                  onClick={(e) => {
                                    delSoko();
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    削除
                                  </Typography>
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} xl={12} marginTop={15}>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid marginX="5%">
                        <Grid marginTop={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                <Stack direction="row" alignItems="center">
                                  倉庫名称
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%", mb: 1 }}>
                              <TextField
                                margin="normal"
                                type="text"
                                fullWidth
                                required
                                placeholder="入力してください"
                                value={sokoName}
                                onChange={(e) => {
                                  setSokoName(e.target.value);
                                  validateSokoName();
                                }}
                              />
                              {sokoName !== ""
                                ? ""
                                : error1 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error1}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={2}>
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    会社名
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="company">
                                  会社名を選択
                                </InputLabel>
                                {userInfo.type === 2 ? (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="company"
                                    fullWidth
                                    label="会社名を選択"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                  >
                                    <MenuItem value="">会社名を選択</MenuItem>

                                    <MenuItem
                                      key={companyList.companyId}
                                      value={companyList.companyId}
                                    >
                                      {companyList.name}
                                    </MenuItem>
                                  </Select>
                                ) : (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="company"
                                    fullWidth
                                    label="会社名を選択"
                                    value={company}
                                    onChange={(e) => {
                                      setKankatsuShu(e.target.value);
                                      setCompany(e.target.value);
                                    }}
                                  >
                                    <MenuItem value="">会社名を選択</MenuItem>
                                    {companyMList
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.companyId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                )}
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    管轄主管支店名
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="kankatsuShukanShiten">
                                  {kankatsuShuList.length === 1
                                    ? "なし"
                                    : "管轄主管支店を選択"}
                                </InputLabel>

                                {kankatsuShuList.length === 1 ? (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="kankatsuShukanShiten"
                                    fullWidth
                                    label={
                                      kankatsuShuList.length === 1
                                        ? "なし"
                                        : "管轄主管支店を選択"
                                    }
                                    value={
                                      kankatsuShuList.length === 1
                                        ? ""
                                        : kankatsuShukanShiten
                                    }
                                    onChange={(e) =>
                                      setKankatsuShukanShiten(e.target.value)
                                    }
                                    disabled
                                  >
                                    {kankatsuShuList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">
                                        管轄主管支店を選択
                                      </MenuItem>
                                    )}
                                    {kankatsuShuList.length === 1
                                      ? ""
                                      : kankatsuShuList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={
                                                  item.kankatsuShukanShitenId
                                                }
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                ) : (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="kankatsuShukanShiten"
                                    fullWidth
                                    label={
                                      kankatsuShuList.length === 1
                                        ? "なし"
                                        : "管轄主管支店を選択"
                                    }
                                    value={
                                      kankatsuShuList.length === 1
                                        ? ""
                                        : kankatsuShukanShiten
                                    }
                                    onChange={(e) =>
                                      setKankatsuShukanShiten(e.target.value)
                                    }
                                  >
                                    {kankatsuShuList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">
                                        管轄主管支店を選択
                                      </MenuItem>
                                    )}
                                    {kankatsuShuList.length === 1
                                      ? ""
                                      : kankatsuShuList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={
                                                  item.kankatsuShukanShitenId
                                                }
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                )}
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    支店名
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="sokoType">
                                  {shitenList[0]?.shitenId === 28 ||
                                  shitenList[0]?.shitenId === 25
                                    ? "支店名を選択"
                                    : shitenList.length === 1
                                    ? "なし"
                                    : "支店名を選択"}
                                </InputLabel>

                                {shitenList[0]?.shitenId === 28 ||
                                shitenList[0]?.shitenId === 25 ||
                                (shitenList.length !== 1 &&
                                  shitenList.length !== 0) ? (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="sokoType"
                                    fullWidth
                                    label={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? "支店名を選択"
                                        : shitenList.length === 1
                                        ? "なし"
                                        : "支店名を選択"
                                    }
                                    value={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? shiten
                                        : shitenList.length === 1
                                        ? ""
                                        : shiten
                                    }
                                    onChange={(e) =>
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? setShiten(e.target.value)
                                        : shitenList.length === 1
                                        ? ""
                                        : setShiten(e.target.value)
                                    }
                                  >
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25 ? (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    ) : shitenList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    )}
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25
                                      ? shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })
                                      : shitenList.length === 1
                                      ? ""
                                      : shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                ) : (
                                  <Select
                                    sx={{ width: "50%" }}
                                    labelId="sokoType"
                                    fullWidth
                                    label={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? "支店名を選択"
                                        : shitenList.length === 1
                                        ? "なし"
                                        : "支店名を選択"
                                    }
                                    value={
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? shiten
                                        : shitenList.length === 1
                                        ? ""
                                        : shiten
                                    }
                                    onChange={(e) =>
                                      shitenList[0]?.shitenId === 28 ||
                                      shitenList[0]?.shitenId === 25
                                        ? setShiten(e.target.value)
                                        : shitenList.length === 1
                                        ? ""
                                        : setShiten(e.target.value)
                                    }
                                    disabled
                                  >
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25 ? (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    ) : shitenList.length === 1 ? (
                                      ""
                                    ) : (
                                      <MenuItem value="">支店名を選択</MenuItem>
                                    )}
                                    {shitenList[0]?.shitenId === 28 ||
                                    shitenList[0]?.shitenId === 25
                                      ? shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })
                                      : shitenList.length === 1
                                      ? ""
                                      : shitenList
                                          .sort((a, b) => a.sort - b.sort)
                                          .map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.shitenId}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            );
                                          })}
                                  </Select>
                                )}
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    営業所名
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <InputLabel id="eigyosho">
                                  {eigyoshoList[0]?.eigyoshoId === 449 ||
                                  eigyoshoList[0]?.eigyoshoId === 866 ||
                                  eigyoshoList[0]?.eigyoshoId === 876 ||
                                  eigyoshoList[0]?.eigyoshoId === 877 ||
                                  eigyoshoList[0]?.eigyoshoId === 909 ||
                                  eigyoshoList[0]?.eigyoshoId === 910
                                    ? "支店名を選択"
                                    : eigyoshoList.length === 1
                                    ? "なし"
                                    : "営業所を選択"}
                                </InputLabel>
                                <Select
                                  sx={{ width: "50%" }}
                                  labelId="eigyosho"
                                  fullWidth
                                  label={
                                    eigyoshoList[0]?.eigyoshoId === 449 ||
                                    eigyoshoList[0]?.eigyoshoId === 866 ||
                                    eigyoshoList[0]?.eigyoshoId === 876 ||
                                    eigyoshoList[0]?.eigyoshoId === 877 ||
                                    eigyoshoList[0]?.eigyoshoId === 909 ||
                                    eigyoshoList[0]?.eigyoshoId === 910
                                      ? "支店名を選択"
                                      : eigyoshoList.length === 1
                                      ? "なし"
                                      : "営業所を選択"
                                  }
                                  value={
                                    eigyoshoList[0]?.eigyoshoId === 449 ||
                                    eigyoshoList[0]?.eigyoshoId === 866 ||
                                    eigyoshoList[0]?.eigyoshoId === 876 ||
                                    eigyoshoList[0]?.eigyoshoId === 877 ||
                                    eigyoshoList[0]?.eigyoshoId === 909 ||
                                    eigyoshoList[0]?.eigyoshoId === 910
                                      ? eigyosho
                                      : eigyoshoList.length === 1
                                      ? ""
                                      : eigyosho
                                  }
                                  onChange={(e) =>
                                    eigyoshoList[0]?.eigyoshoId === 449 ||
                                    eigyoshoList[0]?.eigyoshoId === 866 ||
                                    eigyoshoList[0]?.eigyoshoId === 876 ||
                                    eigyoshoList[0]?.eigyoshoId === 877 ||
                                    eigyoshoList[0]?.eigyoshoId === 909 ||
                                    eigyoshoList[0]?.eigyoshoId === 910
                                      ? eigyoshoSet(e.target.value)
                                      : eigyoshoList.length === 1
                                      ? ""
                                      : eigyoshoSet(e.target.value)
                                  }
                                >
                                  <MenuItem value="">営業所を選択</MenuItem>
                                  {eigyoshoList[0]?.eigyoshoId === 449 ||
                                  eigyoshoList[0]?.eigyoshoId === 866 ||
                                  eigyoshoList[0]?.eigyoshoId === 876 ||
                                  eigyoshoList[0]?.eigyoshoId === 877 ||
                                  eigyoshoList[0]?.eigyoshoId === 909 ||
                                  eigyoshoList[0]?.eigyoshoId === 910
                                    ? eigyoshoList
                                        .sort((a, b) => a.sort - b.sort)
                                        .map((item, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={item.eigyoshoId}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          );
                                        })
                                    : eigyoshoList.length === 1
                                    ? ""
                                    : eigyoshoList
                                        .sort((a, b) => a.sort - b.sort)
                                        .map((item, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={item.eigyoshoId}
                                            >
                                              {item.name}
                                            </MenuItem>
                                          );
                                        })}
                                </Select>
                                {eigyosho !== ""
                                  ? ""
                                  : error2 && (
                                      <Typography
                                        variant="subtitle1"
                                        color="error"
                                      >
                                        {error2}
                                      </Typography>
                                    )}
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid marginTop={2}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">管轄営業部</Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <Typography variant="subtitle1">
                                  {eigyosho === ""
                                    ? ""
                                    : kankatsuEigyobuList.name}
                                </Typography>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    担当者名
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <TextField
                                  sx={{ width: "50%" }}
                                  margin="normal"
                                  type="text"
                                  fullWidth
                                  required
                                  placeholder="入力してください"
                                  value={tantouName}
                                  onChange={(e) => {
                                    setTantouName(e.target.value);
                                    validateTantou();
                                  }}
                                />
                                {tantouName !== ""
                                  ? ""
                                  : error3 && (
                                      <Typography
                                        variant="subtitle1"
                                        color="error"
                                      >
                                        {error3}
                                      </Typography>
                                    )}
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid marginTop={-1}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    電話番号
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <TextField
                                  sx={{ width: "50%" }}
                                  margin="normal"
                                  type="tel"
                                  fullWidth
                                  required
                                  placeholder="半角数字で入力"
                                  value={tel}
                                  onChange={(e) => {
                                    setTel(
                                      e.target.value.replace(/[^0-9]/g, "")
                                    );
                                    validateTel();
                                  }}
                                />
                                {tel !== ""
                                  ? ""
                                  : error4 && (
                                      <Typography
                                        variant="subtitle1"
                                        color="error"
                                      >
                                        {error4}
                                      </Typography>
                                    )}
                              </FormControl>
                            </Stack>
                          </Grid>

                          <Grid marginTop={-1}>
                            <Stack direction="row" alignItems="center">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">
                                  <Stack direction="row" alignItems="center">
                                    所在地
                                    <span style={{ color: "red" }}>*</span>
                                  </Stack>
                                </Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <FormControl sx={{ width: "50%" }}>
                                    <InputLabel id="state" sx={{ mt: 1 }}>
                                      都道府県を選択
                                    </InputLabel>
                                    <Select
                                      sx={{ mt: 1 }}
                                      labelId="state"
                                      fullWidth
                                      label="都道府県を選択"
                                      value={prefecture}
                                      onChange={(e) => {
                                        setPrefecture(e.target.value);
                                        validatePre();
                                      }}
                                    >
                                      <MenuItem value="">
                                        都道府県を選択
                                      </MenuItem>
                                      {prefectureMList.map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.prefectureId}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    {prefecture.length !== 0
                                      ? ""
                                      : error5 && (
                                          <Typography
                                            variant="subtitle1"
                                            color="error"
                                          >
                                            {error5}
                                          </Typography>
                                        )}
                                  </FormControl>

                                  <FormControl sx={{ width: "50%" }}>
                                    <TextField
                                      margin="normal"
                                      type="text"
                                      fullWidth
                                      required
                                      placeholder="市区町村を入力"
                                      value={city}
                                      onChange={(e) => {
                                        setCity(e.target.value);
                                        validateCity();
                                      }}
                                    />
                                    {city !== ""
                                      ? ""
                                      : error6 && (
                                          <Typography
                                            variant="subtitle1"
                                            color="error"
                                          >
                                            {error6}
                                          </Typography>
                                        )}
                                  </FormControl>
                                </Stack>
                              </FormControl>
                            </Stack>
                          </Grid>
                          <Grid marginTop={-1}>
                            <FormControl
                              sx={{ marginX: "5%", width: "20%" }}
                            ></FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <TextField
                                margin="normal"
                                type="text"
                                fullWidth
                                required
                                placeholder="町域・番地などを入力"
                                value={street}
                                onChange={(e) => {
                                  setStreet(e.target.value);
                                  validateStreet();
                                }}
                              />
                              {street !== ""
                                ? ""
                                : error7 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error7}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Grid>
                          <Grid marginTop={1}>
                            <Stack direction="row" alignItems="flex-start">
                              <FormControl sx={{ marginX: "5%", width: "20%" }}>
                                <Typography variant="h6">交通</Typography>
                              </FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <Typography variant="h6" marginBottom={-2}>
                                  鉄道・電車
                                </Typography>

                                <TextField
                                  margin="normal"
                                  type="text"
                                  fullWidth
                                  required
                                  placeholder="入力してください"
                                  value={accessByTrain}
                                  onChange={(e) =>
                                    setAccessByTrain(e.target.value)
                                  }
                                />
                              </FormControl>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <FormControl
                                sx={{ marginX: "5%", width: "20%" }}
                              ></FormControl>
                              <FormControl sx={{ width: "65%" }}>
                                <Typography
                                  variant="h6"
                                  marginTop={1}
                                  marginBottom={-2}
                                >
                                  クルマ
                                </Typography>

                                <TextField
                                  margin="normal"
                                  type="text"
                                  fullWidth
                                  required
                                  placeholder="入力してください"
                                  value={accessByCar}
                                  onChange={(e) =>
                                    setAccessByCar(e.target.value)
                                  }
                                />
                              </FormControl>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">竣工年月</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <Stack
                                direction="row"
                                alignItems="flex-end"
                                spacing={1}
                              >
                                <FormControl>
                                  <InputLabel id="year">西暦</InputLabel>
                                  <Select
                                    sx={{ width: 150 }}
                                    labelId="year"
                                    fullWidth
                                    label="西暦"
                                    value={completionY}
                                    onChange={(e) =>
                                      setCompletionY(e.target.value)
                                    }
                                  >
                                    <MenuItem value="">西暦</MenuItem>
                                    {yearRange.map((item) => {
                                      return (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <Grid>
                                  <Typography>年</Typography>
                                </Grid>
                                <FormControl>
                                  <InputLabel id="month">月</InputLabel>
                                  <Select
                                    sx={{ width: 150 }}
                                    labelId="month"
                                    fullWidth
                                    label="月"
                                    value={completionM}
                                    onChange={(e) =>
                                      setCompletionM(e.target.value)
                                    }
                                  >
                                    <MenuItem disabled value="">
                                      月
                                    </MenuItem>
                                    {monthList.map((item) => {
                                      return (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <Grid>
                                  <Typography>月</Typography>
                                </Grid>
                              </Stack>
                            </FormControl>
                          </Stack>
                        </Grid>

                        <Divider />
                        <Grid marginTop={1} marginBottom={1}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">
                                <Stack direction="row" alignItems="center">
                                  機能・形態を選択
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <FormGroup row={true}>
                                {kinoKeitaiMList
                                  .sort(
                                    (a, b) => a.kinoKeitaiId - b.kinoKeitaiId
                                  )
                                  .map((item, index) => {
                                    const isItemSelected = isSelectedKinoKeitai(
                                      item.kinoKeitaiId
                                    );
                                    return (
                                      <FormControlLabel
                                        sx={{ marginRight: 5 }}
                                        key={index}
                                        value={item.kinoKeitaiId}
                                        onChange={(e) => {
                                          handleKinoKeitaiChange(
                                            item.kinoKeitaiId
                                          );
                                          validateKino();
                                        }}
                                        control={
                                          <Checkbox checked={isItemSelected} />
                                        }
                                        label={item.name}
                                      />
                                    );
                                  })}
                              </FormGroup>
                              {kinoKeitai.length !== 0
                                ? ""
                                : error8 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error8}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={1} marginBottom={1}>
                          <Stack direction="row" alignItems="flex-start">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6" marginTop={1}>
                                <Stack direction="row" alignItems="center">
                                  スペックを選択
                                  <span style={{ color: "red" }}>*</span>
                                </Stack>
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <Typography variant="h6" marginTop={1}>
                                倉庫種別
                              </Typography>
                              <FormGroup row={true}>
                                {sokoTypeMList
                                  .sort((a, b) => a.sokoTypeId - b.sokoTypeId)
                                  .map((item, index) => {
                                    const isItemSelected = isSelectedSokoType(
                                      item.sokoTypeId
                                    );
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        sx={{ marginRight: 5 }}
                                        value={item.sokoTypeId}
                                        onChange={() => {
                                          handleSokoTypeChange(item.sokoTypeId);
                                          validateSokoType();
                                        }}
                                        control={
                                          <Checkbox checked={isItemSelected} />
                                        }
                                        label={item.name}
                                      />
                                    );
                                  })}
                              </FormGroup>
                              {sokoType.length !== 0
                                ? ""
                                : error9 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error9}
                                    </Typography>
                                  )}
                              <Typography variant="h6" marginTop={3}>
                                倉庫スペック
                              </Typography>
                              <FormGroup row={true}>
                                {specMList
                                  .sort((a, b) => a.specId - b.specId)
                                  .map((item, index) => {
                                    const isItemSelected = isSelectedSpec(
                                      item.specId
                                    );
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        sx={{ marginRight: 5 }}
                                        value={item.specId}
                                        onChange={() => {
                                          handleSpecChange(item.specId);
                                          validateSpec();
                                        }}
                                        control={
                                          <Checkbox checked={isItemSelected} />
                                        }
                                        label={item.name}
                                      />
                                    );
                                  })}
                                {spec.includes(13) ? (
                                  <Grid>
                                    <TextField
                                      margin="normal"
                                      type="text"
                                      fullWidth
                                      placeholder="その他特記事項"
                                      value={etc}
                                      onChange={(e) => {
                                        setEtc(e.target.value);
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                              {spec.length !== 0
                                ? ""
                                : error10 && (
                                    <Typography
                                      variant="subtitle1"
                                      color="error"
                                    >
                                      {error10}
                                    </Typography>
                                  )}
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={1} marginBottom={1}>
                          <Stack direction="row" alignItems="flex-start">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6" marginTop={1}>
                                希望業態を選択
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <FormControlLabel
                                key="99"
                                sx={{ marginRight: 5 }}
                                onChange={handleSelectedAll}
                                control={
                                  <Checkbox indeterminate={isIndeterminate} />
                                }
                                checked={isSelectedAll}
                                label="全てを選択"
                              />
                              <FormGroup row={true}>
                                {taiouGyotaiMList
                                  .sort(
                                    (a, b) => a.taiouGyotaiId - b.taiouGyotaiId
                                  )
                                  .map((item, index) => {
                                    const isItemSelected =
                                      isSelectedTaiouGyotai(item.taiouGyotaiId);

                                    return (
                                      <FormControlLabel
                                        key={index}
                                        sx={{ marginRight: 5 }}
                                        value={item.taiouGyotaiId}
                                        control={
                                          <Checkbox checked={isItemSelected} />
                                        }
                                        label={item.name}
                                        onChange={() =>
                                          handleTaiouGyotaiChange(
                                            item.taiouGyotaiId
                                          )
                                        }
                                      />
                                    );
                                  })}
                              </FormGroup>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={2} marginBottom={2}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">画像登録</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <MediaManager
                                sokoId={item.id}
                                imageList={imageList}
                                setImageList={setImageList}
                              />
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid marginTop={1} marginBottom={1}>
                          <Stack direction="row" alignItems="center">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6">公開非公開</Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <RadioGroup
                                row
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="公開する"
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="非公開"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Divider />
                        <Grid>
                          <Stack direction="row" alignItems="flex-start">
                            <FormControl sx={{ marginX: "5%", width: "20%" }}>
                              <Typography variant="h6" marginTop={1}>
                                備考
                              </Typography>
                            </FormControl>
                            <FormControl sx={{ width: "65%" }}>
                              <TextField
                                margin="normal"
                                type="text"
                                fullWidth
                                required
                                multiline
                                rows={3}
                                placeholder="入力してください"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <Copyright sx={{ pt: 2 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
}

export default AdminEditWareHouse;
