import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory, Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Conditions from "./Conditions";
import Chip from "@mui/material/Chip";
import AspectRatio from "@mui/joy/AspectRatio";
import { CssVarsProvider } from "@mui/joy/styles";
import { useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Thumbs, FreeMode, Navigation, Pagination } from "swiper";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import dayjs from "dayjs";
import "./App.css";
import "swiper/css";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function NewChild(props) {
  const { currentUser, userInfo, masterData } = useAuthValue();
  const matches = useMediaQuery("(min-width:900px)");
  const [image, setImage] = useState([]);
  const history = useHistory();
  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const moveDetail = (item) => {
    history.push({
      pathname: "/storagedetail",
      state: {
        item: item,
        searchParams: props.searchParams,
        type: 1,
      },
    });
  };

  useEffect(() => {
    props.newSokoArray.map((item) => {
      const sokoImg = getStorage();

      const sokoId = item.sokoId;

      const getImg = async (sokoId) => {
        const image = [];
        const listRef = ref(
          sokoImg,
          `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
        );
        const list = await listAll(listRef);
        const urls = await Promise.all(
          list.items.map((ref) => getDownloadURL(ref))
        );
        const decodeUrls = urls.map(
          (item) =>
            `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
            decodeURIComponent(item).split("thumb/")[1].split("?")[0]
        );

        setImage(...decodeUrls);

        Object.assign(
          props.newSokoArray.find((e) => sokoId === item.sokoId),
          { image1: [...decodeUrls] }
        );
      };
      getImg(sokoId);
    });
  }, [props.newSokoArray]);

  return (
    <>
      <Swiper
        className="mySwiper"
        loop={true}
        spaceBetween={3}
        slidesPerView={matches ? 3 : 1}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        modules={[Navigation, Autoplay, Pagination]}
      >
        {props.newSokoArray.map((item) => (
          <SwiperSlide
            key={item.storageId}
            onClick={() => {
              moveDetail(item);
            }}
            className="pointer"
          >
            <Grid sx={{ width: "100%", mt: 3, mb: 3, p: 1.5 }}>
              <Paper
                sx={{
                  p: 2,
                }}
              >
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Grid container direction="column" alignItems="center">
                      <Grid width="90%">
                        <CssVarsProvider>
                          <AspectRatio objectFit="contain">
                            <img
                              src={
                                item?.image[0] === undefined
                                  ? `${process.env.REACT_APP_IMG_URL}conf/thumb/soko-dummy_400x400.png`
                                  : item?.image[0]
                              }
                            />
                          </AspectRatio>
                        </CssVarsProvider>
                      </Grid>
                    </Grid>

                    <Grid marginLeft={1}>
                      <Typography
                        variant="subtitle1"
                        marginTop={1}
                        marginBottom={1}
                      >
                        <b>{item.sokoName}</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        住所:{"　"}
                        {
                          prefectureMList.find(
                            (e) => e.prefectureId === item.prefectureId
                          ).name
                        }
                        {" " + item.city + " " + item.street}
                      </Typography>
                      <Typography variant="subtitle2">
                        利用可能面積:{"　"}
                        {item.availableArea}坪
                      </Typography>
                      <Typography variant="subtitle2">
                        対応貨物:{"　"}
                        {item.taiouGyotaiId.map(
                          (item1) =>
                            taiouGyotaiMList.find(
                              (e) => e.taiouGyotaiId === item1
                            ).name + " "
                        )}
                      </Typography>

                      <Typography variant="subtitle2">
                        坪単価（税別）:{"　"}
                        {item.unitPrice}円／坪
                      </Typography>
                      <Typography variant="subtitle2" marginBottom={2}>
                        入庫可能日:{"　"}
                        {dayjs(item.availableDate).format(
                          "YYYY 年 MM 月 DD 日 "
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default NewChild;
