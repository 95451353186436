import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import PaidIcon from "@mui/icons-material/Paid";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { useAuthValue } from "./AuthContext";
import Typography from "@mui/material/Typography";
import { Route, Link, Switch, useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

const date = new Date();

const oneYearAfter = dayjs(date).add(1, "y").format("YYYY/MM/DD hh:mm:ss");

export default function UserMenu() {
  const { currentUser } = useAuthValue();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const search = {
    startAt: "2022/01/01 00:00:00",
    endAt: oneYearAfter,
    sortKey: "createAt",
    ascdesc: "desc",
    limit: 50,
    offset: 0,
    areaId: "",
    prefectureId: "",
    availableArea: "",
    sokoTypeId: [],
    companyId: "",
    shitenId: "",
    contractId: [],
    kankatsuShukanShitenId: "",
    kankatsuEigyobuId: "",
    availableYear: "",
    availableMonth: "",
    kinoKeitaiId: "",
    specId: "",
    taiouGyotaiId: "",
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: search,
      },
    });
  };

  return (
    <React.Fragment>
      {matches ? (
        <Typography sx={{ minWidth: 100 }}>{currentUser.email}</Typography>
      ) : (
        <Typography sx={{ minWidth: 150, maxWidth: 200 }}></Typography>
      )}
      <Tooltip title="user Information">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="inherit"
        >
          <Avatar />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {matches ? (
          ""
        ) : (
          <MenuItem>
            <Typography>{currentUser.email}</Typography>
          </MenuItem>
        )}
        {matches ? "" : <Divider />}

        <MenuItem onClick={() => moveFavorite()}>
          <IconButton>お気に入り</IconButton>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/updateemail">
          <IconButton>メールアドレス変更</IconButton>
        </MenuItem>

        <Divider />
        <MenuItem component={Link} to="/updatepw">
          <IconButton>パスワード変更</IconButton>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/setting">
          <IconButton>設定</IconButton>
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={() => {
            signOut(auth);
            window.location.reload();
          }}
        >
          <Logout fontSize="small" />
          <IconButton>ログアウト</IconButton>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
