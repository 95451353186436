import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PinDropIcon from "@mui/icons-material/PinDrop";
import InfoIcon from "@mui/icons-material/Info";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { RiDashboardFill } from "react-icons/ri";
import { GiWoodenSign } from "react-icons/gi";
import { IoStorefront, IoTicket } from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi";
import { useAuthValue } from "./AuthContext";

function AdminListItems() {
  const { currentUser, userInfo } = useAuthValue();
  return (
    <>
      <ListItemButton component={Link} to="/admin">
        <ListItemText primary="空坪一覧" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton component={Link} to="/admin/addemptyarea">
        <ListItemText primary="空坪登録" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton component={Link} to="/admin/warehouselist">
        <ListItemText primary="倉庫一覧" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton component={Link} to="/admin/addwarehouse">
        <ListItemText primary="倉庫登録" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      {userInfo.type === 3 ? (
        <ListItemButton component={Link} to="/admin/allAdminUsers">
          <ListItemText primary="管理者一覧" />
        </ListItemButton>
      ) : (
        ""
      )}
      {/* {userInfo.type === 3 ? <Divider sx={{ my: 1 }} /> : ""}
      {userInfo.type === 3 ? (
        <ListItemButton component={Link} to="/admin/addmaster">
          <ListItemText primary="マスタ追加" />
        </ListItemButton>
      ) : (
        ""
      )} */}
      {userInfo.type === 3 ? <Divider sx={{ my: 1 }} /> : ""}
      <ListItemButton component={Link} to="/admin/updateemail">
        <ListItemText primary="メールアドレス変更" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton component={Link} to="/admin/updatepw">
        <ListItemText primary="パスワード変更" />
      </ListItemButton>
    </>
  );
}
export default AdminListItems;
