import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Pagination from "@mui/material/Pagination";
import Conditions from "./Conditions";
import UserMenu from "./UserMenu";
import UserFavoriteResultData from "./UserFavoriteResultData";
import { useMediaQuery } from "@mui/material";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import IconButton from "@mui/material/IconButton";
import { PiArrowFatUpFill } from "react-icons/pi";
import "./App.css";
import dayjs from "dayjs";

const limitList = [
  { id: 50, name: "50件を表示" },
  { id: 100, name: "100件を表示" },
  { id: 150, name: "150件を表示" },
];

const sortList = [
  { id: 1, ascdesc: "asc", sort: "updateAt", name: "更新日時が古い順" },
  { id: 2, ascdesc: "desc", sort: "updateAt", name: "更新日時が新しい順" },
  { id: 3, ascdesc: "asc", sort: "availableArea", name: "面積が狭い順" },
  { id: 4, ascdesc: "desc", sort: "availableArea", name: "面積が広い順" },
];

const start = new Date().getFullYear();
let end = start + 3;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const areaList = ["全てを選択", "〜100坪", "〜500坪", "〜1000坪", "1500坪以上"];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const resFavoriteList = (resData) => {
  const favoriteData = [];
  resData.forEach((okini) => {
    const storageId = okini.storageId;
    favoriteData.push(storageId);
  });
  return favoriteData;
};

const resFavoriteData = (resData) => {
  const favoriteDataObjectAarray = [];
  const { result } = resData;
  result.forEach((okini) => {
    const okiniId = okini["okini.okiniId"];
    const storageId = okini["okini.storageId"];
    const status = okini["okini.status"];
    const availableArea = okini["storage.availableArea"];
    const availableDate = okini["storage.availableDate"];
    const availableDay = okini["storage.availableDay"];
    const availableMonth = okini["storage.availableMonth"];
    const availableYear = okini["storage.availableYear"];
    const commonFee = okini["storage.commonFee"];
    const contractId = okini["storage.contractId"];
    const storageDescription = okini["storage.description"];
    const floorId = okini["storage.floorId"];
    const negotiable = okini["storage.negotiable"];
    const soon = okini["storage.soon"];
    const unitPrice = okini["storage.unitPrice"];
    const sokoId = okini["storage.sokoId"];
    const imageList = [];
    const sokoImg = getStorage();
    const getImg = async (sokoId) => {
      const listRef = ref(
        sokoImg,
        `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
      );
      const list = await listAll(listRef);

      const urls = await Promise.all(
        list.items.map((ref) => getDownloadURL(ref))
      );
      const decodeUrls = urls.map(
        (item) =>
          `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
          decodeURIComponent(item).split("thumb/")[1].split("?")[0]
      );
      imageList.push(...decodeUrls);
    };
    getImg(sokoId);
    const sokoName = okini["soko.sokoName"];
    const prefecture = okini["soko.prefectureId"][0];
    const area = okini["soko.areaId"];
    const city = okini["soko.city"];
    const street = okini["soko.street"];
    const sokoType = okini["soko.sokoTypeId"];
    const companyId = okini["soko.companyId"];
    const kankatsuShukanShitenId = okini["soko.kankatsuShukanShitenId"];
    const shitenId = okini["soko.shitenId"];
    const kankatsuEigyobuId = okini["soko.kankatsuEigyobuId"];
    const eigyoshoId = okini["soko.eigyoshoId"];
    const tantouName = okini["soko.tantouName"];
    const tel = okini["soko.tel"];
    const accessByCar = okini["soko.accessByCar"];
    const accessByTrain = okini["soko.accessByTrain"];
    const completionYear = okini["soko.completionYear"];
    const completionMonth = okini["soko.completionMonth"];
    const kinoKeitaiId = okini["soko.kinoKeitaiId"];
    const specId = okini["soko.specId"];
    const taiouGyotaiId = okini["soko.taiouGyotaiId"];
    const sokoDescription = okini["soko.description"];
    const storageStatus = okini["storage.status"];
    const sokoStatus = okini["soko.status"];
    const sokoEtc = okini["soko.etc"];

    const updateAt = dayjs(okini["storage.updateAt"]).format(
      "YYYY/MM/DD hh:mm"
    );
    const createAt = dayjs(okini["storage.createAt"]).format(
      "YYYY/MM/DD hh:mm"
    );
    favoriteDataObjectAarray.push({
      image: imageList,
      topImg: imageList[0],
      okiniId: okiniId,
      storageId: storageId,
      sokoId: sokoId,
      sokoName: sokoName,
      prefectureId: prefecture,
      area: area,
      city: city,
      street: street,
      sokoTypeId: sokoType,
      companyId: companyId,
      kankatsuShukanShitenId: kankatsuShukanShitenId,
      shitenId: shitenId,
      kankatsuEigyobuId: kankatsuEigyobuId,
      eigyoshoId: eigyoshoId,
      tantouName: tantouName,
      tel: tel,
      accessByCar: accessByCar,
      accessByTrain: accessByTrain,
      completionYear: completionYear,
      completionMonth: completionMonth,
      kinoKeitaiId: kinoKeitaiId,
      specId: specId,
      taiouGyotaiId: taiouGyotaiId,
      availableArea: availableArea,
      availableDate: availableDate,
      availableDay: availableDay,
      availableMonth: availableMonth,
      availableYear: availableYear,
      commonFee: commonFee,
      contractId: contractId,
      storageDescription: storageDescription,
      sokoDescription: sokoDescription,
      sokoEtc: sokoEtc,
      floorId: floorId,
      negotiable: negotiable,
      soon: soon,
      unitPrice: unitPrice,
      status: status,
      storageStatus: storageStatus,
      sokoStatus: sokoStatus,
      updateAt: updateAt,
      createAt: createAt,
    });
  });
  return favoriteDataObjectAarray;
};

function UserFavorite(props) {
  const { currentUser, userInfo, masterData, totalCount, newCount } =
    useAuthValue();
  const [count, setCount] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState();
  const [page, setPage] = useState(1);
  const [favorite, setFavorite] = useState([]);
  const [resultCount, setResultCount] = useState();
  const [resultArray, setResultArray] = useState();
  const [favoriteData, setFavoriteData] = useState([]);
  const [favoriteData1, setFavoriteData1] = useState([]);
  const [okiniList, setOkiniList] = useState([]);

  const matches = useMediaQuery("(min-width:1050px)");
  const history = useHistory();

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;
  const searchParams = props.location.state.searchParams;

  const box1 = useRef(null);

  const scroll = (box) => {
    box.current.scrollIntoView("smooth");
  };

  const resFavoriteData1 = (resData) => {
    const favoriteDataObjectAarray = [];
    const { result } = resData;
    scroll(box1);
    result.forEach((okini) => {
      const okiniId = okini["okini.okiniId"];
      const storageId = okini["okini.storageId"];
      const status = okini["okini.status"];
      const availableArea = okini["storage.availableArea"];
      const availableDate = okini["storage.availableDate"];
      const availableDay = okini["storage.availableDay"];
      const availableMonth = okini["storage.availableMonth"];
      const availableYear = okini["storage.availableYear"];
      const commonFee = okini["storage.commonFee"];
      const contractId = okini["storage.contractId"];
      const storageDescription = okini["storage.description"];
      const floorId = okini["storage.floorId"];
      const negotiable = okini["storage.negotiable"];
      const soon = okini["storage.soon"];
      const unitPrice = okini["storage.unitPrice"];
      const sokoId = okini["storage.sokoId"];
      const imageList = [];
      const sokoImg = getStorage();
      const getImg = async (sokoId) => {
        const listRef = ref(
          sokoImg,
          `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
        );
        const list = await listAll(listRef);

        const urls = await Promise.all(
          list.items.map((ref) => getDownloadURL(ref))
        );
        const decodeUrls = urls.map(
          (item) =>
            `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
            decodeURIComponent(item).split("thumb/")[1].split("?")[0]
        );
        imageList.push(...decodeUrls);
      };
      getImg(sokoId);
      const sokoName = okini["soko.sokoName"];
      const prefecture = okini["soko.prefectureId"][0];
      const area = okini["soko.areaId"];
      const city = okini["soko.city"];
      const street = okini["soko.street"];
      const sokoType = okini["soko.sokoTypeId"];
      const companyId = okini["soko.companyId"];
      const kankatsuShukanShitenId = okini["soko.kankatsuShukanShitenId"];
      const shitenId = okini["soko.shitenId"];
      const kankatsuEigyobuId = okini["soko.kankatsuEigyobuId"];
      const eigyoshoId = okini["soko.eigyoshoId"];
      const tantouName = okini["soko.tantouName"];
      const tel = okini["soko.tel"];
      const accessByCar = okini["soko.accessByCar"];
      const accessByTrain = okini["soko.accessByTrain"];
      const completionYear = okini["soko.completionYear"];
      const completionMonth = okini["soko.completionMonth"];
      const kinoKeitaiId = okini["soko.kinoKeitaiId"];
      const specId = okini["soko.specId"];
      const taiouGyotaiId = okini["soko.taiouGyotaiId"];
      const sokoDescription = okini["soko.description"];
      const storageStatus = okini["storage.status"];
      const sokoStatus = okini["soko.status"];
      const sokoEtc = okini["soko.etc"];
      const updateAt = dayjs(okini["storage.updateAt"]).format(
        "YYYY/DD/MM hh:mm"
      );
      const createAt = dayjs(okini["storage.createAt"]).format(
        "YYYY/DD/MM hh:mm"
      );
      favoriteDataObjectAarray.push({
        image: imageList,
        topImg: imageList[0],
        okiniId: okiniId,
        storageId: storageId,
        sokoId: sokoId,
        sokoName: sokoName,
        prefectureId: prefecture,
        area: area,
        city: city,
        street: street,
        sokoTypeId: sokoType,
        companyId: companyId,
        kankatsuShukanShitenId: kankatsuShukanShitenId,
        shitenId: shitenId,
        kankatsuEigyobuId: kankatsuEigyobuId,
        eigyoshoId: eigyoshoId,
        tantouName: tantouName,
        tel: tel,
        accessByCar: accessByCar,
        accessByTrain: accessByTrain,
        completionYear: completionYear,
        completionMonth: completionMonth,
        kinoKeitaiId: kinoKeitaiId,
        specId: specId,
        taiouGyotaiId: taiouGyotaiId,
        availableArea: availableArea,
        availableDate: availableDate,
        availableDay: availableDay,
        availableMonth: availableMonth,
        availableYear: availableYear,
        commonFee: commonFee,
        contractId: contractId,
        storageDescription: storageDescription,
        sokoDescription: sokoDescription,
        sokoEtc: sokoEtc,
        floorId: floorId,
        negotiable: negotiable,
        soon: soon,
        unitPrice: unitPrice,
        status: status,
        storageStatus: storageStatus,
        sokoStatus: sokoStatus,
        updateAt: updateAt,
        createAt: createAt,
      });
    });
    return favoriteDataObjectAarray;
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: searchParams,
      },
    });
  };

  const getOkini = () => {
    setFavoriteData([]);
    setFavoriteData1([]);
    const userFavorite = {
      userId: currentUser?.userId,
      sortKey: "updateAt",
      ascdesc: "desc",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/getLike`, userFavorite, {
        headers: headers,
      })
      .then((response) => {
        const data = response.data.result;
        const data1 = data.filter((e) => e.status === 1);
        const data2 = resFavoriteList(data1);
        const set = new Set(data2);
        setFavoriteData(data);
        setFavoriteData1([...set]);
      });
  };

  const userFavorite = {
    userId: userInfo.userId,
    sortKey: "updateAt",
    ascdesc: "desc",
    limit: 50,
    offset: 0,
    status: 1,
  };

  const favoriteSearch = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfoCount2`,
        userFavorite,
        { headers: headers }
      )
      .then((response) => {
        setResultCount(response.data.result[0]["count(*)"]);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfo2`,
        userFavorite,
        { headers: headers }
      )
      .then((response) => {
        const favoriteDataArray = resFavoriteData(response.data);
        setResultArray(favoriteDataArray);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const searchNext = (event, value) => {
    scroll(box1);
    const limit = userFavorite.limit;
    setPage(value);
    const params = Object.assign(userFavorite, {
      offset: value * limit - limit,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfoCount2`,
        params,
        { headers: headers }
      )
      .then((response) => {
        setResultCount(response.data.result[0]["count(*)"]);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfo2`,
        params,
        { headers: headers }
      )
      .then((response) => {
        const favoriteDataArray = resFavoriteData(response.data);
        setResultArray(favoriteDataArray);
      })
      .catch((err) => {});
  };

  const scrollTop = (event, value) => {
    scroll(box1);
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  const sortChange = (item) => {
    setSortKey(item);
    const key = sortList.find((e) => e.id === item).sort;
    const ascdesc = sortList.find((e) => e.id === item).ascdesc;
    const params = Object.assign(userFavorite, {
      sortKey: key,
      ascdesc: ascdesc,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfoCount2`,
        params,
        { headers: headers }
      )
      .then((response) => {
        setResultCount(response.data.result[0]["count(*)"]);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfo2`,
        params,
        { headers: headers }
      )
      .then((response) => {
        const favoriteDataArray = resFavoriteData1(response.data);
        setResultArray(favoriteDataArray);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const limitChange = (item) => {
    setCount(item);
    const limit = limitList.find((e) => e.id === item).id;
    const params = Object.assign(userFavorite, { limit: limit });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfoCount2`,
        params,
        { headers: headers }
      )
      .then((response) => {
        setResultCount(response.data.result[0]["count(*)"]);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}storage/getLikeWithInfo2`,
        params,
        { headers: headers }
      )
      .then((response) => {
        const favoriteDataArray = resFavoriteData1(response.data);
        setResultArray(favoriteDataArray);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const okiniListDel = () => {
    const okini = {
      okiniId: okiniList,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/deletelikeM`, okini, {
        headers: headers,
      })
      .then((response) => {
        toast.success(
          () => <Typography variant="h5">削除しました</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        setTimeout(() => {
          favoriteSearch();
        }, 4000);
      })
      .catch((err) => {
        toast.error(
          () => <Typography variant="h5">削除できませんでした</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      });
  };

  useEffect(() => {
    favoriteSearch();
    getOkini();
  }, []);
  const pageCount = Math.ceil(resultCount / 10);

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Grid className="scrollbutton">
          <IconButton
            sx={{ width: "3rem", height: "3rem" }}
            onClick={scrollTop}
          >
            <PiArrowFatUpFill size={"3rem"} color="#FFFFFF" />
          </IconButton>
        </Grid>
        <Toaster position="top-right" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className="pointer"
                sx={{ display: "block", marginRight: 5 }}
                onClick={() => moveTop()}
              >
                {matches ? "SENKO-SO-MATCH" : "SSM"}
              </Typography>
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  登録
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  ccolor="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  {totalCount}
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block", marginRight: 3 }}
                >
                  棟
                </Typography>
              ) : (
                ""
              )}

              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  新着
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  {newCount}
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block", flexGrow: 1 }}
                >
                  棟
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Button onClick={() => moveTop()}>
                  <Typography color="#FFFFFF">ホーム</Typography>
                </Button>
              ) : (
                ""
              )}
              {currentUser?.email !== undefined && matches ? (
                <Button onClick={() => moveFavorite()}>
                  <Typography color="#FFFFFF">お気に入り</Typography>
                </Button>
              ) : (
                ""
              )}
              {currentUser?.email !== undefined ? (
                <UserMenu />
              ) : (
                <Button
                  component={Link}
                  to="/login"
                  variant="outlined"
                  color="inherit"
                >
                  <Typography color="#FFFFFF">ログイン</Typography>
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar ref={box1} />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginRight={1}
                          onClick={() => moveTop()}
                        >
                          TOP
                        </Typography>
                        <Typography variant="subtitle1" marginRight={1}>
                          {">"}
                        </Typography>
                        <Typography variant="subtitle1" marginRight={1}>
                          お気に入り
                        </Typography>
                      </Stack>
                      <Grid container direction="column" alignItems="center">
                        <Typography variant={matches ? "h4" : "h5"}>
                          お気に入り一覧
                        </Typography>
                      </Grid>
                      <Grid sx={{ width: "100%", marginTop: 3 }}>
                        <Grid container direction="column" alignItems="center">
                          <Conditions
                            searchParams={props.location.state.searchParams}
                            prefecture={
                              props.location.state?.prefecture ||
                              props.location.state.searchParams?.prefectureId
                            }
                            area={
                              props.location.state?.area ||
                              props.location.state.searchParams?.areaId
                            }
                            sokoTypeId={
                              props.location.state?.sokoType ||
                              props.location.state.searchParams?.sokoTypeId
                            }
                            contractId={
                              props.location.state?.contract ||
                              props.location.state.searchParams?.contractId
                            }
                            kinoKeitaiId={
                              props.location.state?.kinoKeitai ||
                              props.location.state.searchParams?.KinoKeitaiId
                            }
                            specId={
                              props.location.state?.spec ||
                              props.location.state.searchParams?.specId
                            }
                            taiouGyotaiId={
                              props.location.state?.taiouGyotai ||
                              props.location.state.searchParams?.taiouGyotaiId
                            }
                          />
                        </Grid>
                        <Grid sx={{ width: "100%", marginTop: 2 }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            <FormControl>
                              <InputLabel id="sort">並び順</InputLabel>
                              <Select
                                sx={{ width: matches ? 200 : 120 }}
                                labelId="sort"
                                fullWidth
                                size={matches ? "medium" : "small"}
                                label="並び順"
                                value={sortKey}
                                onChange={(e) => {
                                  sortChange(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  並び順を選択
                                </MenuItem>
                                {sortList.map((item, index) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel id="limit">表示件数</InputLabel>
                              <Select
                                sx={{ width: matches ? 200 : 120 }}
                                labelId="limit"
                                fullWidth
                                label="表示件数"
                                value={count}
                                size={matches ? "medium" : "small"}
                                onChange={(e) => {
                                  limitChange(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  件数を選択
                                </MenuItem>
                                {limitList.map((item, index) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Grid className="storeexpand">
                          <Grid marginTop={3}>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => okiniListDel()}
                            >
                              まとめて削除
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid marginTop={2} sx={{ width: "100%" }}>
                        {/* {isLoading ? (
                          ""
                        ) : ( */}
                        <UserFavoriteResultData
                          searchParams={props.location?.state.searchParams}
                          resultArray={resultArray}
                          favoriteData={favoriteData}
                          favoriteSearch={favoriteSearch}
                          okiniList={okiniList}
                          setOkiniList={setOkiniList}
                        />
                        {/* )} */}
                      </Grid>
                      <Grid sx={{ width: "100%", marginTop: 5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <FormControl>
                            <InputLabel id="sort">並び順</InputLabel>
                            <Select
                              sx={{ width: matches ? 200 : 120 }}
                              labelId="sort"
                              fullWidth
                              size={matches ? "medium" : "small"}
                              label="並び順"
                              value={sortKey}
                              onChange={(e) => {
                                sortChange(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                並び順を選択
                              </MenuItem>
                              {sortList.map((item, index) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel id="limit">表示件数</InputLabel>
                            <Select
                              sx={{ width: matches ? 200 : 120 }}
                              labelId="limit"
                              fullWidth
                              label="表示件数"
                              value={count}
                              size={matches ? "medium" : "small"}
                              onChange={(e) => {
                                limitChange(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                件数を選択
                              </MenuItem>
                              {limitList.map((item, index) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        marginTop={2}
                        marginBottom={2}
                      >
                        <Stack spacing={2}>
                          <Pagination
                            count={pageCount}
                            page={page}
                            onChange={searchNext}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 2 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default UserFavorite;
