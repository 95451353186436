import { useState } from "react";
import "./forms.css";
import { auth } from "./firebase";
import { useHistory, Link } from "react-router-dom";
import {
  getAuth,
  EmailAuthProvider,
  updateEmail,
  reauthenticateWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import AdminMenu from "./AdminMenu";
import Copyright from "./footer";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

const theme = createTheme();

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function AdminChangeEmail() {
  const { currentUser } = useAuthValue();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [updated, setUpdated] = useState(false);
  const history = useHistory();
  //const {setTimeActive} = useAuthValue()

  const move = () => {
    history.push("/admin");
  };

  const validateEmail = () => {
    let isValid = true;
    if (email !== "" && confirmEmail !== "") {
      if (email !== confirmEmail) {
        isValid = false;
        setMessage("メールアドレスが異なります");
      }
    }
    return isValid;
  };
  const changeEmail = (e) => {
    e.preventDefault();
    if (validateEmail()) {
      setError("");
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      console.log(auth.currentUser.email);
      reauthenticateWithCredential(user, credential).then(() => {
        updateEmail(user, email)
          .then(() => {
            const ActionCodeSettiings = {
              url: `${process.env.REACT_APP_SITE_URL}/admin/emailupdated/mail/${email}`,
            };
            sendEmailVerification(user, ActionCodeSettiings);
            setSuccess("入力したメールアドレスに確認メールを送信しました");
            setEmail("");
            setConfirmEmail("");
            setUpdated(true);
          })
          .catch((err) => {
            console.log(err.message);
            err.message === "Firebase: Error (auth/email-already-in-use)."
              ? setError("入力したメールアドレスは既に使用されています")
              : console.log(err.message);
          });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              SENKO-SO-MATCH(登録サイト)
            </Typography>
            <AdminMenu />
          </Toolbar>
        </AppBar>
        <List component="nav" sx={{ mt: 10, width: 240 }}>
          <AdminListItems />
          <Divider sx={{ my: 1 }} />
        </List>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Paper
                  sx={{
                    mt: 2,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid container direction="column" alignItems="center">
                      <Grid
                        sx={{
                          width: "50%",
                          my: 8,
                          mx: 4,
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h5"
                          color="primary"
                          marginTop={3}
                          align="center"
                        >
                          メールアドレスを変更いたします
                        </Typography>
                        <Typography
                          component="h1"
                          variant="subtitle1"
                          color="#000000"
                          marginTop={3}
                          align="center"
                          sx={{ width: "100%" }}
                        >
                          {updated === true ? "" : "ログインIDも変更されます"}
                        </Typography>
                        <Grid container direction="column" alignItems="center">
                          <Box
                            component="form"
                            noValidate
                            sx={{ mt: 1, width: "50%" }}
                            onSubmit={changeEmail}
                            name="login_form"
                          >
                            {success && (
                              <div className="auth__success">{success}</div>
                            )}
                            {error && (
                              <div className="auth__error">{error}</div>
                            )}
                            {email === confirmEmail
                              ? ""
                              : message && (
                                  <div className="auth__error">{message}</div>
                                )}
                            {updated === true ? (
                              ""
                            ) : (
                              <TextField
                                margin="normal"
                                type="email"
                                fullWidth
                                value={email}
                                required
                                label="新しいメールアドレス"
                                placeholder="新しいメールアドレス"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            )}

                            {updated === true ? (
                              ""
                            ) : (
                              <TextField
                                margin="normal"
                                type="email"
                                fullWidth
                                value={confirmEmail}
                                required
                                label="新しいメールアドレス(確認)"
                                placeholder="新しいメールアドレス(確認)"
                                autoFocus
                                onChange={(e) => {
                                  setConfirmEmail(e.target.value);
                                  validateEmail();
                                }}
                              />
                            )}
                            {updated === true ? (
                              ""
                            ) : (
                              <TextField
                                margin="normal"
                                type="password"
                                fullWidth
                                value={password}
                                required
                                label="パスワード"
                                placeholder="パスワードを入力してください。"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            )}
                            {updated === true ? (
                              <Button
                                variant="contained"
                                fullWidth
                                color="success"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={move}
                              >
                                トップページ ＞
                              </Button>
                            ) : email !== "" &&
                              password !== "" &&
                              email === confirmEmail ? (
                              <Button
                                variant="contained"
                                type="submit"
                                fullWidth
                                color="success"
                                sx={{ mt: 3, mb: 2 }}
                              >
                                送信
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                disabled
                                fullWidth
                                color="success"
                                sx={{ mt: 3, mb: 2 }}
                              >
                                送信
                              </Button>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 2 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AdminChangeEmail;
