import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar, jaJP } from "@mui/x-data-grid";
import AdminListItems from "./MenuList";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { useHistory, Link } from "react-router-dom";
import AdminEditUser from "./AdminEditUser";
import dayjs from "dayjs";

const garantList = [
  { id: 0, name: "無効" },
  { id: 1, name: "利用者" },
  { id: 2, name: "倉庫管理者" },
  { id: 3, name: "サイト管理者" },
];

const mdTheme = createTheme();

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const resGetUser = (
  resData,
  companyMList,
  kankatsuShukanShitenMList,
  shitenMList,
  eigyoshoMList
) => {
  const userObjectAarray = [];
  const { result } = resData;
  console.log("res", resData);
  result.forEach((user) => {
    const email = user.email;
    const companyId = user.companyId;
    const kankatsuShukanShitenId = user.kankatsuShukanShitenId;
    const shitenId = user.shitenId;
    const eigyoshoId = user.eigyoshoId;
    const status = user.status;
    const firebaseUuid = user.firebaseUuid;
    const isNotification = user.isNotification;
    const type = user.type;
    const createAt = dayjs(user.reateAt).format("YYYY/MM/DD hh:mm");

    const id = user.userId;

    userObjectAarray.push({
      id: id,
      email: email,
      companyId: companyId,
      companyName: companyMList.find((e) => e.companyId === companyId)?.name,
      kankatsuShukanShitenId: kankatsuShukanShitenId,
      kankatsuShukanShitenName: kankatsuShukanShitenMList.find(
        (e) => e.kankatsuShukanShitenId === kankatsuShukanShitenId
      )?.name,
      shitenId: shitenId,
      shitenName: shitenMList.find((e) => e.shitenId === shitenId)?.name,
      eigyoshoId: eigyoshoId,
      eigyoshoName: eigyoshoMList.find((e) => e.eigyoshoId === eigyoshoId)
        ?.name,
      type: type,
      grant: garantList.find((e) => e.id === type).name,
      status: status,
      firebaseUuid: firebaseUuid,
      isNotification: isNotification,
      createAt: createAt,
    });
  });
  console.log(userObjectAarray);
  return userObjectAarray;
};

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //Authorization: `Bearer ${props.accessToken}`,
};

function AdminUserList(props) {
  const { currentUser, masterData } = useAuthValue();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [flag, setFlag] = useState("0");
  const history = useHistory();

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const handleAlertOpen = (data1) => {
    console.log(data1);
    if (modal === data1) {
      console.log("1");
      setModal("");
    } else {
      console.log("2");
      setModal(data1);
      console.log(data1);
    }
  };

  const handleAlertClose = () => {
    setModal(false);
  };
  const handleGrantChange = (e, data) => {
    e.stopPropagation();
    console.log("e,data,Grant", e, data);
    console.log(modal);
    const userData = {
      firebaseUuid: data.firebaseUuid,
      companyId: data.companyId,
      type: Number(e.target.value),
      kankatsuShukanShitenId: data.kankatsuShukanShitenId,
      shitenId: data.shitenId,
      eigyoshoId: data.eigyoshoId,
      status: data.status,
      email: data.email,
      isNotification: data.isNotification,
      userId: data.id,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}user/update`, userData, {
        headers: headers,
      })
      .then((res) => {
        toast.success(
          () => <Typography variant="h5">更新しました</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        setTimeout(() => {
          getData();
        }, 4000);
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          () => <Typography variant="h5">更新できませんでした</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      });
  };

  const delUser = (e, data1) => {
    console.log("e,data,Del", data1);
    const userData = {
      firebaseUuid: data1.firebaseUuid,
      companyId: data1.companyId,
      type: Number(4),
      kankatsuShukanShitenId: data1.kankatsuShukanShitenId,
      shitenId: data1.shitenId,
      eigyoshoId: data1.eigyoshoId,
      status: Number(2),
      email: data1.email,
      isNotification: data1.isNotification,
      userId: data1.id,
    };
    console.log(userData);
    axios
      .post(`${process.env.REACT_APP_API_URL}user/update`, userData, {
        headers: headers,
      })
      .then((res) => {
        toast.success(
          () => <Typography variant="h5">削除しました</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        setTimeout(() => {
          getData();
        }, 4000);
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          () => <Typography variant="h5">削除できませんでした</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      });
  };

  const moveAdd = () => {
    history.push("/admin/adduser");
  };

  const moveBulk = () => {
    history.push("/admin/bulkadd");
  };

  const flagChange = (data) => {
    setFlag(data);
  };

  const getData = () => {
    const getUsers1 = {
      sortKey: "createAt",
      ascdesc: "desc",
      limit: 9999,
      ofset: 0,
      type: 3,
    };

    const getUsers2 = {
      sortKey: "createAt",
      ascdesc: "desc",
      limit: 9999,
      ofset: 0,
      type: 2,
    };
    const getUsers3 = {
      sortKey: "createAt",
      ascdesc: "desc",
      limit: 9999,
      ofset: 0,
      type: 1,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}user/getUsers`,
        flag === "0" ? getUsers1 : flag === "1" ? getUsers2 : getUsers3,
        { headers: headers }
      )
      .then((response) => {
        const userDataArray = resGetUser(
          response.data,
          companyMList,
          kankatsuShukanShitenMList,
          shitenMList,
          eigyoshoMList
        );
        console.log("users", userDataArray);
        setUserData(userDataArray);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [flag]);

  const columns = [
    {
      field: "email",
      headerName: "メールアドレス",
      width: 250,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "companyName",
      headerName: "会社名",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "kankatsuShukanShitenName",
      headerName: "管轄主管支店名",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "shitenName",
      headerName: "支店名",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "eigyoshoName",
      headerName: "営業所名",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "grant",
      headerName: "権限",
      width: 150,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "isNotification",
      headerName: "権限更新",
      width: 200,
      renderCell: (params) => {
        let grantData = "0";
        return (
          <FormControl>
            <InputLabel id="grant">権限を選択</InputLabel>
            <Select
              labelId="grant"
              fullWidth
              size="small"
              label="権限を選択"
              value={grantData}
              sx={{ width: 150 }}
              onChange={(e) => {
                handleGrantChange(e, params.row);
              }}
            >
              <MenuItem value="0" disabled>
                権限を選択
              </MenuItem>
              <MenuItem value="1">利用者</MenuItem>
              <MenuItem value="2">倉庫管理者</MenuItem>
              <MenuItem value="3">サイト管理者</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "createAt",
      headerName: "登録日時",
      width: 200,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "firebaseUuid",
      headerName: "編集",
      width: 100,
      renderCell: (params) => {
        return <AdminEditUser userData={params.row} />;
      },
    },
    {
      field: "status",
      headerName: "削除",
      width: 100,
      renderCell: (params) => {
        return (
          <Grid>
            <Button
              variant="contained"
              color="error"
              onClick={(e) => {
                delUser(e, params.row);
              }}
            >
              削除
            </Button>
          </Grid>
        );
      },
    },
  ];

  const rows = userData;

  return (
    <>
      {isLoading ? (
        <Grid>
          <p>Loading</p>
        </Grid>
      ) : (
        <ThemeProvider theme={mdTheme}>
          <Toaster position="top-right" />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="absolute" color="error">
              <Toolbar
                sx={{
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  SENKO-SO-MATCH(登録サイト)
                </Typography>
                <AdminMenu />
              </Toolbar>
            </AppBar>
            <List component="nav" sx={{ mt: 10, width: 240 }}>
              <AdminListItems />
              <Divider sx={{ my: 1 }} />
            </List>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />

              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5">倉庫管理者</Typography>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      spacing={3}
                      marginTop={3}
                      marginBottom={2}
                    >
                      <Grid>
                        <Button
                          variant="contained"
                          sx={{ width: 150 }}
                          value="0"
                          onClick={() => {
                            moveAdd();
                          }}
                        >
                          新規登録
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant="contained"
                          sx={{ width: 150 }}
                          value="1"
                          onClick={() => {
                            moveBulk();
                          }}
                        >
                          一括登録
                        </Button>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      spacing={3}
                      marginTop={3}
                      marginBottom={2}
                    >
                      <Grid>
                        <Button
                          variant={flag === "0" ? "contained" : "outlined"}
                          sx={{ width: 150 }}
                          value="0"
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          サイト管理者一覧
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant={flag === "1" ? "contained" : "outlined"}
                          sx={{ width: 150 }}
                          value="1"
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          倉庫管理者一覧
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          variant={flag === "2" ? "contained" : "outlined"}
                          sx={{ width: 150 }}
                          value="2"
                          onClick={(e) => {
                            flagChange(e.target.value);
                          }}
                        >
                          利用者一覧
                        </Button>
                      </Grid>
                    </Stack>
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        sx={{ width: "auto", height: "500px" }}
                      >
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          pageSize={20}
                          rowsPerPageOptions={[20]}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                              printOptions: { disableToolbarButton: true },
                              csvOptions: { disableToolbarButton: true },
                            },
                          }}
                          localeText={
                            jaJP.components.MuiDataGrid.defaultProps.localeText
                          }
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Copyright sx={{ pt: 2 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
}

export default AdminUserList;
