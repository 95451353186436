import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory, Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Pagination from "@mui/material/Pagination";
import Conditions from "./Conditions";
import UserResultData from "./UserResultData";
import UserMenu from "./UserMenu";
import { useMediaQuery } from "@mui/material";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import { jsonToCSV } from "react-papaparse";
import IconButton from "@mui/material/IconButton";
import { PiArrowFatUpFill } from "react-icons/pi";
import dayjs from "dayjs";

import "./App.css";

const limitList = [
  { id: 50, name: "50件を表示" },
  { id: 100, name: "100件を表示" },
  { id: 150, name: "150件を表示" },
];

const sortList = [
  { id: 1, ascdesc: "asc", sort: "updateAt", name: "更新日時が古い順" },
  { id: 2, ascdesc: "desc", sort: "updateAt", name: "更新日時が新しい順" },
  { id: 3, ascdesc: "asc", sort: "availableArea", name: "面積が狭い順" },
  { id: 4, ascdesc: "desc", sort: "availableArea", name: "面積が広い順" },
];

const resFavoriteList = (resData) => {
  const favoriteData = [];
  resData.forEach((okini) => {
    const storageId = okini.storageId;
    favoriteData.push(storageId);
  });
  return favoriteData;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const resStorageData = (resData) => {
  const dataObjectAarray = [];
  const { result } = resData;
  result.map((storage) => {
    const sokoId = storage.sokoId;

    const imageList = [];

    const sokoImg = getStorage();

    const getImg = async (sokoId) => {
      const image = [];
      const listRef = ref(
        sokoImg,
        `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
      );
      const list = await listAll(listRef);

      const urls = await Promise.all(
        list.items.map((ref) => getDownloadURL(ref))
      );

      const decodeUrls = urls.map(
        (item) =>
          `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
          decodeURIComponent(item).split("thumb/")[1].split("?")[0]
      );
      imageList.push(...decodeUrls);
    };

    getImg(sokoId);

    const storageId = storage.storageId;
    const availableArea = storage.availableArea;
    const availableDate = storage.availableDate;
    const availableDay = storage.availableDay;
    const availableMonth = storage.availableMonth;
    const availableYear = storage.availableYear;
    const commonFee = storage.commonFee;
    const contractId = storage.contractId;
    const storageDescription = storage.description;
    const floorId = storage.floorId;
    const negotiable = storage.negotiable;
    const soon = storage.soon;
    const hikiai = soon === 1 ? "有り" : "無し";
    const unitPrice = storage.unitPrice;

    const sokoName = storage["soko.sokoName"];
    const prefecture = storage["soko.prefectureId"][0];
    const area = storage["soko.areaId"];
    const city = storage["soko.city"];
    const street = storage["soko.street"];
    const sokoType = storage["soko.sokoTypeId"];
    const companyId = storage["soko.companyId"];
    const kankatsuShukanShitenId = storage["soko.kankatsuShukanShitenId"];
    const shitenId = storage["soko.shitenId"];
    const kankatsuEigyobuId = storage["soko.kankatsuEigyobuId"];
    const eigyoshoId = storage["soko.eigyoshoId"];
    const tantouName = storage["soko.tantouName"];
    const tel = storage["soko.tel"];
    const accessByCar = storage["soko.accessByCar"];
    const accessByTrain = storage["soko.accessByTrain"];
    const completionYear = storage["soko.completionYear"];
    const completionMonth = storage["soko.completionMonth"];
    const kinoKeitaiId = storage["soko.kinoKeitaiId"];
    const specId = storage["soko.specId"];
    const taiouGyotaiId = storage["soko.taiouGyotaiId"];
    const sokoDescription = storage["soko.description"];
    const status = storage.status === 1 ? "公開中" : "非公開";
    const sokoStatus = storage["soko.status"];
    const sokoEtc = storage["soko.etc"];
    const updateAt = dayjs(storage.updateAt).format("YYYY/MM/DD hh:mm");
    const createAt = dayjs(storage.createAt).format("YYYY/MM/DD hh:mm");
    dataObjectAarray.push({
      image: imageList,
      storageId: storageId,
      sokoId: sokoId,
      sokoName: sokoName,
      prefectureId: prefecture,
      area: area,
      city: city,
      street: street,
      sokoTypeId: sokoType,
      companyId: companyId,
      kankatsuShukanShitenId: kankatsuShukanShitenId,
      shitenId: shitenId,
      eigyoshoId: eigyoshoId,
      kankatsuEigyobuId: kankatsuEigyobuId,
      tantouName: tantouName,
      tel: tel,
      accessByCar: accessByCar,
      accessByTrain: accessByTrain,
      completionYear: completionYear,
      completionMonth: completionMonth,
      kinoKeitaiId: kinoKeitaiId,
      specId: specId,
      taiouGyotaiId: taiouGyotaiId,
      availableArea: availableArea,
      availableDate: availableDate,
      availableDay: availableDay,
      availableMonth: availableMonth,
      availableYear: availableYear,
      commonFee: commonFee,
      contractId: contractId,
      storageDescription: storageDescription,
      sokoDescription: sokoDescription,
      sokoEtc: sokoEtc,
      floorId: floorId,
      negotiable: negotiable,
      soon: soon,
      hikiai: hikiai,
      unitPrice: unitPrice,
      status: status,
      sokoStatus: sokoStatus,
      updateAt: updateAt,
      createAt: createAt,
    });
  });

  return dataObjectAarray;
};

const mdTheme = createTheme();

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

function UserSearchResult(props) {
  const { currentUser, userInfo, masterData, totalCount, newCount } =
    useAuthValue();

  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [resultCount, setResultCount] = useState();
  const [resultArray, setResultArray] = useState();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState("");
  const [search2Params, setSearch2Params] = useState("");
  const [searchUse, setSearchUse] = useState(false);
  const [count, setCount] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [csvCount, setCsvCount] = useState("");
  const [prefecture, setPrefecture] = useState([]);
  const [favoriteData, setFavoriteData] = useState([]);
  const [favoriteData1, setFavoriteData1] = useState([]);
  const [allAllay, setAllArray] = useState([]);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList;
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const box1 = useRef(null);

  const scroll = (box) => {
    box.current.scrollIntoView("smooth");
  };

  const resStorageData1 = (resData) => {
    const dataObjectAarray = [];
    const { result } = resData;
    scroll(box1);
    result.map((storage) => {
      const sokoId = storage.sokoId;

      const imageList = [];

      const sokoImg = getStorage();

      const getImg = async (sokoId) => {
        const image = [];
        const listRef = ref(
          sokoImg,
          `${process.env.REACT_APP_GS_URL}soko/${sokoId}/thumb`
        );
        const list = await listAll(listRef);

        const urls = await Promise.all(
          list.items.map((ref) => getDownloadURL(ref))
        );

        const decodeUrls = urls.map(
          (item) =>
            `${process.env.REACT_APP_IMG_URL}soko/${sokoId}/thumb/` +
            decodeURIComponent(item).split("thumb/")[1].split("?")[0]
        );
        imageList.push(...decodeUrls);
      };

      getImg(sokoId);

      const storageId = storage.storageId;
      const availableArea = storage.availableArea;
      const availableDate = storage.availableDate;
      const availableDay = storage.availableDay;
      const availableMonth = storage.availableMonth;
      const availableYear = storage.availableYear;
      const commonFee = storage.commonFee;
      const contractId = storage.contractId;
      const storageDescription = storage.description;
      const floorId = storage.floorId;
      const negotiable = storage.negotiable;
      const soon = storage.soon;
      const hikiai = soon === 1 ? "有り" : "無し";
      const unitPrice = storage.unitPrice;

      const sokoName = storage["soko.sokoName"];
      const prefecture = storage["soko.prefectureId"][0];
      const area = storage["soko.areaId"];
      const city = storage["soko.city"];
      const street = storage["soko.street"];
      const sokoType = storage["soko.sokoTypeId"];
      const companyId = storage["soko.companyId"];
      const kankatsuShukanShitenId = storage["soko.kankatsuShukanShitenId"];
      const shitenId = storage["soko.shitenId"];
      const kankatsuEigyobuId = storage["soko.kankatsuEigyobuId"];
      const eigyoshoId = storage["soko.eigyoshoId"];
      const tantouName = storage["soko.tantouName"];
      const tel = storage["soko.tel"];
      const accessByCar = storage["soko.accessByCar"];
      const accessByTrain = storage["soko.accessByTrain"];
      const completionYear = storage["soko.completionYear"];
      const completionMonth = storage["soko.completionMonth"];
      const kinoKeitaiId = storage["soko.kinoKeitaiId"];
      const specId = storage["soko.specId"];
      const taiouGyotaiId = storage["soko.taiouGyotaiId"];
      const sokoDescription = storage["soko.description"];
      const status = storage.status === 1 ? "公開中" : "非公開";
      const sokoStatus = storage["soko.status"];
      const sokoEtc = storage["soko.etc"];
      const updateAt = dayjs(storage.updateAt).format("YYYY/MM/DD hh:mm");
      const createAt = dayjs(storage.createAt).format("YYYY/MM/DD hh:mm");

      dataObjectAarray.push({
        image: imageList,
        storageId: storageId,
        sokoId: sokoId,
        sokoName: sokoName,
        prefectureId: prefecture,
        area: area,
        city: city,
        street: street,
        sokoTypeId: sokoType,
        companyId: companyId,
        kankatsuShukanShitenId: kankatsuShukanShitenId,
        shitenId: shitenId,
        eigyoshoId: eigyoshoId,
        kankatsuEigyobuId: kankatsuEigyobuId,
        tantouName: tantouName,
        tel: tel,
        accessByCar: accessByCar,
        accessByTrain: accessByTrain,
        completionYear: completionYear,
        completionMonth: completionMonth,
        kinoKeitaiId: kinoKeitaiId,
        specId: specId,
        taiouGyotaiId: taiouGyotaiId,
        availableArea: availableArea,
        availableDate: availableDate,
        availableDay: availableDay,
        availableMonth: availableMonth,
        availableYear: availableYear,
        commonFee: commonFee,
        contractId: contractId,
        storageDescription: storageDescription,
        sokoDescription: sokoDescription,
        sokoEtc: sokoEtc,
        floorId: floorId,
        negotiable: negotiable,
        soon: soon,
        hikiai: hikiai,
        unitPrice: unitPrice,
        status: status,
        sokoStatus: sokoStatus,
        updateAt: updateAt,
        createAt: createAt,
      });
    });

    return dataObjectAarray;
  };

  const area =
    search2Params?.areaId === undefined
      ? props.location.state.searchParams?.areaId ?? ""
      : search2Params?.areaId;

  const areaId = [...area];

  const pre =
    search2Params?.prefectureId === undefined
      ? props.location.state.searchParams?.prefectureId ?? ""
      : search2Params?.prefectureId ?? "";

  const prefectureId = [...pre] ?? [];
  const limit =
    props.location.state.searchParams?.limit ||
    props.location.state.searchKeyword?.limit;

  const getOkini = () => {
    setFavoriteData([]);
    setFavoriteData1([]);
    const userFavorite = {
      userId: userInfo?.userId,
      sortKey: "updateAt",
      ascdesc: "desc",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}storage/getLike`, userFavorite, {
        headers: headers,
      })
      .then((response) => {
        const data = response.data.result;
        const data1 = data.filter((e) => e.status === 1);
        const data2 = resFavoriteList(data1);
        const set = new Set(data2);
        setFavoriteData(data);
        setFavoriteData1([...set]);
      });
  };
  const search = () => {
    console.log("1");
    setIsLoading1(true);
    setSearchParams(
      props.location.state?.searchKeyword !== undefined
        ? props.location.state.searchKeyword
        : props.location.state.searchParams
    );
    setCount(
      props.location.state?.searchKeyword?.limit ||
        props.location.state.searchParams.limit
    );
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2Count`
          : `${process.env.REACT_APP_API_URL}storage/search7count`,
        search2Params !== ""
          ? Object.assign(search2Params, { areaId: "" })
          : props.location.state?.searchKeyword !== undefined
          ? props.location.state.searchKeyword
          : Object.assign(props.location.state.searchParams, { areaId: "" }),
        { headers: headers }
      )
      .then((res) => {
        setResultCount(res.data.result[0]["count(*)"]);
      });
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2`
          : `${process.env.REACT_APP_API_URL}storage/search7`,
        search2Params !== ""
          ? Object.assign(search2Params, { areaId: "" })
          : props.location.state?.searchKeyword !== undefined
          ? props.location.state.searchKeyword
          : Object.assign(props.location.state.searchParams, { areaId: "" }),
        { headers: headers }
      )
      .then((res) => {
        const dataArray = resStorageData(res.data);
        setResultArray(dataArray);
        setIsLoading1(false);
      })
      .catch((err) => {
        switch (err.response) {
          case "keyward.substr is not a function":
            setMessage("キーワードが入力されていません");
            break;
          default:
            setMessage("エラーが発生しました");
            break;
        }
      })
      .then(() => setIsLoading(false));
  };

  const search2 = async (params) => {
    const params1 = Object.assign(params, { areaId: "" });
    console.log("2");
    setSearchUse(true);
    setSearch2Params(params);
    setCount(params.keyward?.limit || params.limit);
    axios
      .post(
        params.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2Count`
          : `${process.env.REACT_APP_API_URL}storage/search7count`,
        params1,
        { headers: headers }
      )
      .then((res) => {
        setResultCount(res.data.result[0]["count(*)"]);
      });
    axios
      .post(
        params.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2`
          : `${process.env.REACT_APP_API_URL}storage/search7`,
        params1,
        { headers: headers }
      )
      .then((res) => {
        const dataArray = resStorageData(res.data);
        setIsLoading1(false);
        setResultArray(dataArray);
      })
      .catch((err) => {
        switch (err.response.data) {
          case "keyward.substr is not a function":
            setMessage("キーワードが入力されていません");
            break;
          default:
            setMessage("エラーが発生しました");
            break;
        }
      });
  };

  const sortChange = (item) => {
    console.log("s");
    setSortKey(item);
    const key = sortList.find((e) => e.id === item).sort;
    const ascdesc = sortList.find((e) => e.id === item).ascdesc;
    const params =
      searchUse === true
        ? Object.assign(search2Params, {
            sortKey: key,
            ascdesc: ascdesc,
            areaId: "",
          })
        : props.location.state?.searchKeyword !== undefined
        ? Object.assign(props.location.state.searchKeyword, {
            sortKey: key,
            ascdesc: ascdesc,
          })
        : Object.assign(props.location.state.searchParams, {
            sortKey: key,
            ascdesc: ascdesc,
            areaId: "",
          });

    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2Count`
          : `${process.env.REACT_APP_API_URL}storage/search7count`,
        params,
        { headers: headers }
      )
      .then((res) => {
        setResultCount(res.data.result[0]["count(*)"]);
      });
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2`
          : `${process.env.REACT_APP_API_URL}storage/search7`,
        params,
        { headers: headers }
      )
      .then((res) => {
        const dataArray = resStorageData1(res.data);
        setResultArray(dataArray);
      });
  };

  const allDownload = () => {
    console.log("download");
    const params =
      searchUse === true
        ? Object.assign(search2Params, { limit: 9999, areaId: "" })
        : props.location.state?.searchKeyword !== undefined
        ? Object.assign(props.location.state.searchKeyword, {
            limit: 9999,
          })
        : Object.assign(props.location.state.searchParams, {
            limit: 9999,
            areaId: "",
          });
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2`
          : `${process.env.REACT_APP_API_URL}storage/search7`,
        params,
        { headers: headers }
      )
      .then((res) => {
        const dataArray = resStorageData1(res.data);
        console.log(dataArray);
        setAllArray(dataArray);
      });
  };

  const searchNext = (event, value) => {
    scroll(box1);
    console.log("n");
    setPage(value);
    const params =
      searchUse === true
        ? Object.assign(search2Params, {
            offset: value * limit - limit,
            areaId: "",
          })
        : props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
        ? Object.assign(props.location.state.searchKeyword, {
            offset: value * limit - limit,
          })
        : Object.assign(props.location.state.searchParams, {
            offset: value * limit - limit,
            areaId: "",
          });
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2`
          : `${process.env.REACT_APP_API_URL}storage/search7`,
        params,
        { headers: headers }
      )
      .then((res) => {
        const dataArray = resStorageData(res.data);
        setResultArray(dataArray);
      });
  };

  const scrollTop = (event, value) => {
    scroll(box1);
  };

  const limitChange = (item) => {
    console.log("l");
    setCount(item);
    const limit = limitList.find((e) => e.id === item).id;
    const params =
      searchUse === true
        ? Object.assign(search2Params, { limit: limit, areaId: "" })
        : props.location.state?.searchKeyword !== undefined
        ? Object.assign(props.location.state.searchKeyword, { limit: limit })
        : Object.assign(props.location.state.searchParams, {
            limit: limit,
            areaId: "",
          });
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2Count`
          : `${process.env.REACT_APP_API_URL}storage/search7count`,
        params,
        { headers: headers }
      )
      .then((res) => {
        setResultCount(res.data.result[0]["count(*)"]);
      });
    axios
      .post(
        props.location.state?.searchKeyword !== undefined ||
          props.location.state?.searchParams.keyward !== undefined
          ? `${process.env.REACT_APP_API_URL}storage/searchKeyward2`
          : `${process.env.REACT_APP_API_URL}storage/search7`,
        params,
        { headers: headers }
      )
      .then((res) => {
        const dataArray = resStorageData1(res.data);
        setResultArray(dataArray);
      });
  };

  const convartCsvNow = () => {
    //const csvData = csvCount === "now" ? resultArray : "";
    const convartData = [];
    resultArray.map((item) => {
      const image = item.image;
      const sokoName = item.sokoName;
      const prefecture = prefectureMList.find(
        (e) => e.prefectureId === item.prefectureId
      ).name;
      const area = areaMList.find((e) => e.areaId === item.area[0]).name;
      const city = item.city;
      const street = item.street;
      const sokoTypeId = item.sokoTypeId.map(
        (el) => sokoTypeMList.find((e) => e.sokoTypeId === el).name
      );
      const companyId = companyMList.find(
        (e) => e.companyId === item.companyId
      ).name;
      const kankatsuShukanShitenId = kankatsuShukanShitenMList.find(
        (e) => e.kankatsuShukanShitenId === item.kankatsuShukanShitenId
      ).name;
      const shitenId = shitenMList.find(
        (e) => e.shitenId === item.shitenId
      ).name;
      const eigyoshoId = eigyoshoMList.find(
        (e) => e.eigyoshoId === item.eigyoshoId
      ).name;
      const kankatsuEigyobuId = kankatsuEigyobuMList.find(
        (e) => e.kankatsuEigyobuId === item.kankatsuEigyobu
      ).name;
      const tantouName = item.tantouName;
      const tel = item.tel;
      const accessByCar = item.accessByCar;
      const accessByTrain = item.accessByTrain;
      const completionYear = item.completionYear;
      const completionMonth = item.completionMonth;
      const kinoKeitaiId =
        item.kinoKeitaiId.length === 0
          ? ""
          : item.kinoKeitaiId.length === 1
          ? kinoKeitaiMList.find((e) => e.kinoKeitaiId === item.kinoKeitaiId[0])
              .name
          : item.kinoKeitaiId.map(
              (el) => kinoKeitaiMList.find((e) => e.kinoKeitaiId === el).name
            );
      const specId =
        item.specId.length === 1
          ? specMList.find((e) => e.specId === item.specId[0]).name
          : item.specId.map(
              (el) => specMList.find((e) => e.specId === el).name
            );
      const taiouGyotaiId =
        item.taiouGyotaiId.length === 0
          ? ""
          : item.taiouGyotaiId.length === 1
          ? taiouGyotaiMList.find(
              (e) => e.taiouGyotaiId === item.taiouGyotaiId[0]
            ).name
          : item.taiouGyotaiId.map(
              (el) => taiouGyotaiMList.find((e) => e.taiouGyotaiId === el).name
            );
      const availableArea = item.availableArea;
      const availableDate = item.availableDate;
      const availableDay = item.availableDay;
      const availableMonth = item.availableMonth;
      const availableYear = item.availableYear;
      const commonFee = item.commonFee;
      const contractId = item.contractId.map(
        (el) => contractMList.find((e) => e.contractId === el).name
      );
      const storageDescription = item.storageDescription;
      const sokoDescription = item.sokoDescription;
      const sokoEtc = item.sokoEtc;
      const negotiable = item.negotiable === 0 ? "" : "坪単価応相談";
      const hikiai = item.hikiai;
      const unitPrice = item.unitPrice;
      const status =
        item.status === 0 || item.status === "0" ? "非公開" : "公開";
      const updateAt = item.updateAt;
      const createAt = item.createAt;
      convartData.push({
        画像: image,
        倉庫名: sokoName,
        倉庫種別: sokoTypeId,
        会社名: companyId,
        管轄主管支店: kankatsuShukanShitenId,
        支店: shitenId,
        営業所: eigyoshoId,
        担当者: tantouName,
        電話番号: tel,
        管轄営業部: kankatsuEigyobuId,
        契約期間: contractId,
        入庫可能日:
          availableYear + "年" + availableMonth + "月" + availableDay + "日",
        利用面積: availableArea + "坪",
        坪単価: unitPrice + "円/坪" + " " + negotiable,
        共益費: commonFee + "円",
        希望業態: taiouGyotaiId,
        "機能・形態": kinoKeitaiId,
        倉庫スペック: specId + " " + sokoEtc,
        竣工: completionYear + "年" + completionMonth + "日",
        "交通（鉄道・電車）": accessByTrain,
        "交通（クルマ）": accessByCar,
        地域: area,
        都道府県: prefecture,
        市区町村: city,
        以降の住所: street,
        倉庫備考: sokoDescription,
        空坪備考: storageDescription,
        引合: hikiai,
        公開状況: status,
        登録日: createAt,
        更新日: updateAt,
      });
    });
    const csvData = convartData;
    const csv = jsonToCSV(csvData);
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "export.csv";
    link.click();
    link.remove();
  };

  const convartCsvAll = () => {
    allDownload();
    const convartData = [];
    if (allAllay !== undefined && allAllay.length !== 0) {
      allAllay.map((item) => {
        const image = item.image;
        const sokoName = item.sokoName;
        const prefecture = prefectureMList.find(
          (e) => e.prefectureId === item.prefectureId
        ).name;
        const area = areaMList.find((e) => e.areaId === item.area[0]).name;
        const city = item.city;
        const street = item.street;
        const sokoTypeId = item.sokoTypeId.map(
          (el) => sokoTypeMList.find((e) => e.sokoTypeId === el).name
        );
        const companyId = companyMList.find(
          (e) => e.companyId === item.companyId
        ).name;
        const kankatsuShukanShitenId = kankatsuShukanShitenMList.find(
          (e) => e.kankatsuShukanShitenId === item.kankatsuShukanShitenId
        ).name;
        const shitenId = shitenMList.find(
          (e) => e.shitenId === item.shitenId
        ).name;
        const eigyoshoId = eigyoshoMList.find(
          (e) => e.eigyoshoId === item.eigyoshoId
        ).name;
        const kankatsuEigyobuId = kankatsuEigyobuMList.find(
          (e) => e.kankatsuEigyobuId === item.kankatsuEigyobu
        ).name;
        const tantouName = item.tantouName;
        const tel = item.tel;
        const accessByCar = item.accessByCar;
        const accessByTrain = item.accessByTrain;
        const completionYear = item.completionYear;
        const completionMonth = item.completionMonth;
        const kinoKeitaiId =
          item.kinoKeitaiId.length === 0
            ? ""
            : item.kinoKeitaiId.length === 1
            ? kinoKeitaiMList.find(
                (e) => e.kinoKeitaiId === item.kinoKeitaiId[0]
              ).name
            : item.kinoKeitaiId.map(
                (el) => kinoKeitaiMList.find((e) => e.kinoKeitaiId === el).name
              );
        const specId =
          item.specId.length === 1
            ? specMList.find((e) => e.specId === item.specId[0]).name
            : item.specId.map(
                (el) => specMList.find((e) => e.specId === el).name
              );
        const taiouGyotaiId =
          item.taiouGyotaiId.length === 0
            ? ""
            : item.taiouGyotaiId.length === 1
            ? taiouGyotaiMList.find(
                (e) => e.taiouGyotaiId === item.taiouGyotaiId[0]
              ).name
            : item.taiouGyotaiId.map(
                (el) =>
                  taiouGyotaiMList.find((e) => e.taiouGyotaiId === el).name
              );
        const availableArea = item.availableArea;
        const availableDate = item.availableDate;
        const availableDay = item.availableDay;
        const availableMonth = item.availableMonth;
        const availableYear = item.availableYear;
        const commonFee = item.commonFee;
        const contractId = item.contractId.map(
          (el) => contractMList.find((e) => e.contractId === el).name
        );
        const storageDescription = item.storageDescription;
        const sokoDescription = item.sokoDescription;
        const sokoEtc = item.sokoEtc;
        const negotiable = item.negotiable === 0 ? "" : "坪単価応相談";
        const hikiai = item.hikiai;
        const unitPrice = item.unitPrice;
        const status =
          item.status === 0 || item.status === "0" ? "非公開" : "公開";
        const updateAt = item.updateAt;
        const createAt = item.createAt;
        convartData.push({
          画像: image,
          倉庫名: sokoName,
          倉庫種別: sokoTypeId,
          会社名: companyId,
          管轄主管支店: kankatsuShukanShitenId,
          支店: shitenId,
          営業所: eigyoshoId,
          担当者: tantouName,
          電話番号: tel,
          管轄営業部: kankatsuEigyobuId,
          契約期間: contractId,
          入庫可能日:
            availableYear + "年" + availableMonth + "月" + availableDay + "日",
          利用面積: availableArea + "坪",
          坪単価: unitPrice + "円/坪" + " " + negotiable,
          共益費: commonFee + "円",
          希望業態: taiouGyotaiId,
          "機能・形態": kinoKeitaiId,
          倉庫スペック: specId + " " + sokoEtc,
          竣工: completionYear + "年" + completionMonth + "日",
          "交通（鉄道・電車）": accessByTrain,
          "交通（クルマ）": accessByCar,
          地域: area,
          都道府県: prefecture,
          市区町村: city,
          以降の住所: street,
          倉庫備考: sokoDescription,
          空坪備考: storageDescription,
          引合: hikiai,
          公開状況: status,
          登録日: createAt,
          更新日: updateAt,
        });
      });
      const csvData = convartData;
      const csv = jsonToCSV(csvData);
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "export.csv";
      link.click();
      link.remove();
    }
  };

  const downloadCSV = (id) => {
    console.log("id", id);
    if (id === "now") {
      convartCsvNow();
    } else if (id === "all") {
      convartCsvAll();
    }
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: props.location.state.searchParams,
      },
    });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  useEffect(() => {
    search();
    getOkini();
  }, []);

  const pageCount = Math.ceil(resultCount / count);

  return (
    <>
      {isLoading ? (
        <Grid>
          <Typography>loading...</Typography>
        </Grid>
      ) : (
        <ThemeProvider theme={mdTheme}>
          <Grid className="scrollbutton">
            <IconButton
              sx={{ width: "3rem", height: "3rem" }}
              onClick={scrollTop}
            >
              <PiArrowFatUpFill size={"3rem"} color="#FFFFFF" />
            </IconButton>
          </Grid>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="absolute">
              <Toolbar
                sx={{
                  pr: "24px", // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className="pointer"
                  sx={{ display: "block", marginRight: 5 }}
                  onClick={() => moveTop()}
                >
                  {matches ? "SENKO-SO-MATCH" : "SSM"}
                </Typography>
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    登録
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    ccolor="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    {totalCount}
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block", marginRight: 3 }}
                  >
                    棟
                  </Typography>
                ) : (
                  ""
                )}

                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    新着
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block" }}
                  >
                    {newCount}
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ display: "block", flexGrow: 1 }}
                  >
                    棟
                  </Typography>
                ) : (
                  ""
                )}
                {matches ? (
                  <Button onClick={() => moveTop()}>
                    <Typography color="#FFFFFF">ホーム</Typography>
                  </Button>
                ) : (
                  ""
                )}
                {currentUser?.email !== undefined && matches ? (
                  <Button onClick={() => moveFavorite()}>
                    <Typography color="#FFFFFF">お気に入り</Typography>
                  </Button>
                ) : (
                  ""
                )}
                {currentUser?.email !== undefined ? (
                  <UserMenu />
                ) : (
                  <Button
                    sx={{ marginLeft: matches ? 0 : 15 }}
                    component={Link}
                    to="/login"
                    variant="outlined"
                    color="inherit"
                  >
                    <Typography color="#FFFFFF">ログイン</Typography>
                  </Button>
                )}
              </Toolbar>
            </AppBar>

            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar ref={box1} />

              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container>
                        <Stack direction="row" alignItems="center">
                          <Typography
                            variant="subtitle1"
                            marginRight={1}
                            onClick={() => moveTop()}
                          >
                            TOP
                          </Typography>
                          <Typography variant="subtitle1" marginRight={1}>
                            {">"}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            marginRight={1}
                            id="top"
                          >
                            倉庫検索結果
                          </Typography>
                        </Stack>
                        <Grid container direction="column" alignItems="center">
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            {areaId === "" || areaId.length === 8
                              ? ""
                              : areaId.map((item, index) => (
                                  <Typography variant="h4" key={index}>
                                    {
                                      areaMList.find((e) => e.areaId === item)
                                        ?.name
                                    }
                                  </Typography>
                                ))}
                            {prefectureId === "" || prefectureId.length === 47
                              ? ""
                              : prefectureId.map((item, index) => (
                                  <Typography
                                    variant={matches ? "h4" : "h5"}
                                    key={index}
                                  >
                                    {
                                      prefectureMList.find(
                                        (e) => e.prefectureId === Number(item)
                                      )?.name
                                    }
                                  </Typography>
                                ))}
                            {(areaId.length === 0 &&
                              prefectureId.length === 0) ||
                            (areaId.length === 8 &&
                              prefectureId.length === 47) ? (
                              <Typography variant={matches ? "h4" : "h5"}>
                                全国
                              </Typography>
                            ) : (
                              ""
                            )}

                            <Typography variant={matches ? "h4" : "h5"}>
                              {" "}
                              <section id="top">の倉庫一覧</section>
                            </Typography>
                          </Stack>
                          <Typography variant="h6">
                            対象物件{resultCount}件
                          </Typography>
                        </Grid>
                        <Grid sx={{ width: "100%", marginTop: 3 }}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Conditions
                              searchKeyword={
                                props.location.state?.searchKeyword
                              }
                              searchParams={props.location.state?.searchParams}
                              search2Params={search2Params}
                              prefecture={
                                props.location.state?.prefecture ||
                                props.location.state.searchParams?.prefectureId
                              }
                              area={
                                props.location.state?.area ||
                                props.location.state.searchParams?.areaId
                              }
                              search={search2}
                              sokoTypeId={
                                props.location.state?.sokoType ||
                                props.location.state.searchParams?.sokoTypeId
                              }
                              contractId={
                                props.location.state?.contract ||
                                props.location.state.searchParams?.contractId
                              }
                              kinoKeitaiId={
                                props.location.state?.kinoKeitai ||
                                props.location.state.searchParams?.KinoKeitaiId
                              }
                              specId={
                                props.location.state?.spec ||
                                props.location.state.searchParams?.specId
                              }
                              taiouGyotaiId={
                                props.location.state?.taiouGyotai ||
                                props.location.state.searchParams?.taiouGyotaiId
                              }
                              setSearch2Params={setSearch2Params}
                            />
                          </Grid>
                          <Grid sx={{ width: "100%", marginTop: 2 }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              spacing={1}
                            >
                              <FormControl>
                                <InputLabel id="sort">並び順</InputLabel>
                                <Select
                                  sx={{ width: matches ? 200 : 120 }}
                                  labelId="sort"
                                  fullWidth
                                  size={matches ? "medium" : "small"}
                                  label="並び順"
                                  value={sortKey}
                                  onChange={(e) => {
                                    sortChange(e.target.value);
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    並び順を選択
                                  </MenuItem>
                                  {sortList.map((item, index) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl>
                                <InputLabel id="limit">表示件数</InputLabel>
                                <Select
                                  sx={{ width: matches ? 200 : 120 }}
                                  labelId="limit"
                                  fullWidth
                                  label="表示件数"
                                  value={count}
                                  size={matches ? "medium" : "small"}
                                  onChange={(e) => {
                                    limitChange(e.target.value);
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    件数を選択
                                  </MenuItem>
                                  {limitList.map((item, index) => {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid marginTop={2} sx={{ width: "100%" }}>
                          {isLoading1 ? (
                            ""
                          ) : (
                            <UserResultData
                              searchParams={searchParams}
                              search2Params={search2Params}
                              resultArray={resultArray}
                            />
                          )}
                        </Grid>
                        <Grid sx={{ width: "100%", marginTop: 5 }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            <FormControl>
                              <InputLabel id="sort">並び順</InputLabel>
                              <Select
                                sx={{ width: matches ? 200 : 120 }}
                                labelId="sort"
                                fullWidth
                                size={matches ? "medium" : "small"}
                                label="並び順"
                                value={sortKey}
                                onChange={(e) => {
                                  sortChange(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  並び順を選択
                                </MenuItem>
                                {sortList.map((item, index) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel id="limit">表示件数</InputLabel>
                              <Select
                                sx={{ width: matches ? 200 : 120 }}
                                labelId="limit"
                                fullWidth
                                label="表示件数"
                                value={count}
                                size={matches ? "medium" : "small"}
                                onChange={(e) => {
                                  limitChange(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  件数を選択
                                </MenuItem>
                                {limitList.map((item, index) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Stack>

                          <Grid className="storeexpand" marginTop={2}>
                            <FormControl>
                              <InputLabel id="limit">
                                データをダウンロードする
                              </InputLabel>
                              <Select
                                sx={{ width: matches ? 200 : 120 }}
                                labelId="limit"
                                fullWidth
                                label="表示件数"
                                size={matches ? "medium" : "small"}
                                value={csvCount}
                                onChange={(e) => {
                                  downloadCSV(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  選択
                                </MenuItem>
                                <MenuItem key="now" value="now">
                                  現在の表示件数
                                </MenuItem>
                                <MenuItem key="all" value="all">
                                  全件
                                </MenuItem>
                              </Select>
                            </FormControl>
                            {/* <Grid marginTop={2}>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  convartCSV();
                                }}
                              >
                                CSVダウンロード
                              </Button>
                            </Grid> */}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          marginTop={2}
                          marginBottom={2}
                        >
                          <Stack spacing={2}>
                            <Pagination
                              count={pageCount}
                              page={page}
                              onChange={searchNext}
                              variant="outlined"
                              shape="rounded"
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <Copyright sx={{ pt: 2 }} />
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
}

export default UserSearchResult;
