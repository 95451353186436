import React from "react";
import { useState, useEffect } from "react";
import Iframe from "react-iframe";
import Typography from "@mui/material/Typography";

function Map(props) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Iframe
        id="page1"
        url={`https://www.google.com/maps/embed/v1/place?key=${
          process.env.REACT_APP_FIREBASE_API_KEY
        }&q=${
          props.address === "" ? "東京都江東区潮見" : props.address
        }&zoom=12`}
        position="relative"
        width="100%"
        height="95%"
      />
      <Typography
        variant="subtitle2"
        marginTop={-0.5}
        sx={{
          "@media print": {
            fontSize: 12,
          },
        }}
      >
        ※ピンは正しい位置に打たれていない事があります。
      </Typography>
    </>
  );
}

export default Map;
