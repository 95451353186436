import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory, Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Pagination from "@mui/material/Pagination";
import Conditions from "./Conditions";
import UserMenu from "./UserMenu";
import UserFavoriteResultData from "./UserFavoriteResultData";
import { useMediaQuery } from "@mui/material";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import dayjs from "dayjs";

const date = new Date();

const oneYearAfter = dayjs(date).add(1, "y").format("YYYY/MM/DD hh:mm:ss");

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme();

function UserSetting(props) {
  const {
    currentUser,
    userInfo,
    masterData,
    totalCount,
    newCount,
    favoriteData,
  } = useAuthValue();
  const [isLoading, setIsLoading] = useState(true);
  const [isNotification, setIsNotification] = useState(
    Number(userInfo.isNotification)
  );
  const matches = useMediaQuery("(min-width:1050px)");
  const history = useHistory();

  const searchParams = {
    startAt: "2022/01/01 00:00:00",
    endAt: oneYearAfter,
    sortKey: "createAt",
    ascdesc: "asc",
    limit: 50,
    offset: 0,
    status: ["1"],
    areaId: "",
    shitenId: "",
    prefectureId: "",
    availableArea: "",
    sokoTypeId: "",
    companyId: "",
    contractId: "",
    kankatsuShukanShitenId: "",
    kankatsuEigyobuId: "",
    availableYear: "",
    availableMonth: "",
    kinoKeitaiId: "",
    specId: "",
    taiouGyotaiId: "",
  };

  const changeCheckBox = (value) => {
    if (isNotification === 1) {
      setIsNotification(0);
    } else if (isNotification === 0) {
      setIsNotification(1);
    }
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: searchParams,
      },
    });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  const update = () => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const userData = {
      userId: userInfo.userId,
      companyId: userInfo.companyId,
      type: userInfo.type,
      kankatsuShukanShitenId: userInfo.kankatsuShukanShitenId,
      shitenId: userInfo.shitenId,
      eigyoshoId: userInfo.eigyoshoId,
      status: userInfo.status,
      email: userInfo.email,
      isNotification: Number(isNotification),
      firebaseUuid: userInfo.firebaseUuid,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}user/update`, userData, {
        headers: headers,
      })
      .then(function (res) {
        toast.success(
          () => <Typography variant="h5">設定しました</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      })
      .catch(function (err) {
        toast.error(
          () => <Typography variant="h5">設定できませんでした</Typography>,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      });
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Toaster position="top-right" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className="pointer"
                sx={{ display: "block", marginRight: 5 }}
                onClick={() => moveTop()}
              >
                {matches ? "SENKO-SO-MATCH" : "SSM"}
              </Typography>
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  登録
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  ccolor="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  {totalCount}
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block", marginRight: 3 }}
                >
                  棟
                </Typography>
              ) : (
                ""
              )}

              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  新着
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block" }}
                >
                  {newCount}
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ display: "block", flexGrow: 1 }}
                >
                  棟
                </Typography>
              ) : (
                ""
              )}
              {matches ? (
                <Button onClick={() => moveTop()}>
                  <Typography color="#FFFFFF">ホーム</Typography>
                </Button>
              ) : (
                ""
              )}
              {currentUser?.email !== undefined && matches ? (
                <Button onClick={() => moveFavorite()}>
                  <Typography color="#FFFFFF">お気に入り</Typography>
                </Button>
              ) : (
                ""
              )}
              {currentUser?.email !== undefined ? (
                <UserMenu />
              ) : (
                <Button
                  component={Link}
                  to="/login"
                  variant="outlined"
                  color="inherit"
                >
                  <Typography color="#FFFFFF">ログイン</Typography>
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          marginRight={1}
                          onClick={() => moveTop()}
                        >
                          TOP
                        </Typography>
                        <Typography variant="subtitle1" marginRight={1}>
                          {">"}
                        </Typography>
                        <Typography variant="subtitle1" marginRight={1}>
                          設定
                        </Typography>
                      </Stack>
                      <Grid container direction="column" alignItems="center">
                        <Typography variant={matches ? "h4" : "h5"}>
                          設定の変更
                        </Typography>
                      </Grid>
                      <Grid sx={{ width: "100%", marginTop: 3 }}>
                        <Grid container direction="column" alignItems="center">
                          <FormControlLabel
                            sx={{}}
                            onChange={(e) => changeCheckBox(e.target.value)}
                            control={
                              <Checkbox checked={isNotification === 1} />
                            }
                            label="新しい空坪が登録された場合、通知メールを受け取る"
                          />
                          <Grid sx={{ marginTop: 5, marginBottom: 5 }}>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ width: 200 }}
                              onClick={() => update()}
                            >
                              設定
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 2 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default UserSetting;
