import React from "react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AdminMenu from "./AdminMenu";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import AdminListItems from "./MenuList";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";

import { useDropzone } from "react-dropzone";
import { readString } from "react-papaparse";
import { Message } from "@mui/icons-material";
import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import "./App.css";
import { useHistory, Link } from "react-router-dom";

const start = 1960;
let end = new Date().getFullYear() + 1;
const yearRange = [...Array(end - start + 1)].map((_, i) => start + i);
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const headers = {
  "Content-Type": "application/json",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mailSend = (body) => {
  console.log("body", body);
  axios
    .post(`${process.env.REACT_APP_API_URL}sendgrid/sendMail`, body, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const mdTheme = createTheme();
let list = [];
function AdminBulkAddUser() {
  const { currentUser, userInfo } = useAuthValue();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [addList, setAddList] = useState(false);
  const [success, setSuccess] = useState("");
  const history = useHistory();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        readString(binaryStr, {
          worker: true,
          complete: (results) => {
            results.data.map((row) => {
              console.log("row", row);
              const grant = row[0] === "" ? undefined : row[0];
              const email = row[1];
              const password = row[2];
              const company = row[3];
              const kankatsuShukan = row[4];
              const shiten = row[5];
              const eigyosho = row[6];
              list.push({
                type: grant,
                email: email,
                password: password,
                company: company,
                kankatsuShukan: kankatsuShukan,
                shiten: shiten,
                eigyosho: eigyosho,
                status: grant === undefined ? undefined : 1,
                isNotification: grant === undefined ? undefined : 0,
              });
              return list;
            });
            console.log(list);
            setAddList(true);
          },
        });

        console.log("error", error);
      };
      reader.readAsText(file);
    });
  }, []);
  const addUser = () => {
    console.log("origin", list);
    const list1 = list.shift();
    console.log(list1);
    console.log("list", addList);
    console.log(addList.length);
    const listAdd = addList.length === 1 ? [list] : list;
    console.log("listAdd", listAdd);
    listAdd.map((item) => {
      if (item.type !== undefined) {
        let createUser = {
          companyId: item.company,
          type: item.type,
          kankatsuShukanShitenId: item.kankatsuShukan,
          shitenId: item.shiten,
          eigyoshoId: item.eigyosho,
          status: item.status,
          email: item.email,
          isNotification: item.isNotification,
          password: item.password,
        };
        console.log("user", createUser);

        axios
          .post(
            `${process.env.REACT_APP_API_URL}user/addWithAuth`,
            createUser,
            {
              headers: headers,
            }
          )
          .then((response) => {
            console.log("success");
            console.log("item", item);
            let body = {
              token: `${process.env.REACT_APP_SENDGRID_API_KEY}`,
              personalizations: [
                {
                  to: [
                    {
                      email: item.email,
                    },
                  ],
                  subject: "SENKO-SO-MATCH 仮パスワード発行",
                },
              ],
              from: {
                email: `${process.env.REACT_APP_SENDMAIL_EMAIL}`,
              },
              content: [
                {
                  type: "text/plain",
                  value: `SENKO-SO-MATCH 管理ページの仮パスワードを発行いたしました。\n\n以下のリンクよりアカウントの認証を行い、仮パスワードの変更を行なってください。\n\nメール認証：${response.data.link}\n\n登録メールアドレス：${item.email}\n\n仮パスワード：${item.password}\n\n`,
                },
              ],
            };
            console.log(body);
            mailSend(body);
            setSuccess("入力したメールアドレスに確認メールを送信しました");
          })
          .catch((err) => {
            console.log(err.response.data);
            switch (err.response.data.errors) {
              case "The email address is already in use by another account.":
                setError("すでにメールアドレスが使用されています");
                break;
              case "The password is invalid or the user does not have a password.":
                setError("パスワードが間違っています");
                break;
              case "auth/user-disabled":
                setError("ユーザーが無効です");
                break;
              case "There is no user record corresponding to this identifier. The user may have been deleted.":
                setError("ユーザーが存在しません");
                break;
              case "The email address is badly formatted.":
                setError("メールアドレスの形式が間違っています");
                break;
              case "auth/too-many-requests":
                setError(
                  "試行回数制限を超えました。時間をおいて再度お試しください"
                );
                break;
              default:
                setError("エラーが発生しました");
            }
          });
      }
    });
    setAddList(false);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const templateList = [
    {
      grant: 2,
      email: "aaaaa@example.com",
      password: "12345678",
      companyId: 1,
      kankatsuShukan: 1,
      shiten: 1,
      eigyosho: 1,
    },
  ];
  const move = () => {
    history.push("/admin");
  };
  const csvDownload = () => {
    const header =
      "権限,メールアドレス,仮パスワード,会社ID,主管支店ID,支店ID,営業所ID\r\n";
    let data = header;
    for (let template of templateList) {
      data += template.grant + ",";
      data += template.email + ",";
      data += template.password + ",";
      data += template.companyId + ",";
      data += template.kankatsuShukan + ",";
      data += template.shiten + ",";
      data += template.eigyosho;
      data += "\r\n";
    }

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: "text/csv" });
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    const fileName = "template.csv";
    downloadLink.download = fileName;
    downloadLink.href = objectUrl;
    downloadLink.click();
    downloadLink.remove();
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Toaster position="top-right" />
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                SENKO-SO-MATCH(登録サイト)
              </Typography>
              <AdminMenu />
            </Toolbar>
          </AppBar>
          <List component="nav" sx={{ mt: 10, width: 240 }}>
            <AdminListItems />
            <Divider sx={{ my: 1 }} />
          </List>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5">倉庫管理者(登録)</Typography>

                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid marginX="5%">
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        marginTop={2}
                      >
                        <Typography variant="h5">倉庫管理者一括登録</Typography>
                      </Grid>

                      <Grid marginTop={10}>
                        {success !== "" ? (
                          <Grid
                            marginTop={1}
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Typography variant="h6" sx={{ marginBottom: 3 }}>
                              倉庫管理者の一括登録が完了致しました。
                            </Typography>
                            <Typography variant="h6" sx={{ marginBottom: 3 }}>
                              倉庫管理者の本登録完了後に利用可能となります。
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid
                            marginTop={1}
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Typography variant="h6" sx={{ marginBottom: 3 }}>
                              CSVファイル
                            </Typography>

                            <FormControl sx={{ width: "70%" }}>
                              <div className="dropzone">
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {isDragActive ? (
                                    <p>
                                      ドラッグ&ドロップもしくはクリックしてファイルを選択。
                                    </p>
                                  ) : (
                                    <p>
                                      ドラッグ&ドロップもしくはクリックしてファイルを選択。
                                    </p>
                                  )}
                                </div>
                              </div>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                      {addList === true ? (
                        <Grid
                          marginTop={5}
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <Button
                            variant="contained"
                            sx={{ width: 250 }}
                            onClick={() => addUser()}
                            color="success"
                          >
                            登録する
                          </Button>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      marginTop={5}
                      marginBottom={5}
                    >
                      <Grid>
                        {success && (
                          <div className="auth__success">{success}</div>
                        )}
                        {error && <div className="auth__error">{error}</div>}
                      </Grid>
                      {success !== "" ? (
                        ""
                      ) : (
                        <Grid sx={{ marginTop: 5 }}>
                          <Typography variant="subtitle1">
                            一括登録用CSVテンプレート
                          </Typography>
                        </Grid>
                      )}
                      {success !== "" ? (
                        <Grid sx={{ marginTop: 2 }}>
                          <Button
                            variant="contained"
                            color="success"
                            sx={{ width: 250 }}
                            onClick={move}
                          >
                            トップページ　＞
                          </Button>
                        </Grid>
                      ) : (
                        <Grid sx={{ marginTop: 2 }}>
                          <Button
                            variant="contained"
                            sx={{ width: 250 }}
                            onClick={csvDownload}
                          >
                            ダウンロードする
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Copyright sx={{ pt: 2 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default AdminBulkAddUser;
