import { Route, Redirect, Link } from "react-router-dom";
import { useAuthValue } from "./AuthContext";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { currentUser, userInfo } = useAuthValue();

  return (
    <Route
      {...rest}
      render={(Props) => {
        return (currentUser?.emailVerified && userInfo?.type === 2) ||
          (currentUser?.emailVerified && userInfo?.type === 3) ? (
          <Component {...Props} />
        ) : (
          <Redirect to="/admin/login" />
        );
      }}
    ></Route>
  );
};

export default AdminRoute;
