import { useState } from "react";
import "./forms.css";
import { auth } from "./firebase";
import { useHistory, Link } from "react-router-dom";
import {
  getAuth,
  EmailAuthProvider,
  updateEmail,
  reauthenticateWithCredential,
  sendEmailVerification,
  ActionCodeURL,
  signOut,
} from "firebase/auth";
import { useAuthValue } from "./AuthContext";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MuiAppBar from "@mui/material/AppBar";
import UserMenu from "./UserMenu";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

const date = new Date();

const oneYearAfter = dayjs(date).add(1, "y").format("YYYY/MM/DD hh:mm:ss");

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const theme = createTheme();

function UserChangeEmail(props) {
  const { currentUser, totalCount, newCount } = useAuthValue();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [updated, setUpdated] = useState(false);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");
  //const {setTimeActive} = useAuthValue()

  const searchParams = {
    startAt: "2022/01/01 00:00:00",
    endAt: oneYearAfter,
    sortKey: "createAt",
    ascdesc: "asc",
    limit: 50,
    offset: 0,
    status: ["1"],
    areaId: "",
    shitenId: "",
    prefectureId: "",
    availableArea: "",
    sokoTypeId: "",
    companyId: "",
    contractId: "",
    kankatsuShukanShitenId: "",
    kankatsuEigyobuId: "",
    availableYear: "",
    availableMonth: "",
    kinoKeitaiId: "",
    specId: "",
    taiouGyotaiId: "",
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: searchParams,
      },
    });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  const validateEmail = () => {
    let isValid = true;
    if (email !== "" && confirmEmail !== "") {
      if (email !== confirmEmail) {
        isValid = false;
        setMessage("メールアドレスが異なります");
      }
    }
    return isValid;
  };

  const changeEmail = (e) => {
    e.preventDefault();
    if (validateEmail()) {
      setError("");
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      console.log(auth.currentUser.email);
      reauthenticateWithCredential(user, credential).then(() => {
        updateEmail(user, email)
          .then(() => {
            const ActionCodeSettiings = {
              url: `${process.env.REACT_APP_SITE_URL}/emailupdated/mail/${email}`,
            };
            sendEmailVerification(user, ActionCodeSettiings);
            setSuccess("入力したメールアドレスに確認メールを送信しました");
            setEmail("");
            setConfirmEmail("");
            setUpdated(true);
          })
          .catch((err) => {
            console.log(err.message);
            err.message === "Firebase: Error (auth/email-already-in-use)."
              ? setError("入力したメールアドレスは既に使用されています")
              : console.log(err.message);
          });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className="pointer"
              sx={{ display: "block", marginRight: 5 }}
              onClick={() => moveTop()}
            >
              {matches ? "SENKO-SO-MATCH" : "SSM"}
            </Typography>
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                登録
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                ccolor="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                {totalCount}
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block", marginRight: 3 }}
              >
                棟
              </Typography>
            ) : (
              ""
            )}

            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                新着
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block" }}
              >
                {newCount}
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ display: "block", flexGrow: 1 }}
              >
                棟
              </Typography>
            ) : (
              ""
            )}
            {matches ? (
              <Button onClick={() => moveTop()}>
                <Typography color="#FFFFFF">ホーム</Typography>
              </Button>
            ) : (
              ""
            )}
            {currentUser?.email !== undefined && matches ? (
              <Button onClick={() => moveFavorite()}>
                <Typography color="#FFFFFF">お気に入り</Typography>
              </Button>
            ) : (
              ""
            )}
            {currentUser?.email !== undefined ? (
              <UserMenu />
            ) : (
              <Button
                component={Link}
                to="/login"
                variant="outlined"
                color="inherit"
              >
                <Typography color="#FFFFFF">ログイン</Typography>
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container component="main">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                component={Paper}
                elevation={6}
                square
                container
                direction="column"
                alignItems="center"
              >
                <Grid
                  sx={{
                    width: matches ? "50%" : "90%",
                    my: 8,
                    mx: matches ? 4 : 0,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h1"
                    variant={matches ? "h5" : "h6"}
                    color="primary"
                    marginTop={3}
                    align="center"
                  >
                    メールアドレスを変更いたします
                  </Typography>
                  <Typography
                    component="h1"
                    variant="subtitle1"
                    color="#000000"
                    marginTop={3}
                    align="center"
                    sx={{ width: "100%" }}
                  >
                    {updated === true ? "" : "ログインIDも変更されます"}
                  </Typography>
                  <Grid container direction="column" alignItems="center">
                    <Box
                      component="form"
                      noValidate
                      sx={{ mt: 1, width: matches ? "50%" : "80%" }}
                      onSubmit={changeEmail}
                      name="login_form"
                    >
                      {success && (
                        <div className="auth__success">{success}</div>
                      )}
                      {error && <div className="auth__error">{error}</div>}
                      {email === confirmEmail
                        ? ""
                        : message && (
                            <div className="auth__error">{message}</div>
                          )}
                      {updated === true ? (
                        ""
                      ) : (
                        <TextField
                          margin="normal"
                          type="email"
                          fullWidth
                          value={email}
                          required
                          label="新しいメールアドレス"
                          placeholder="新しいメールアドレス"
                          autoFocus
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      )}

                      {updated === true ? (
                        ""
                      ) : (
                        <TextField
                          margin="normal"
                          type="email"
                          fullWidth
                          value={confirmEmail}
                          required
                          label="新しいメールアドレス(確認)"
                          placeholder="新しいメールアドレス(確認)"
                          autoFocus
                          onChange={(e) => {
                            setConfirmEmail(e.target.value);
                            validateEmail();
                          }}
                        />
                      )}
                      {updated === true ? (
                        ""
                      ) : (
                        <TextField
                          margin="normal"
                          type="password"
                          fullWidth
                          value={password}
                          required
                          label="パスワード"
                          placeholder="パスワードを入力してください。"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      )}
                      {updated === true ? (
                        <Button
                          variant="contained"
                          fullWidth
                          color="success"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={() => moveTop()}
                        >
                          トップページ ＞
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          color="success"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          送信
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default UserChangeEmail;
