import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useAuthValue } from "./AuthContext";
import Copyright from "./footer";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useHistory, Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { MdClose } from "react-icons/md";
import UserMenu from "./UserMenu";
import { useMediaQuery } from "@mui/material";
import { match } from "assert";

import "./App.css";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#009944",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const style = {
  position: "absolute",
  top: 0,
  width: "100vw",
  height: "100%",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

function StateConditions(props) {
  const {
    currentUser,
    userInfo,
    masterData,
    totalCount,
    newCount,
    areaCount,
    prefectureCount,
  } = useAuthValue();
  const [stateList, setStateList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [wareHouseCount, SetWareHouseCount] = useState();
  const [searchModal, setSearchModal] = useState(false);
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1050px)");

  const areaMList = masterData.areaMList;
  const companyMList = masterData.companyMList;
  const contractMList = masterData.contractMList;
  const kankatsuShukanShitenMList = masterData.kankatsuShukanShitenMList;
  const shitenMList = masterData.shitenMList;
  const eigyoshoMList = masterData.eigyoshoMList;
  const kankatsuEigyobuMList = masterData.kankatsuEigyobuMList;
  const prefectureMList = masterData.prefectureMList.sort(
    (a, b) => a.prefectureId - b.prefectureId
  );
  const sokoTypeMList = masterData.sokoTypeMList;
  const kinoKeitaiMList = masterData.kinoKeitaiMList;
  const specMList = masterData.specMList;
  const taiouGyotaiMList = masterData.taiouGyotaiMList;

  const handleStateSearchModaleOpen = (index) => {
    if (searchModal === index) {
      setSearchModal("");
    } else {
      setSearchModal(index);
    }
  };

  const handleStateSearchModalClose = () => {
    setSearchModal(false);
  };

  const prefectureList = prefectureMList.sort(
    (a, b) => a.prefectureId - b.prefectureId
  );

  const areaList = areaMList.sort((a, b) => a.areaId - b.areaId);
  const areaList1 = () => {
    prefectureCount.map((item) => {
      Object.assign(
        prefectureList.find((e) => e.prefectureId === Number(item.PRI)),
        { count: item.count }
      );
      return prefectureList;
    });

    areaCount.map((item) => {
      Object.assign(
        areaList.find((e) => e.areaId === Number(item.ARE)),
        { count: item.count }
      );
      return areaList;
    });

    setIsLoading(false);
  };

  const hokkaido = prefectureList.slice(0, 1);
  const tohoku = prefectureList.slice(1, 7);
  const kanto = prefectureList.slice(7, 14);
  const chubu = prefectureList.slice(14, 24);
  const kansai = prefectureList.slice(24, 30);
  const chugoku = prefectureList.slice(30, 35);
  const shikoku = prefectureList.slice(35, 39);
  const kyusyu = prefectureList.slice(39, 47);

  const tohokuList = tohoku.map((item) => item.prefectureId);
  const kantoList = kanto.map((item) => item.prefectureId);
  const chubuList = chubu.map((item) => item.prefectureId);
  const kansaiList = kansai.map((item) => item.prefectureId);
  const chugokuList = chugoku.map((item) => item.prefectureId);
  const shikokuList = shikoku.map((item) => item.prefectureId);
  const kyusyuList = kyusyu.map((item) => item.prefectureId);

  const areaIdList = areaList.map((item) => item.areaId);

  const isSelectedPrefecture = (value) => props.prefecture.includes(value);

  const isSelectedAll =
    prefectureList?.length > 0 &&
    props.prefecture?.length === prefectureList?.length;

  const isIndeterminateAll =
    props.prefecture.length > 0 &&
    props.prefecture.length < prefectureList?.length;

  const handleSelectedarea = (value) => {
    switch (value) {
      case 1:
        props.setArea([1]);
        props.setPrefecture([1]);
        break;
      case 2:
        props.setArea([2]);
        props.setPrefecture([...tohokuList]);
        break;
      case 3:
        props.setArea([3]);
        props.setPrefecture([...kantoList]);
        break;
      case 4:
        props.setArea([4]);
        props.setPrefecture([...chubuList]);
        break;
      case 5:
        props.setArea([5]);
        props.setPrefecture([...kansaiList]);
        break;
      case 6:
        props.setArea([6]);
        props.setPrefecture([...chugokuList]);
        break;
      case 7:
        props.setArea([7]);
        props.setPrefecture([...shikokuList]);
        break;
      case 8:
        props.setArea([8]);
        props.setPrefecture([...kyusyuList]);
        break;
    }
  };

  const handleSelectedAll = () => {
    isSelectedAll
      ? props.setPrefecture([]) && props.setArea([])
      : props.setPrefecture(prefectureList.map((item) => item.prefectureId)) &&
        props.setArea(areaList.map((item) => item.areaId));
  };
  const handleSelectedAllArea = () => {
    isSelectedAll
      ? props.setArea([])
      : props.setArea(areaList.map((item) => item.areaId));
  };

  const isSelectedHokkaido = props.prefecture.includes(1);

  const handleSelectedHokkaido = () => {
    isSelectedHokkaido
      ? props.setPrefecture(props.prefecture.filter((item) => item !== 1))
      : props.setPrefecture([...props.prefecture, 1]);
  };

  const handleSelectedHokkaidoArea = () => {
    isSelectedHokkaido
      ? props.setArea([...props.area.filter((item) => item !== 1)])
      : props.setArea([...props.area.filter((item) => item !== 1), 1]);
  };

  const isSelectedTohoku =
    props.prefecture.filter((item) => tohokuList.includes(item)).length > 0 &&
    tohokuList.every((el) => props.prefecture.includes(el));

  const isIndeterminateTohoku =
    props.prefecture.filter((item) => tohokuList.includes(item)).length > 0 &&
    tohokuList.some((el) => !props.prefecture.includes(el));

  const isSelectedTohokuArea =
    props.prefecture.filter((item) => tohokuList.includes(item)).length > 0 &&
    tohokuList.some((el) => props.prefecture.includes(el));

  const handleSelectedTohoku = () => {
    isSelectedTohoku
      ? props.setPrefecture(
          props.prefecture.filter((item) => !tohokuList.includes(item))
        )
      : props.setPrefecture([
          ...tohokuList,
          ...props.prefecture.filter((item) => !tohokuList.includes(item)),
        ]);
  };

  const handleSelectedTohokuArea = () => {
    isSelectedTohokuArea
      ? props.setArea([...props.area.filter((item) => item !== 2)])
      : props.setArea([...props.area.filter((item) => item !== 2), 2]);
  };

  const isSelectedKanto =
    props.prefecture.filter((item) => kantoList.includes(item)).length > 0 &&
    kantoList.every((el) => props.prefecture.includes(el));

  const isIndeterminateKanto =
    props.prefecture.filter((item) => kantoList.includes(item)).length > 0 &&
    kantoList.some((el) => !props.prefecture.includes(el));

  const isSelectedKantoArea =
    props.prefecture.filter((item) => kantoList.includes(item)).length > 0 &&
    kantoList.some((el) => props.prefecture.includes(el));

  const handleSelectedKanto = () => {
    isSelectedKanto
      ? props.setPrefecture(
          props.prefecture.filter((item) => !kantoList.includes(item))
        )
      : props.setPrefecture([
          ...kantoList,
          ...props.prefecture.filter((item) => !kantoList.includes(item)),
        ]);
  };

  const handleSelectedKantoArea = () => {
    isSelectedKantoArea
      ? props.setArea([...props.area.filter((item) => item !== 3)])
      : props.setArea([...props.area.filter((item) => item !== 3), 3]);
  };

  const isSelectedChubu =
    props.prefecture.filter((item) => chubuList.includes(item)).length > 0 &&
    chubuList.every((el) => props.prefecture.includes(el));

  const isIndeterminateChubu =
    props.prefecture.filter((item) => chubuList.includes(item)).length > 0 &&
    chubuList.some((el) => !props.prefecture.includes(el));

  const isSelectedChubuArea =
    props.prefecture.filter((item) => chubuList.includes(item)).length > 0 &&
    chubuList.some((el) => props.prefecture.includes(el));

  const handleSelectedChubu = () => {
    isSelectedChubu
      ? props.setPrefecture(
          props.prefecture.filter((item) => !chubuList.includes(item))
        )
      : props.setPrefecture([
          ...chubuList,
          ...props.prefecture.filter((item) => !chubuList.includes(item)),
        ]);
  };

  const handleSelectedChubuArea = () => {
    isSelectedChubuArea
      ? props.setArea([...props.area.filter((item) => item !== 4)])
      : props.setArea([...props.area.filter((item) => item !== 4), 4]);
  };

  const isSelectedKansai =
    props.prefecture.filter((item) => kansaiList.includes(item)).length > 0 &&
    kansaiList.every((el) => props.prefecture.includes(el));

  const isIndeterminateKansai =
    props.prefecture.filter((item) => kansaiList.includes(item)).length > 0 &&
    kansaiList.some((el) => !props.prefecture.includes(el));

  const isSelectedKansaiArea =
    props.prefecture.filter((item) => kansaiList.includes(item)).length > 0 &&
    kansaiList.some((el) => props.prefecture.includes(el));

  const handleSelectedKansai = () => {
    isSelectedKansai
      ? props.setPrefecture(
          props.prefecture.filter((item) => !kansaiList.includes(item))
        )
      : props.setPrefecture([
          ...kansaiList,
          ...props.prefecture.filter((item) => !kansaiList.includes(item)),
        ]);
  };

  const handleSelectedKansaiArea = () => {
    isSelectedKansaiArea
      ? props.setArea([...props.area.filter((item) => item !== 5)])
      : props.setArea([...props.area.filter((item) => item !== 5), 5]);
  };

  const isSelectedChugoku =
    props.prefecture.filter((item) => chugokuList.includes(item)).length > 0 &&
    chugokuList.every((el) => props.prefecture.includes(el));

  const isIndeterminateChugoku =
    props.prefecture.filter((item) => chugokuList.includes(item)).length > 0 &&
    chugokuList.some((el) => !props.prefecture.includes(el));

  const isSelectedChugokuArea =
    props.prefecture.filter((item) => chugokuList.includes(item)).length > 0 &&
    chugokuList.some((el) => props.prefecture.includes(el));

  const handleSelectedChugoku = () => {
    isSelectedChugoku
      ? props.setPrefecture(
          props.prefecture.filter((item) => !chugokuList.includes(item))
        )
      : props.setPrefecture([
          ...chugokuList,
          ...props.prefecture.filter((item) => !chugokuList.includes(item)),
        ]);
  };

  const handleSelectedChugokuArea = () => {
    isSelectedChugokuArea
      ? props.setArea([...props.area.filter((item) => item !== 6)])
      : props.setArea([...props.area.filter((item) => item !== 6), 6]);
  };

  const isSelectedShikoku =
    props.prefecture.filter((item) => shikokuList.includes(item)).length > 0 &&
    shikokuList.every((el) => props.prefecture.includes(el));

  const isIndeterminateShikoku =
    props.prefecture.filter((item) => shikokuList.includes(item)).length > 0 &&
    shikokuList.some((el) => !props.prefecture.includes(el));

  const isSelectedShikokuArea =
    props.prefecture.filter((item) => shikokuList.includes(item)).length > 0 &&
    shikokuList.some((el) => props.prefecture.includes(el));

  const handleSelectedShikoku = () => {
    isSelectedShikoku
      ? props.setPrefecture(
          props.prefecture.filter((item) => !shikokuList.includes(item))
        )
      : props.setPrefecture([
          ...shikokuList,
          ...props.prefecture.filter((item) => !shikokuList.includes(item)),
        ]);
  };

  const handleSelectedShikokuArea = () => {
    isSelectedShikokuArea
      ? props.setArea([...props.area.filter((item) => item !== 7)])
      : props.setArea([...props.area.filter((item) => item !== 7), 7]);
  };

  const isSelectedKyusyu =
    props.prefecture.filter((item) => kyusyuList.includes(item)).length > 0 &&
    kyusyuList.every((el) => props.prefecture.includes(el));

  const isIndeterminateKyusyu =
    props.prefecture.filter((item) => kyusyuList.includes(item)).length > 0 &&
    kyusyuList.some((el) => !props.prefecture.includes(el));

  const isSelectedKyusyuArea =
    props.prefecture.filter((item) => kyusyuList.includes(item)).length > 0 &&
    kyusyuList.some((el) => props.prefecture.includes(el));

  const handleSelectedKyusyu = () => {
    isSelectedKyusyu
      ? props.setPrefecture(
          props.prefecture.filter((item) => !kyusyuList.includes(item))
        )
      : props.setPrefecture([
          ...kyusyuList,
          ...props.prefecture.filter((item) => !kyusyuList.includes(item)),
        ]);
  };

  const handleSelectedKyusyuArea = () => {
    isSelectedKyusyuArea
      ? props.setArea([...props.area.filter((item) => item !== 8)])
      : props.setArea([...props.area.filter((item) => item !== 8), 8]);
  };

  const handlePrefectureChange = (value) => {
    isSelectedPrefecture(value)
      ? props.setPrefecture(
          props.prefecture.filter((checkedValue) => checkedValue !== value)
        )
      : props.setPrefecture([...props.prefecture, value]);
  };

  const allDelete = () => {
    props.setPrefecture([]);
    props.setArea([]);
  };

  const moveFavorite = () => {
    history.push({
      pathname: "/favorite",
      state: {
        searchParams: props.searchParams,
      },
    });
  };

  const moveTop = (item) => {
    history.push({
      pathname: "/",
    });
  };

  useEffect(() => {
    areaList1();
  }, []);

  return (
    <>
      {areaList.map((item, index) => (
        <FormControl key={item.name}>
          {isLoading ? (
            <Grid>
              <Typography>loading...</Typography>
            </Grid>
          ) : (
            <Grid sx={{ margin: 1 }}>
              <Button
                variant="contained"
                fullWidth
                value={item.areaId}
                onClick={(e) => {
                  handleSelectedarea(Number(e.target.value));
                  handleStateSearchModaleOpen(index);
                }}
                size={matches ? "medium" : "small"}
                sx={{ fontSize: "1em" }}
              >
                {item.name}（{item.count === undefined ? 0 : item.count}）
              </Button>
            </Grid>
          )}
          <Modal
            hideBackdrop
            open={searchModal === index}
            aria-labelledby="title"
            aria-describedby="description"
          >
            {isLoading ? (
              <Grid>
                <Typography>loading...</Typography>
              </Grid>
            ) : (
              <Grid>
                <Grid className="closebutton">
                  <IconButton
                    onClick={() => {
                      handleStateSearchModalClose();
                    }}
                    sx={{ width: "3rem", height: "3rem" }}
                  >
                    <MdClose size={"3rem"} color="#FFFFFF" />
                  </IconButton>
                </Grid>
                <Paper
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    ...style,
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <CssBaseline />
                    <AppBar
                      position="absolute"
                      sx={{
                        backgroundColor: "#009944",
                      }}
                    >
                      <Toolbar
                        sx={{
                          pr: "24px", // keep right padding when drawer closed
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          sx={{ display: "block", marginRight: 5 }}
                        >
                          {matches ? "SENKO-SO-MATCH" : "SSM"}
                        </Typography>
                        {matches ? (
                          <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ display: "block" }}
                          >
                            登録
                          </Typography>
                        ) : (
                          ""
                        )}
                        {matches ? (
                          <Typography
                            variant="h6"
                            ccolor="inherit"
                            noWrap
                            sx={{ display: "block" }}
                          >
                            {totalCount}
                          </Typography>
                        ) : (
                          ""
                        )}
                        {matches ? (
                          <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ display: "block", marginRight: 3 }}
                          >
                            棟
                          </Typography>
                        ) : (
                          ""
                        )}

                        {matches ? (
                          <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ display: "block" }}
                          >
                            新着
                          </Typography>
                        ) : (
                          ""
                        )}
                        {matches ? (
                          <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ display: "block" }}
                          >
                            {newCount}
                          </Typography>
                        ) : (
                          ""
                        )}
                        {matches ? (
                          <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ display: "block", flexGrow: 1 }}
                          >
                            棟
                          </Typography>
                        ) : (
                          ""
                        )}
                        {matches ? (
                          <Button onClick={() => moveTop()}>
                            <Typography color="#FFFFFF">ホーム</Typography>
                          </Button>
                        ) : (
                          ""
                        )}
                        {currentUser?.email !== undefined && matches ? (
                          <Button onClick={() => moveFavorite()}>
                            <Typography color="#FFFFFF">お気に入り</Typography>
                          </Button>
                        ) : (
                          ""
                        )}
                        {currentUser?.email !== undefined ? (
                          <UserMenu />
                        ) : (
                          <Button
                            sx={{ marginLeft: matches ? 0 : 15 }}
                            component={Link}
                            to="/login"
                            variant="outlined"
                            color="inherit"
                          >
                            <Typography color="#FFFFFF">ログイン</Typography>
                          </Button>
                        )}
                      </Toolbar>
                    </AppBar>
                    <Box
                      component="main"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                      }}
                    >
                      <Toolbar />
                      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} lg={12} xl={12}>
                            <Paper
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                  marginTop={3}
                                >
                                  <Typography
                                    variant={matches ? "h5" : "subtitle1"}
                                    color="inherit"
                                    noWrap
                                  >
                                    都道府県を選択してください
                                  </Typography>
                                </Grid>

                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                  sx={{ marginTop: 3 }}
                                >
                                  <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    sx={{ marginTop: 2 }}
                                  >
                                    <Grid
                                      sx={{ width: matches ? "70%" : "100%" }}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "100%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              onChange={() => {
                                                handleSelectedAll();
                                                handleSelectedAllArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateAll
                                                  }
                                                />
                                              }
                                              checked={isSelectedAll}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {"全ての都道府県を選択"}
                                                </Typography>
                                              }
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{ width: matches ? "70%" : "0%" }}
                                        ></FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              control={
                                                <Checkbox
                                                  checked={
                                                    isSelectedHokkaido ||
                                                    props.area.includes(1)
                                                  }
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 1
                                                  ).count === undefined
                                                    ? "北海道（0）"
                                                    : "北海道" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 1
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              onChange={() => {
                                                handleSelectedHokkaido();
                                                handleSelectedHokkaidoArea();
                                              }}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {hokkaido.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedTohoku();
                                                handleSelectedTohokuArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateTohoku
                                                  }
                                                  checked={props.area.includes(
                                                    2
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 2
                                                  ).count === undefined
                                                    ? "東北（0）"
                                                    : "東北" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 2
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedTohoku}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {tohoku.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() => {
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    );
                                                  }}
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedKanto();
                                                handleSelectedKantoArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateKanto
                                                  }
                                                  checked={props.area.includes(
                                                    3
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 3
                                                  ).count === undefined
                                                    ? "関東（0）"
                                                    : "関東" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 3
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedKanto}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {kanto.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedChubu();
                                                handleSelectedChubuArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateChubu
                                                  }
                                                  checked={props.area.includes(
                                                    4
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 4
                                                  ).count === undefined
                                                    ? "中部・北信越（0）"
                                                    : "中部・北信越" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 4
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedChubu}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {chubu.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedKansai();
                                                handleSelectedKansaiArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateKansai
                                                  }
                                                  checked={props.area.includes(
                                                    5
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 5
                                                  ).count === undefined
                                                    ? "関西（0）"
                                                    : "関西" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 5
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedKansai}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {kansai.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedChugoku();
                                                handleSelectedChugokuArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateChugoku
                                                  }
                                                  checked={props.area.includes(
                                                    6
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 6
                                                  ).count === undefined
                                                    ? "中国（0）"
                                                    : "中国" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 6
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedChugoku}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {chugoku.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedShikoku();
                                                handleSelectedShikokuArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateShikoku
                                                  }
                                                  checked={props.area.includes(
                                                    7
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 7
                                                  ).count === undefined
                                                    ? "四国（0）"
                                                    : "四国" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 7
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedShikoku}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {shikoku.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                      <Grid
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        <Divider />
                                      </Grid>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "5%" : "0%",
                                            width: matches ? "25%" : "50%",
                                          }}
                                        >
                                          <FormGroup>
                                            <FormControlLabel
                                              key="99"
                                              value="99"
                                              onChange={() => {
                                                handleSelectedKyusyu();
                                                handleSelectedKyusyuArea();
                                              }}
                                              control={
                                                <Checkbox
                                                  indeterminate={
                                                    isIndeterminateKyusyu
                                                  }
                                                  checked={props.area.includes(
                                                    8
                                                  )}
                                                />
                                              }
                                              sx={{
                                                marginRight: matches ? 0 : 0,
                                              }}
                                              label={
                                                <Typography
                                                  variant={
                                                    matches
                                                      ? "subtitle1"
                                                      : "subtitle2"
                                                  }
                                                >
                                                  {areaList.find(
                                                    (e) => e.areaId === 8
                                                  ).count === undefined
                                                    ? "九州・沖縄（0）"
                                                    : "九州・沖縄" +
                                                      "（" +
                                                      areaList.find(
                                                        (e) => e.areaId === 8
                                                      ).count +
                                                      "）"}
                                                </Typography>
                                              }
                                              checked={isSelectedKyusyu}
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <FormControl
                                          sx={{
                                            marginLeft: matches ? "0%" : "0%",
                                            width: matches ? "70%" : "50%",
                                          }}
                                        >
                                          <FormGroup row={true}>
                                            {kyusyu.map((item, index) => {
                                              const isItemSelected =
                                                isSelectedPrefecture(
                                                  item.prefectureId
                                                );
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  sx={{
                                                    marginRight: matches
                                                      ? 3
                                                      : 0,
                                                  }}
                                                  value={item.prefectureId}
                                                  control={
                                                    <Checkbox
                                                      checked={isItemSelected}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant={
                                                        matches
                                                          ? "subtitle1"
                                                          : "subtitle2"
                                                      }
                                                    >
                                                      {item.count === undefined
                                                        ? item.name + "（0）"
                                                        : item.name +
                                                          "（" +
                                                          item.count +
                                                          "）"}
                                                    </Typography>
                                                  }
                                                  onChange={() =>
                                                    handlePrefectureChange(
                                                      item.prefectureId
                                                    )
                                                  }
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </FormControl>
                                      </Stack>
                                    </Grid>
                                  </Grid>

                                  <Grid sx={{ width: "70%", marginTop: 2 }}>
                                    <Divider />
                                  </Grid>
                                  <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    sx={{ marginTop: 2 }}
                                  >
                                    <Grid sx={{ width: "70%" }}>
                                      <Grid sx={{ width: "100%" }}>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="flex-end"
                                          sx={{
                                            marginBottom: 2,
                                            marginLeft: matches ? 10 : 7,
                                          }}
                                        >
                                          <FormControl
                                            sx={{
                                              width: matches ? "30%" : "55%",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              onClick={() => {
                                                allDelete();
                                              }}
                                              size={
                                                matches ? "medium" : "small"
                                              }
                                              sx={{
                                                background: "gray",
                                                ":hover": {
                                                  background: "#6C676E",
                                                },
                                              }}
                                            >
                                              <Typography
                                                color="inherit"
                                                variant={
                                                  matches
                                                    ? "subtitle1"
                                                    : "caption"
                                                }
                                              >
                                                選択フィルターを外す
                                              </Typography>
                                            </Button>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid sx={{ width: "70%" }}>
                                      <Grid
                                        sx={{ marginTop: 1, width: "100%" }}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                          sx={{ marginTop: 2 }}
                                        >
                                          <FormControl
                                            sx={{
                                              width: matches ? "30%" : "100%",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              onClick={() => {
                                                handleStateSearchModalClose();
                                                props.seachCount(
                                                  props.searchParams
                                                );
                                              }}
                                            >
                                              <Typography
                                                color="#FFFFFF"
                                                variant={
                                                  matches
                                                    ? "subtitle1"
                                                    : "subtitle2"
                                                }
                                                sx={{
                                                  fontWeight: matches
                                                    ? "nomal"
                                                    : "bold",
                                                }}
                                              >
                                                選択した都道府県を反映する
                                              </Typography>
                                            </Button>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 2 }} />
                      </Container>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Modal>
        </FormControl>
      ))}
    </>
  );
}

export default StateConditions;
