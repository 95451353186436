import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import PaidIcon from "@mui/icons-material/Paid";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { useAuthValue } from "./AuthContext";
import Typography from "@mui/material/Typography";
import { Route, Link, Switch, useHistory } from "react-router-dom";
import { Button } from "@mui/material";

export default function AdminMenu() {
  const { currentUser, userInfo } = useAuthValue();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Typography sx={{ minWidth: 100 }}>{currentUser?.email}</Typography>
      <Tooltip title="Store Information">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="inherit"
        >
          <Avatar />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to="/admin/emptyarealist">
          <IconButton>空坪一覧</IconButton>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/admin/addemptyarea">
          <IconButton>空坪登録</IconButton>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/admin/warehouselist">
          <IconButton>倉庫一覧</IconButton>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/admin/addwarehouse">
          <IconButton>倉庫登録</IconButton>
        </MenuItem>
        <Divider />
        {userInfo.type === 3 ? (
          <MenuItem component={Link} to="/admin/allAdminUsers">
            <IconButton>管理者一覧</IconButton>
          </MenuItem>
        ) : (
          ""
        )}
        {/* {userInfo.type === 3 ? <Divider /> : ""}
        {userInfo.type === 3 ? (
          <MenuItem component={Link} to="/admin/addmaster">
            <IconButton>マスタ追加</IconButton>
          </MenuItem>
        ) : (
          ""
        )} */}
        {userInfo.type === 3 ? <Divider /> : ""}
        <MenuItem component={Link} to="/admin/updateemail">
          <IconButton>メールアドレス変更</IconButton>
        </MenuItem>

        <Divider />
        <MenuItem component={Link} to="/admin/updatepw">
          <IconButton>パスワード変更</IconButton>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() =>
            signOut(auth).then((res) => {
              history.push("/admin");
            })
          }
        >
          <Logout fontSize="small" />
          <IconButton>ログアウト</IconButton>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
